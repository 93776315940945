import { userModel } from './../model/user.model';
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
// import { ToastrService } from 'ngx-toastr';
import { UserService } from "../../services/user.service";
import { BehaviorSubject, Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { RolesService } from '../../services/roles.service';
// import * as moment from 'moment';

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {

  userId: number;
  errors:any = [];
  name: string;
  createUserForm: FormGroup;
  adminProfile: any = [];
  profile: any = [];
  submited1: boolean = false;
  step1Validate = new BehaviorSubject(false);
  step2Validate = new BehaviorSubject(false);
  step3Validate = new BehaviorSubject(false);
  passwordShow: boolean = false;
  submitted: boolean = false;
  public validate: boolean = false;
  roles: any = [];
  // public tooltipValidation = false;

  assignDepartment = [{ id: 1, name: "Digital Marketing" }];
  assignDesignation = [{ id: 1, name: "Digital Marketing" }];
  assignRoles:any = [];

  constructor(
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router,
    private userRole: RolesService,
    private toast: ToastrService,
    private fb: FormBuilder
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.userId = Number(routeParams.get("id"));
  }

  ngOnInit() {
    this.createUserForm = this.fb.group({
      firstFormGroup: this.fb.group({
        first_name: ["", [Validators.required, Validators.minLength(3)]],
        last_name: [""],
        address: ["", Validators.required],
        mobile_no: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        // dob: [moment([2017, 0, 1]), Validators.required],
        blood_group: ["", Validators.required],
        biography: [""],
        gender: ["", Validators.required],
      }),
      secondFormGroup: this.fb.group({
        job_title: ["", Validators.required],
        department_id: ["", Validators.required],
        designation_id: ["", Validators.required],
        doh: ["", Validators.required],
      }),
      thirdFormGroup: this.fb.group({
        username: ["", Validators.required],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          ],
        ],
        password_hash: [""],
        bank_details: [""],
        assign_roles: [""],
      }),
    });
    this.user.findOne(this.userId).subscribe((res: any) => {
      console.log(res);
      if (res.status === true) {
        const role = res.data.roles;
        role.forEach((item: any) => {
          this.roles.push(item.role_id)
        });
        console.log(this.roles);
        const _user: userModel = res.data[0];
        this.firstFormGroup.get('first_name').setValue(_user.profile.first_name)
        this.firstFormGroup.get('last_name').setValue(_user.profile.last_name)
        this.firstFormGroup.get('address').setValue(_user.profile.address)
        this.firstFormGroup.get('mobile_no').setValue(_user.profile.mobile_no || 0)
        // this.firstFormGroup.patchValue({dob : moment("12/25/1995", "MM/DD/YYYY")})
        // this.firstFormGroup.get('dob').patchValue(moment('1/1/2022').format('mm/dd/yyyy'))
        this.firstFormGroup.get('gender').setValue(String(_user.profile.gender))
        this.firstFormGroup.get('blood_group').setValue(_user.profile.blood_group)
        this.firstFormGroup.get('biography').setValue(_user.profile.biography)
        this.secondFormGroup.get('job_title').setValue(_user.profile.job_title)
        this.secondFormGroup.get('department_id').setValue(_user.profile.department_id)
        this.secondFormGroup.get('designation_id').setValue(_user.profile.designation_id)
        // this.secondFormGroup.get('doh').setValue(moment(_user.profile.doh).format('dd/mm/yyyy'))
        this.thirdFormGroup.get('username').setValue(_user.username)
        this.thirdFormGroup.get('email').setValue(_user.email)
        this.thirdFormGroup.get('bank_details').setValue(_user.profile.bank_details)
        this.thirdFormGroup.get('assign_roles').setValue(this.roles)
      }
    });

    this.userRole.getAll().subscribe((res: any) =>{
      console.log(res);
      this.assignRoles = res.data;
    })
  }

  get f() {
    return this.createUserForm.controls;
  }

  get firstFormGroup() {
    return this.f.firstFormGroup;
  }

  get secondFormGroup() {
    return this.f.secondFormGroup;
  }

  get thirdFormGroup() {
    return this.f.thirdFormGroup;
  }

  showPassword() {
    this.passwordShow = !this.passwordShow;
  }

  generate() {
    const value = this.user.generatePassword().subscribe((res:any)=>{
      console.log(res);
      console.log(res.data);
      if(res.status === true){
          this.thirdFormGroup.get('password_hash').setValue(res.data)
      }
    })
  }

  step1() {
    this.step1Validate.next(true);
    //console.log(this.firstFormGroup);
  }

  step2() {
    this.step2Validate.next(true);
    //console.log(this.secondFormGroup);
  }

  async onSubmit() {
    this.step3Validate.next(true);
    this.submitted = true;
    this.validate = !this.validate;
    const postData = {
      id:this.userId,
      username: this.thirdFormGroup.value.username,
      email: this.thirdFormGroup.value.email,
      password_hash: this.thirdFormGroup.value.password_hash,
      assign_roles: this.thirdFormGroup.value.assign_roles,
      first_name: this.firstFormGroup.value.first_name,
      last_name: this.firstFormGroup.value.last_name,
      address: this.firstFormGroup.value.address,
      mobile_no: this.firstFormGroup.value.mobile_no,
      dob: this.firstFormGroup.value.dob,
      blood_group: this.firstFormGroup.value.blood_group,
      biography: this.firstFormGroup.value.biography,
      gender: Number (this.firstFormGroup.value.gender),
      job_title: this.secondFormGroup.value.job_title,
      department_id: this.secondFormGroup.value.department_id,
      designation_id: this.secondFormGroup.value.designation_id,
      doh: this.secondFormGroup.value.doh,
      bank_details: this.thirdFormGroup.value.bank_details,
      roles: this.thirdFormGroup.value.assign_roles,
    }
    console.log(postData);
    if (this.createUserForm.status == 'VALID') {
      const response = await this.user.update(postData).subscribe((res: any) => {
        console.log(res);
          if (res.status == true) {
            this.createUserForm.reset();
            this.toast.success('User Profile has been updated successfully');
            return this.router.navigate(['/user-management/user']) && res.data;
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        // }, (error) => {
        //   return error;
        }
      );
      console.log(response);
    }
  }
}
