import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeServices } from '../../attribute/services/attribute-services';
import { ToastrService } from 'ngx-toastr';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-attribute-family-assign',
  templateUrl: './attribute-family-assign.component.html',
  styleUrls: ['./attribute-family-assign.component.scss']
})
export class AttributeFamilyAssignComponent {

  public searchText;
  public selected = [];
  ATTRIBUTE = "ATTRIBUTE";
  ATTRIBUTES = "ATTRIBUTES";
  attribute: any = [];
  attribute1: any = [];

  attributes = [
    {
      id: 1,
      name: "Product Information",
      assigned: [
        // { id: 10, code: "mobile camera" }, { id: 11, code: "camera" }
      ]
    },
    {
      id: 2,
      name: "Sales Information",
      assigned: [
        // { id: 1, code: "price" }, { id: 2, code: "discount" }
      ]
    },
    {
      id: 3,
      name: "Media Information",
      assigned: [
        // { id: 1, code: "ram" }, { id: 2, code: "memory" }
      ]
    },
    {
      id: 4,
      name: "Tax Information",
      assigned: [
        // { id: 1, code: "vat" }, { id: 2, code: "gst" }
      ]
    },
    {
      id: 5,
      name: "Shipping Information",
      assigned: [
        // { id: 1, code: "packing" }, { id: 2, code: "logistics" }
      ]
    },
    {
      id: 6,
      name: "Agreement",
      assigned: [
        // { id: 1, code: "agree" }, { id: 2, code: "agree2" }
      ]
    },
    {
      id: 7,
      name: "Terms and Condition",
      assigned: [
        // { id: 1, code: "checked" }, { id: 2, code: "verified" }
      ]
    },
    {
      id: 8,
      name: "SEO",
      assigned: [
        // { id: 1, code: "seo1" }, { id: 2, code: "seo2" }
      ]
    },
  ]
  target: any = [];
  attributeAssigned: any = [];
  clickAttribute: any = [];
  attributeReClick: any = [];
  changeAttribute: any = [];
  changeReAttribute: any = [];
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  attributeId: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private attributeService: AttributeServices,
    private toast: ToastrService,) {
    const routeParams = this.route.snapshot.paramMap;
    this.attributeId = Number(routeParams.get("id"));

  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    console.log(this.attribute);
    console.log(this.attributes);
  }


  reset() {
    // this.attributes.reset;
  }

  save() {
    console.log(this.attributes);
    console.log(this.attribute);
  }


  getIdsAttribute(item): any[] {
    console.log(item);
    let ids = [item.id];
    item.assigned.forEach(childItem => {
      ids = ids.concat(this.getIdsAttribute(childItem));
    });
    return ids;
  }


  async ngOnInit() {
    await this.getAttributeList();
  }

  async getAttributeList() {
    await this.attributeService.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        console.log(res.data.data);
        this.attribute = res.data.data;
      }
    });
  }

  attributeAssign(attributes: any) {
    // this.clickAttribute = attributes;
    this.clickAttribute.push(attributes);
    console.log(this.clickAttribute);

  }

  selectAssign() {

    this.clickAttribute.forEach((item: any) => {
      this.attribute.filter((attri: any) => {
        if (item.attribute_id == attri.attribute_id) {
          let index = this.attribute.indexOf(attri);
          this.attribute.splice(index, 1);
          this.changeAttribute.push(item);
        }
        this.attributeAssigned = this.changeAttribute;
      });
    });

    console.log(this.attributeAssigned);

  }

  attributeDeAssign(attribute1: any) {
    this.attributeReClick.push(attribute1);
    console.log(this.attributeReClick);
  }

  selectReAssign() {
    this.attributeReClick.forEach((items: any) => {
      this.attributeAssigned.filter((reAssign: any) => {
        if (items.attribute_id == reAssign.attribute_id) {
          let index = this.attributeAssigned.indexOf(reAssign);
          this.attributeAssigned.splice(index, 1);
          this.changeReAttribute = items;
          this.attribute.push(this.changeReAttribute);
        }
      });
    });
    this.changeAttribute = [];
    console.log(this.attribute)

  }


}


