<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="loginpage" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="loginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">

              <!-- header -->

              <h4 class="mb-0">Admin Login</h4>
              <p class="mb-1">Enter your admin credentials</p>

              <!-- Email or Phone Number Validation -->

              <div class="form-group mb-0">
                <label class="col-form-label">Enter Email/Phone Number </label>
                <input type="text" class="form-control" formControlName="emailphone">

                <div class="errors mb-0" *ngIf="f['emailphone'].errors">
                  <p class="text-danger mb-0" *ngIf="f['emailphone'].errors['required']">Email or phone number is
                    required
                  </p>
                  <p class="text-danger mb-0" *ngIf="f['emailphone'].errors['invalidEmailphone']">Please enter valid
                    email or phone number
                  </p>
                </div>
              </div>
              <!-- password -->
              <div class="form-group mb-0">
                <div class="form-group">
                  <label class="col-form-label">Password <span class="text-danger">*</span></label>
                  <input class="form-control" [type]="passwordShow ? 'text' : 'password'" formControlName="password"
                    required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!passwordShow"><span class="show"></span>
                  </div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="passwordShow"><span class="Hide"></span>
                  </div>
                </div>
                <div class="errors mb-0" *ngIf="f['password'].errors">
                  <p class="text-danger mb-0" *ngIf="f['password'].errors['required']">Please Enter Password</p>
                  <p class="text-danger mb-0" *ngIf="f['password'].errors['pattern']"> Password must be strong</p>
                  <p class="text-danger mb-0" *ngIf="f['password'].errors['minlength']"> Password must be 8 characters</p>
                </div>
              </div>
              <!-- Password Must be min 8 characters,1 Lowercase,1 Uppercase,1 Numeric,1 Special Char -->
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" formControlName="remember"/>
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a  routerLink='/auth/forgetpassword'>Forgot password?</a>
                <div class="text-end mt-1">
                  <button class="btn btn-primary d-block w-100" (click)="onsubmit()" type="button">Login</button>
                </div>
              </div>
              <p class="mt-1 mb-0">
                Or
                <!-- Login with OTP -->
                <a routerLink='/auth/loginotp' class="ms-2">Login with OTP</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>