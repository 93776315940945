import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { folderArray } from './folder-list'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { articleArray } from './article-list';
import { categoryArray } from './category'

@Component({
  selector: 'app-knowledge-center',
  templateUrl: './knowledge-center.component.html',
  styleUrls: ['./knowledge-center.component.scss']
})
export class KnowledgeCenterComponent implements OnInit {
  // colors = ['red', 'blue', 'green'];
  // getColor(index: number): string {
  //   const colors = ['red', 'blue', 'green']; // Define your color array here
  //   return colors[index] || 'black'; // Default to black if index is out of range
  // }
  getBadgeClass(index: number): string {
    const badgeClasses = ['badge-prim', 'badge-sec', 'badge-green']; // Define your badge classes here
    return badgeClasses[index] || ''; // Default to empty string if index is out of range
  }

  selectedValue2: string = 'Article Name';
  myForm: FormGroup;

  selectedValue: string = 'Folder Name';
  filters = [{ id: 1, name: "Folder Name", ph: "Folder Name" },
  { id: 2, name: "Updated By", ph: "Updated By" },]


  filters2 = [{ id: 1, name: "Folder Name", ph: "Folder Name" },
  { id: 2, name: "Updated By", ph: "Updated By" },]

  setValue() {
    this.selectedValue = this.myForm.get('selectedValue')?.value;
    console.log(this.selectedValue);
  }
  setValue2() {
    this.selectedValue2 = this.myForm.get('selectedValue2')?.value;
    console.log(this.selectedValue2);
  }
  // Your array of folder data

  // Pagination variables
  itemsPerPage: number = 4;
  currentPage: number = 1;
  totalPages: number = 0;
  paginatedList: any[] = [];
  pages: number[] = [];


  folderList = folderArray;


  inputValue: string;
  // currentPage: number = 1;
  // itemsPerPage: number = 5;
  // totalPages: number = 0;
  // pages: number[] = [];
  // paginatedList: any[] = [];

  ngOnInit() {
    this.myForm = this.fb.group({
      sorting: ['', Validators.required],
      selectedValue: new FormControl(this.selectedValue),
      selectedValue2: new FormControl(this.selectedValue2),

    });
  }


  public selectedList: string;


  constructor(private fb: FormBuilder, private modalService: NgbModal) {
    // this.totalPages = Math.ceil(this.folderList.length / this.itemsPerPage);
    // this.totalPagesArticle = Math.ceil(this.articleList.length / this.itemsPerPage);
    // this.generatePages();
    // this.updatePaginatedCategories();
    // this.generateArticlePages();
    // this.updateArticlePaginatedCategories();
    // this.updateCategoryPaginatedCategories();
    this.paginate();
    this.paginateArticle()
    this.paginateCategory();
  }




  paginate() {
    // Calculate paginatedList, totalPages, and pages based on itemsPerPage and currentPage
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedList = this.folderList.slice(startIndex, endIndex);

    this.totalPages = Math.ceil(this.folderList.length / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginate();
    }
  }

  changeItemsPerPage() {
    this.currentPage = 1;
    this.paginate();
  }

  currentPageArticle: number = 1;
  articleList = articleArray;
  paginatedArticleList: any[];
  itemsPerPageArticle: number = 4;
  totalPagesArticle: number = 0;
  articlePage: number[] = [];
  articlepages: number[] = [];


  paginateArticle() {
    // Calculate paginatedList, totalPages, and pages based on itemsPerPage and currentPage
    const startIndex = (this.currentPageArticle - 1) * this.itemsPerPageArticle;
    const endIndex = startIndex + this.itemsPerPageArticle;
    this.paginatedArticleList = this.articleList.slice(startIndex, endIndex);

    this.totalPagesArticle = Math.ceil(this.articleList.length / this.itemsPerPageArticle);
    this.articlepages = Array.from({ length: this.totalPagesArticle }, (_, i) => i + 1);
  }


  changeItemsPerPageArticle() {
    this.currentPageArticle = 1;
    this.paginateArticle();
  }
  previousPageArticle() {
    if (this.currentPageArticle > 1) {
      this.currentPageArticle--;
      this.paginateArticle();
      // this.updateArticlePaginatedCategories();
    }
  }

  nextPageArticle() {
    if (this.currentPageArticle < this.totalPagesArticle) {
      this.currentPageArticle++;
      this.paginateArticle();
      // this.updateArticlePaginatedCategories();
    }
  }

  goToPageArticle(articlePage: number) {
    if (articlePage >= 1 && articlePage <= this.totalPagesArticle) {
      this.currentPageArticle = articlePage;
      // this.updateArticlePaginatedCategories();
      this.paginateArticle();
    }
  }

  //Category Pagination
  currentPageCategory: number = 1;
  paginatedCategoryList: any[];
  totalPagesCategory: number = 0;
  categoryPage: number[] = [];
  categorypages: number[] = [];
  itemsPerPageCategory: number = 4;
  categoryList = categoryArray;

  // currentPageCategory: number = 1;

  // totalPagesCategory: number = 0;
  // categoryPage: number[] = [];
  // categorypages: number[] = [];


  paginateCategory() {
    // Calculate paginatedList, totalPages, and pages based on itemsPerPage and currentPage
    const startIndex = (this.currentPageCategory - 1) * this.itemsPerPageCategory;
    const endIndex = startIndex + this.itemsPerPageCategory;
    this.paginatedCategoryList = this.categoryList.slice(startIndex, endIndex);

    this.totalPagesCategory = Math.ceil(this.categoryList.length / this.itemsPerPageCategory);
    this.categorypages = Array.from({ length: this.totalPagesCategory }, (_, i) => i + 1);
  }


  changeItemsPerPageCategory() {
    this.currentPageCategory = 1;
    this.paginateCategory();
  }
  previousPageCategory() {
    if (this.currentPageCategory > 1) {
      this.currentPageCategory--;
      this.paginateCategory();
      // this.updateCategoryPaginatedCategories();
    }
  }

  nextPageCategory() {
    if (this.currentPageCategory < this.totalPagesCategory) {
      this.currentPageCategory++;
      this.paginateCategory();
      // this.updateCategoryPaginatedCategories();
    }
  }

  goToPageCategory(categoryPage: number) {
    if (categoryPage >= 1 && categoryPage <= this.totalPagesCategory) {
      this.currentPageCategory = categoryPage;
      this.paginateCategory();
      // this.updateArticlePaginatedCategories();
    }
  }


}