import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MenuMasterRoutingModule } from './menu-master-routing.module';


@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    MenuMasterRoutingModule,
    NgSelectModule,
    NgbModule
  ]
})
export class MenuMasterModule { }
