import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../../theme/theme.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { StateService } from './state/state.service';
import { CityService } from './city/city.service';
import { StateComponent } from './state/state/state.component';
import { CityComponent } from './city/city.component';

const routes: Routes = [
  {
    path: 'state',
    component: StateComponent
  },
  
  {
    path: 'state/edit/:id',
    component: StateComponent,
  },

  {
    path: 'city',
    component: CityComponent
  },
  
  {
    path: 'city/edit/:id',
    component: CityComponent,
  },
  
];

@NgModule({
  declarations: [
    StateComponent,
    CityComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MatFormFieldModule, 
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule, 
    MatSortModule,
    MatNativeDateModule,
    ThemeModule,
    ReactiveFormsModule,
    ThemeModule,
    RouterModule.forChild(routes),
  ],
  providers:[StateService, CityService]
})
export class MastersModule { }
