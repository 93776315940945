<div class="container-fluid pt-3">
    <h4>User List</h4>
    <div class="row">
        <div class="col-sm-12">
            <div class="card" *ngIf="users.length > 0">
                <div class="card-header pt-1">
                    <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="col-form-label">Search</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="igeet" formControlName="search">                                        
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label class="col-form-label">Status</label>
                                <div class="input-group">
                                    <ng-select [items]="status" bindValue="id" [clearable]="false" bindLabel="name"
                                    class="custom col-12" placeholder="Select status" formControlName="status"></ng-select>                                        
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label class="col-form-label">Sort By</label>
                                <div class="input-group">
                                    <ng-select [items]="sortBy" bindValue="value" [clearable]="false" bindLabel="name"
                                    class="custom col-12" placeholder="Select status" formControlName="sort"></ng-select>                                        
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-end mt-4 pt-2">
                            <a class="btn btn-lg btn-primary" routerLink="/user-management/user/create">
                                <i class="fa fa-plus mx-2"></i> Add User</a>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-3">                                
                                    <a class="btn btn-secondary mx-2" (click)="onClear()">Clear</a>
                                    <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                            </div>
                        </div>
                        
                    </div>
                    </form>
                </div>
                
                <div class="card-body">
                    <!-- <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="search" #input>
                      </mat-form-field> -->
                    

                    <!-- <div class="spinner-container" *ngIf="users.loading$ | async">
                        <mat-spinner></mat-spinner>
                    </div> -->
                    

                    <mat-table class="" [dataSource]="users" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="username">
                            <mat-header-cell *matHeaderCellDef>User Name</mat-header-cell>
                            <mat-cell *matCellDef="let user">{{ user.username }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                            <mat-cell class="email-cell" *matCellDef="let user">{{
                                user.email
                                }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="role">
                            <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
                            <mat-cell class="role-cell" *matCellDef="let user">{{
                                getRole(user.roles)
                                }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                            <mat-cell class="status-cell" *matCellDef="let user">{{
                                getStatusName(user.status)
                                }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                            <mat-cell class="action-cell" *matCellDef="let user">
                                <div class="action">
                                    <li class="edit me-3">
                                        <a [routerLink]="['/user-management/user/edit', user.id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li class="delete" (click)="onSelect(user.id)">
                                        <a>
                                            <i class="icon-trash"></i>
                                        </a>
                                    </li>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                </div>
                <div class="card-footer">
                    <mat-paginator [length]="paginationMeta.totalItems" 
                        [pageIndex] = 0
                        [pageSize]="paginationMeta.itemsPerPage"
                        [pageSizeOptions]="[5, 10, 20, 50]" (page)="pageEvent = getServerData($event)"
                        [showFirstLastButtons]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>