import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';


import { ThemeModule } from '../../../theme/theme.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateDirectoryComponent } from '../create-modules/create-directory/create-directory.component';
import { CreateNewArticleComponent } from '../create-modules/create-new-article/create-new-article.component'
import { CreateNewCatagoryComponent } from '../create-modules/create-new-catagory/create-new-catagory.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { KnowledgeCenterComponent } from './knowledge-center/knowledge-center.component';
import { CreateNewAnnouncementComponent } from './create-new-announcement/create-new-announcement.component';
import { CreateNewFlashAnnouncementComponent } from './create-new-flash-announcement/create-new-flash-announcement.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { FlashAnnouncementComponent } from './flash-announcement/flash-announcement.component';
import { PageComponent } from '../create-modules/page/page.component';
import { PageListComponent } from './page/page-list/page-list.component';

const routes: Routes = [
  {
    path: 'page-create',
    component: PageComponent,
  }, {
    path: 'page-list',
    component: PageListComponent,
  },
  {
    path: 'announcement',
    component: AnnouncementComponent,
  },
  {
    path: 'flash-announcememt',
    component: FlashAnnouncementComponent,
  },


  {
    path: 'create-new-flashannouncement',
    component: CreateNewFlashAnnouncementComponent,
  },
  {
    path: 'create-new-announcement',
    component: CreateNewAnnouncementComponent,
  },

  {
    path: 'create-folder',
    component: CreateDirectoryComponent,
  },

  {
    path: 'create-category',
    component: CreateNewCatagoryComponent,
  },
  {
    path: 'create-article',
    component: CreateNewArticleComponent,
  },
  {
    path: 'knowledge-center',
    component: KnowledgeCenterComponent
  }

];
@NgModule({
  declarations: [
    CreateDirectoryComponent, PageComponent,
    CreateNewArticleComponent,
    CreateNewCatagoryComponent, KnowledgeCenterComponent, CreateNewAnnouncementComponent, CreateNewFlashAnnouncementComponent, AnnouncementComponent, FlashAnnouncementComponent, PageListComponent,
  ],
  imports: [
    FormsModule, CKEditorModule,
    ReactiveFormsModule,
    CommonModule, NgSelectModule,
    ThemeModule,

    RouterModule.forChild(routes)
  ]
})
export class CreateModule { }
