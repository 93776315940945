import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { ServerResponseState } from 'src/app/core/modals/response-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryServices {

  constructor(private http: HttpClient) { }
  public errorMsg: string;

  public create(post) {
    return this.http.post<ServerResponseState>(`${environment.apiUrl}/category/create`, post)
      .pipe(
        map((res: ServerResponseState) => {
          console.log(res);
          if (res.statusCode == 201 && res.status == 'success') {
            return { status: true, data: res.data };
          } else if (res.statusCode == 201 && res.status == 'failed') {
            return { status: false, errors: res.error, message: res.message };
          } else {
            console.log(res);
          }
        }),
        catchError(this.handleError)
      );
  }

  findAll(params) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/category/list`, { params: params }).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  delete(data) {
    return this.http.post<ServerResponseState>(`${environment.apiUrl}/category/delete`, data).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 201 && res.status == 'success') {
          return { status: true, data: res.data, message: res.message };
        } else if (res.statusCode == 201 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  findGetChild(id: number) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/category/get-child/${id}`).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  public update(post) {
    return this.http.post<ServerResponseState>(`${environment.apiUrl}/category/update`, post)
      .pipe(
        map((res: ServerResponseState) => {
          console.log(res);
          if (res.statusCode == 201 && res.status == 'success') {
            return { status: true, data: res.data };
          } else if (res.statusCode == 201 && res.status == 'failed') {
            return { status: false, errors: res.error, message: res.message };
          } else {
            console.log(res);
          }
        }),
        catchError(this.handleError)
      );
  }

  findOne(id: number) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/category/edit/${id}`).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  getSearch(data:string) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/category/search/${data}`).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 400) {
      return error.error.message;
    }
    return throwError(error);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }

      case 403: {
        return `Access Denied: ${error.message}`;
      }

      case 500: {
        return `Internal Server Error: ${error.message}`;
      }

      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }


}
