<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" [formGroup]="signupForm">

                <h4>Reset Your Password</h4>
                <p>Please enter your official email or mobile number,and we will send OTP to reset your password
                </p>
                <div formGroupName="emailGroup">
                  <div class="form-group">
                    <label class="col-form-label">Email/Mobile Number</label>
                    <div class="input-group">
                      <input class="form-control" required="" placeholder="prabhu@gmail.com" (keyup)="validateEmail()"
                        formControlName="mail">
                      <button class="btn btn-primary" type="button" (click)="sendEmailOtp()"
                        [disabled]="!emailButtonEnabled">Send OTP</button>
                    </div>

                    <div class="errors" *ngIf=" f['emailGroup']['controls'].mail.errors">
                      <p class="text-danger" *ngIf="f['emailGroup']['controls'].mail.errors['required']">Email or Mobile
                        Number is required
                      </p>
                      <p class="text-danger" *ngIf="f['emailGroup']['controls'].mail.errors['invalidEmailphone']">Invalid Email or
                        Mobile Number

                    </div>
                  </div>
                  <!-- <div class="text-center "><span class="reset-password-link">If don't receive OTP?<a
                        class="btn-link text-danger" routerLink='/'>Resend</a></span></div> -->


                  <div class="form-group" *ngIf="emailOtpField">
                    <label class="col-form-label pt-0">Enter Authentication Code</label>
                    <div class="input-group">

                      <input class="form-control text-center" placeholder="Enter 6 Digit OTP" formControlName="emailOtp"
                        onlyNumbers>


                      <button class="btn btn-primary " (click)="verfiyEmailOtp() ">Verify</button>
                    </div>
                    <div class="errors" *ngIf="f['emailGroup']['controls'].emailOtp.errors">
                      <p class="text-danger" *ngIf="f['emailGroup']['controls'].emailOtp.errors['required']">Please
                        Enter
                        OTP</p>
                      <p class="text-danger" *ngIf="f['emailGroup']['controls'].emailOtp.errors['pattern']">Invalid OTP
                      </p>
                      <p class="text-danger " *ngIf="f['emailGroup']['controls'].emailOtp.errors['minlength']">Please
                        Enter 6 digit OTP</p>
                    </div>
                  </div>

                </div>
                <div class="form-group" *ngIf="resetPwdField">
                  <h5>Create Your Password</h5>
                  <div class="form-group mb-0">
                    <div class="form-group">
                      <label class="col-form-label">Password <span class="text-danger">*</span></label>
                      <input class="form-control" [type]="passwordShow ? 'text' : 'password'" formControlName="password"
                        required="" placeholder="*********" placement="top" ngbPopover="
                     " popoverTitle="Password Must be min 8 characters. 1 Lowercase,
                     1 Uppercase,
                     1 Numeric,
                     1 Special Char">
                      <div class="show-hide" (click)="showPassword()" *ngIf="!passwordShow"><span class="show"></span>
                      </div>
                      <div class="show-hide" (click)="showPassword()" *ngIf="passwordShow"><span class="Hide"></span>
                      </div>
                    </div>
                    <div class="errors mb-0" *ngIf="f['password'].errors">
                      <p class="text-danger mb-0" *ngIf="f['password'].errors['required']">Please Enter Password</p>
                      <!-- <p class="text-danger mb-0" *ngIf="f['password'].errors['pattern']"> Password must be strong</p>
                    <p class="text-danger mb-0" *ngIf="f['password'].errors['minlength']"> Password must be 8 characters
                    </p> -->
                    </div>
                  </div>
                  <!-- Password Must be min 8 characters,1 Lowercase,1 Uppercase,1 Numeric,1 Special Char -->
                  <div class="form-group mb-0">
                    <label>Confirm Password</label>

                    <input class="form-control" type="password" placeholder="*********"
                      formControlName="confirmPassword">
                    <div class="errors" *ngIf="f['confirmPassword'].errors">
                      <p class="text-danger" *ngIf="f['confirmPassword'].errors['required']">please Confirm password
                      </p>
                      <p class="text-danger" *ngIf="f['confirmPassword'].errors['mismatch']">password must be match
                      </p>
                    </div>

                  </div>
                  <div class="form-group ">
                    <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div> -->
                    <button class="btn btn-primary d-block w-100" type="submit">SET NEW PASSWORD</button>
                  </div>
                </div>
                <p class=" mb-0">Already have an password?<a class="ms-2" routerLink="/auth/login">Sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>