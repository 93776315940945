<div class="container-fluid">
    <div class="row">
        <div class="col-lg-4">
            <h3 class="mt-3">Status</h3>
            <div class="card">
                <div class="card-body  ">

                    <!-- Add status Start here  -->
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <h4>Add status</h4>

                        <div class="form-group mb-2">
                            <div class="input-group media-body">
                                <label class="col-form-label col-12 "><span class="mb-1">Group</span>

                                </label>
                                <div class="col-12">
                                    <ng-select [items]="filters" [clearable]="false" (click)="setValue()" class="custom"
                                        bindValue="name" bindLabel="name" formControlName="group" multiple></ng-select>
                                </div>
                                <div class="errors mb-0" *ngIf="submitted && form.get('group').errors">
                                    <p class="text-danger mb-0" *ngIf="form.get('group').errors.required">
                                        Group is required
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div class="form-group mb-2">
                            <label>Key</label>
                            <input class="form-control" id="key" type="text" placeholder="Enter Key"
                                formControlName="key" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && form.get('key').errors">
                                <p class="text-danger mb-0" *ngIf="form.get('key').errors.required">
                                    Key is required
                                </p>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <label>Value</label>
                            <input class="form-control" id="key" type="text" placeholder="Enter Value"
                                formControlName="value" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && form.get('value').errors">
                                <p class="text-danger mb-0" *ngIf="form.get('value').errors.required">
                                    Value is required
                                </p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 text-end">
                                <button class="btn btn-md btn-primary" type="submit" (click)="onSubmit()">Save</button>

                            </div>
                        </div>
                    </form>
                    <!-- Add status Ends here  -->
                </div>
            </div>
        </div>
        <div class="col-lg-8 statuslist">

            <div class="card">
                <div class="card-body ">
                    <h4>Status List</h4>

                    <!--  status table Starts here  -->
                    <div class="table-responsive">
                        <table class="table  ">
                            <thead>
                                <tr class="align-items-center text-dark py-2 border-bottom mx-0">
                                    <th scope="col">#</th>
                                    <th scope="col" class="">Key</th>
                                    <th scope="col" class="">Value</th>
                                    <th scope="col" class="">Group</th>
                                    <th scope="col text-end" class="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>

                                    <td class="text-start" scope="col">
                                        <input type="text" />
                                    </td>
                                    <td class="" scope="col"><input type="text" /></td>
                                    <td class="" scope="col"><input type="text" /></td>
                                    <td></td>

                                </tr>
                                <tr *ngFor="let status of paginatedList; let i = index">



                                    <td>{{status.sNo }}</td>
                                    <td>{{status.key }}</td>
                                    <td>{{status.value }}</td>
                                    <td>{{status.group }}</td>
                                    <td>
                                        <div class="d-flex justify-content-end action-icon">
                                            <a href="#" class="ms-1 pencil-icon"><i class="icon-pencil-alt"></i></a>
                                            <a href="#" class="ms-1 trash-icon"><i class="icon-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <!--  status table Ends here  -->
                </div>

                <!-- Pagination Start Here  -->

                <nav *ngIf="totalPages > 1" class="me-4 mb-2">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" [class.disabled]="currentPage === 1">
                            <a class="page-link" href="javascript:void(0)" (click)="previousPage()">Previous</a>
                        </li>
                        <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages">
                            <a class="page-link" href="javascript:void(0)" (click)="goToPage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage === totalPages">
                            <a class="page-link" href="javascript:void(0)" (click)="nextPage()">Next</a>
                        </li>
                    </ul>
                </nav>

                <!-- Pagination Ends Here  -->
            </div>
        </div>
    </div>
</div>