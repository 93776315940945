import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryServices } from '../services/category-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent {
  languages: Array<any> = environment.languages;
  categoryForm: FormGroup;
  categoryGroupDiv = false;
  parentCategory: number = 0;
  categoryData: any = [];
  categoryLevelData: any = [];
  parentsData: any = [];
  parents: any = [];
  parent_id: number = 0;
  level: number = 1;
  categoryLevels: Array<any> = [];
  categoryId: number;
  firstLevelId: number = 0;
  secondLevelId: number = 0;
  thirdLevelId: number = 0;
  firstLevel: Array<any> = [];
  secondLevel: Array<any> = [];
  thirdLevel: Array<any> = [];
  searchResults:any = [];
  banners_list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  layoutsList = [
    { id: 1, name: 'Layout 1' }, { id: 2, name: 'Layout 2' }, { id: 3, name: 'Layout 3' }
  ];

  attrubitesList = [
    { id: 1, name: 'Electronics' }, { id: 2, name: 'Books' }, { id: 3, name: 'Fashion' }
  ];

  filters = [
    { id: 1, name: 'Option 1' }, { id: 2, name: 'Option 2' }, { id: 3, name: 'Option 3' }
  ];

  commission = [
    { id: 1, name: '3 %' }, { id: 2, name: '5 %' }, { id: 3, name: '10 %' }
  ];

  public activePillIndex: number = 0;

  step1Validate = new BehaviorSubject(false);
  step2Validate = new BehaviorSubject(false);
  step3Validate = new BehaviorSubject(false);
  step4Validate = new BehaviorSubject(false);
  step5Validate = new BehaviorSubject(false);
  step6Validate = new BehaviorSubject(false);


  public validate: any;
  submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private categoryService: CategoryServices,
    private route: ActivatedRoute,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.categoryId = Number(routeParams.get("id"));
    this.categoryForm = this.fb.group({
      category: this.fb.array([]),
    });
  }

  async ngOnInit() {
    this.categoryData = [];
    this.parents = [];
    this.parentsData = [];
    await this.categoryService.findOne(this.categoryId).subscribe((res: any) => {
      this.categoryData = res.data.category;
      this.parentsData = res.data.parents;
      this.parents = JSON.parse(this.categoryData.parents);
      let transformedData = this.createCategoryFormGroup(this.categoryData);
      this.categoryForm.setControl('category', transformedData);
      this.setSelectedCategories();
    });
  }

  translationFormGroup(translation: any) {
    return this.fb.group({
      language_id: [translation.local_id],
      name: [translation.name, [Validators.required]],
      meta_title: [translation.meta_title, [Validators.required]],
      description: translation.description,
      meta_keywords: translation.meta_keywords,
      meta_description: translation.meta_description,
    });
  }

  loadTranslationsArray(translations: any[]) {
    let _translations = translations.map((transalation: any) => this.translationFormGroup(transalation));
    return _translations;
  }

  createCategoryFormGroup(category: any) {
    return this.fb.group({
      first: this.fb.group({
      }),
      translations: this.fb.array(this.loadTranslationsArray(category.translation)),
      third: this.fb.group({
        enable_category: [true, Validators.required],
        visible_front: [category.visible_front, Validators.required],
        visible_seller: [category.visible_seller, [Validators.required]],
        restricted_seller: [true, Validators.required],
        seller_access: [null, [Validators.required]],
        position: [category.position, [Validators.required, Validators.pattern(/^(?=.*[0-9])/)]],
        layout_id: [category.layout_id, [Validators.required]],
      }),
      fourth: this.fb.group({
        attribute_set_id: [category.attribute_set_id, [Validators.required]],
        filter_list: [null, [Validators.required]],
      }),
      fifth: this.fb.group({
        commission: [category.commission, [Validators.required, Validators.pattern(/^(?=.*[0-9])/)]],
      }),
      sixth: this.fb.group({
        thumb: this.fb.array([], [Validators.required]),
        banners: this.fb.array([]),
        products: this.fb.array([], [Validators.required]),
      })
    })
  }

  get form() {
    return this.categoryForm.controls.category;
  }

  get first() {
    return this.form.get('first');
  }

  get third() {
    return this.form.get('third');
  }


  get fourth() {
    return this.form.get('fourth');
  }

  get fifth() {
    return this.form.get('fifth');
  }

  get sixth() {
    return this.form.get('sixth');
  }

  public selectPill(index: number) {
    this.activePillIndex = index;
    console.log(this.activePillIndex + 1);
  }

  bannerFileGroup() {
    return this.fb.group({
      file: [null]
    })
  }

  get translations(): FormArray {
    return this.form.get('translations') as FormArray
  }

  get thumb(): FormArray {
    return this.sixth.get('thumb') as FormArray
  }

  get banners(): FormArray {
    return this.sixth.get('banners') as FormArray
  }

  get products(): FormArray {
    return this.sixth.get('products') as FormArray
  }

  bannerFromGroup() {
    this.banners_list.forEach(banner => {
      this.banners.push(this.bannerFileGroup());
    })
  }

  setSelectedCategories() {
    if (this.parents != null) {
      this.parents.forEach((parent: any) => {
        if (parent.level == 1) {
          this.firstLevelId = parent.category_id;
          this.updateCategoryLevel(parent, 1);
        } else if (parent.level == 2) {
          this.secondLevelId = parent.category_id;
          this.updateCategoryLevel(parent, 2);
        } else if (parent.level == 3) {
          this.updateCategoryLevel(parent, 3);
          this.thirdLevelId = parent.category_id;
        }
      });
    }
  }

  
  updateCategoryLevel(item: any, level: number) {
    if (level == 1) {
      this.categoryLevels = [];
      this.categoryLevels.push(item);
    } else {
      this.categoryLevels.map((element: any, index) => {
        if (element.level == item.level) {
          this.categoryLevels.splice(index);
        }
      });
      this.categoryLevels.push(item)
    }
    console.log(this.categoryLevels);
  }

  getSelectedPath(): string {
    let path = [];
    this.categoryLevels.forEach((element: any) => {
      path.push(element.name);
    });
    return (path.length == 0) ? '' : path.join(' > ');
  }

  selectedFile = [];
  selectedFile1 = [];
  selectedFile2 = [];

  onSelect(files: File[] = []) {
    this.selectedFile = files;
  }

  onSelect1(files1: File[] = []) {
    this.selectedFile1.push(files1);
    this.selectedFile1.push(files1);
  }

  onSelect2(files2: File[] = []) {
    this.selectedFile2 = files2;
  }

  moveDirection = (validityStatus, direction) => {
    return validityStatus;
  };

  step1() {
    this.step1Validate.next(true);
    // console.log(this.first.value);
    // console.log(this.parent_id);
    // this.categoryLevels.push(this.thirdLevelData);
    // console.log(this.categoryLevels);
  }

  step2() {
    this.step2Validate.next(true);
    // console.log(this.translations);
  }

  step3() {
    this.step3Validate.next(true);
  }

  step4() {
    this.step4Validate.next(true);
  }

  step5() {
    this.step5Validate.next(true);
  }

  async step6() {
    const img = this.fb.group({
      files: [this.selectedFile],
    });

    if (this.selectedFile.length != 0) {
      this.thumb.push(img);
    }
    // console.log(this.thumb.status);

    const img1 = this.fb.group({
      files: [this.selectedFile1],
    });
    if (this.selectedFile1.length != 0) {
      this.banners.push(img1);
    }

    // console.log(this.selectedFile1);

    const img2 = this.fb.group({
      files2: [this.selectedFile2],
    });

    if (this.selectedFile2.length != 0) {
      this.products.push(img2);
    }

    this.step3Validate.next(true);
    this.submitted = true;
    this.validate = !this.validate;

    const postData = {
      id: this.categoryId,
      position: this.third.value.position,
      //  thumb: this.sixth.value.thumb,
      //  icon: this.sixth.value.thumb,
      visible_front: this.third.value.visible_front,
      visible_seller: this.third.value.visible_seller,
      layout_id: this.third.value.layout_id,
      attribute_set_id: this.fourth.value.attribute_set_id,
      value: this.fifth.value.commission,
      translations: this.translations.value,
    }

    // console.log(this.categoryForm);
    // console.log(postData);

    if (this.categoryForm.status == 'VALID') {
      const response = await this.categoryService.update(postData).subscribe((res: any) => {
        // console.log(res);
        if (res.status == true) {
          // this.toast.success('User Profile has been added successfully');
          // return this.router.navigate(['/user-management/user']) && res.data;
        } else {
          // this.toast.error(res.errors);
          // this.errors = res;
          return res;
        }
        // }, (error) => {
        //   return error;
      }
      );
      // console.log(response);
    }
  }

  enterStep(event) {
    console.log(event);
  }
}
