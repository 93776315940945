import { StorageService } from 'src/app/shared/services/storage.service';
import { Component, OnDestroy, OnInit, Optional } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CategoryDB } from 'src/app/theme/data/category/category';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  firstLevel$: Array<any> = [];
  secondLevel$: Array<any> = [];
  CategoryDB: Array<any> = [];
  parents: Array<any> = [];
  selectedItems$: Array<any> = [];
  checks: boolean = false;;
  currentParentId: number = 0;

  firstLevelKey: string = 'FIRST_LEVEL';
  secondLevelKey: string = 'SECOND_LEVEL';
  selectedItemsKey: string = 'SELECTED_ITEMS';

  constructor(
    @Optional() private readonly activeModal: NgbActiveModal,
    private storage: StorageService
  ) {

  }
  ngOnDestroy(): void {
    /* this.storage.remove(this.firstLevelKey);
    this.storage.removeByPrefix(this.secondLevelKey);
    this.storage.remove(this.selectedItemsKey); */
  }

  async ngOnInit() {
    this.deleteStorages();
    await this.getFirstLevelData();
    this.selectedItems$ = [];
  }

  deleteStorages(): void {
    this.storage.remove(this.firstLevelKey);
    this.storage.removeByPrefix(this.secondLevelKey);
    this.storage.remove(this.selectedItemsKey);
  }

  public confirm(): void {
    if (this.activeModal)
      this.activeModal.close();
  }

  public dismiss(): void {
    this.deleteStorages();
    if (this.activeModal)
      this.activeModal.dismiss();
  }

  async getFirstLevelData() {
    let firstLevelData = await this.storage.get(this.firstLevelKey);
    if (!firstLevelData) {
      firstLevelData = CategoryDB.first_level;
      this.storage.set(this.firstLevelKey, firstLevelData);
    }
    this.firstLevel$ = firstLevelData;
  }

  async onFirstLevelSelected(e, item) {
    await this.getSecondLevelData(item.id);
  }

  async onFirstLevelClick(e, item) {
    console.log(e.target.value);
    if (e.target.checked == true) {
      let firstLevelData = await this.storage.get(this.firstLevelKey);
      firstLevelData.map((data) => {
        if (data.id === item.id) {
          data.selected = true;
        }
      });
      this.firstLevel$ = firstLevelData;
      this.storage.set(this.firstLevelKey, firstLevelData);

      let secondLevelData = await this.getSecondLevelData(item.id);
      secondLevelData.map(data => {
        data.selected = true;
      });
      this.selectedItems$.push(...secondLevelData);
      this.storage.remove(this.secondLevelKey + '_' + item.id);
      this.storage.set(this.secondLevelKey + '_' + item.id, secondLevelData);
    }else{
      let firstLevelData = await this.storage.get(this.firstLevelKey);
      firstLevelData.map(data => {
        if (data.id === item.id) {
          data.selected = false;
        }
      });
      this.firstLevel$ = firstLevelData;
      this.storage.set(this.firstLevelKey, firstLevelData);

      let secondLevelData = await this.getSecondLevelData(item.id);
      secondLevelData.map((data,index) => {
        data.selected = false;
      });
      this.storage.remove(this.secondLevelKey + '_' + item.id);
      this.storage.set(this.secondLevelKey + '_' + item.id, secondLevelData);
    }
  }

  onSecondLevelClick() { }

  async getSecondLevelData(parent) {
    let secondLevelData = await this.storage.get(this.secondLevelKey + '_' + parent);
    if (!secondLevelData) {
      secondLevelData = this.loadChildCategories(parent);
      this.storage.set(this.secondLevelKey + '_' + parent, secondLevelData)
    }
    this.secondLevel$ = secondLevelData;
    return secondLevelData;
  }

  loadChildCategories(parent) {
    let child = [];
    CategoryDB.second_level.forEach((res: any) => {
      if (res.idpath.includes(parent)) {
        let _child = {
          id: res.id,
          name: res.name,
          idpath: res.idpath,
          path: res.path,
          selected: false
        }
        child.push(_child);
      }
    });
    return child;
  }

  async getSelected() {
    let selected = await this.storage.get(this.selectedItemsKey);
    if (!selected) {
      selected = [];
    }
    this.selectedItems$ = selected;
    return selected;
   
  }

  /*
  onParentSelect(item, e) {
    let exist: boolean = this.checkParentKeyExistInSelectedItem(item.id);
    if (exist == false) { this.getsecondLevelData(item.id, false); }
    else { this.selectFromSelected(item.id); }
  }

  checkParentKeyExistInSelectedItem(id): boolean {
    let exist: boolean = false;
    this.selectedValues$.forEach(item => {
      if (item.parent == id) {
        exist = true;
      }
    });
    return exist;
  }

  selectFromSelected(id) {
    let secondLevel = this.getChildArray(id, true);
    let data = secondLevel.filter((item, index) => {
      console.log(item);
    });
     this.selectedValues$.filter(item => {
      if (item.parent == id) {
        this.secondLevel$.next(item.child);
      }
    }); 
  }

  onParentCheck(event, item) {

    this.getsecondLevelData(item.id, true);
    this.currentParentId = item.id;
    if (event.target.checked == true) {
      item.checked = event.target.checked;
      // this.checks = true;
      this.parents.push(item.id);
      let select = { parent: item.id, child: this.getChildArray(item.id, true) }
      this.selectedValues$.push(select);
      console.log(this.selectedValues$);
    }
    else {
      //this.getsecondLevelData('', false)
      // this.checks =  false;
      this.secondLevel$.asObservable();

      this.selectedValues$.filter((res: any, index) => {
        if (res.parent = item.id) {
          this.selectedValues$.splice(index, 1);
        }
      });
    }
  }

  onChangeSecondLevel(checked: boolean, item) {
    if (checked == false) {
      //this.uncheckChildArray(this.currentParentId, item.id);
      this.selectedValues$.forEach((res: any) => {
        if (res.parent === this.currentParentId) {
          res.child.filter((childRes: any, index) => {
            if (childRes.id === item.id) {
              res.child.splice(index, 1);
            }
          });
        }
      });
    } else {
      let parentExist: boolean = this.checkParentKeyExistInSelectedItem(item.idpath[0]);
      if (parentExist == false) {
        let child = [];
        child.push(item);
        let select = { parent: item.id, child: child };
        this.selectedValues$.push(select);
      } else {
        this.selectedValues$.forEach((res: any, index) => {
          if (res.parent === item.idpath[0]) {
            this.selectedValues$[index].child.push(...item);
          }
        });
      }
    }
  }

  removeItem(item, index) {
    this.selectedValues$.forEach((res: any) => {
      this.uncheckChildArray(res.parent, item.id);
      res.child.filter((childRes: any, index) => {
        if (childRes.id === item.id) {
          res.child.splice(index, 1);
        }
      });
    });
  }

  uncheckChildArray(parent, child) {
    let _category = [];
    this.secondLevel$.value.forEach((res) => {
      if (res.id == child) {
        let _child = {
          id: res.id,
          name: res.name,
          idpath: res.idpath,
          path: res.path,
          selected: false,
        }
        _category.push(_child)
      } else {
        _category.push(res);
      }
    });
  }

  getChildArray(parent: any, select: boolean) {
    let _category = []
    CategoryDB.second_level.forEach((res: any) => {
      if (res.idpath.includes(parent)) {
        let childKeyExits = this.checkChildExists(parent, res.id);
        let _child = {
          id: res.id,
          name: res.name,
          idpath: res.idpath,
          path: res.path,
          selected: (childKeyExits == true) ? true : select,
        }
        _category.push(_child)
      }
    });
    return _category;
  }


  checkChildExists(parent, item): boolean {
    let exists: boolean = false;
    this.selectedValues$.forEach((res: any) => {
      if (res.parent === parent) {
        res.child.forEach((childRes: any) => {
          if (childRes.id == item) {
            exists = true;
          }
        });
      }
    });
    return exists;
  }

  getFirstLevelData() {
    this.firstLevel = CategoryDB.first_level;
  }

  getsecondLevelData(parent, select) {
    this.secondLevel$.asObservable();
    this.secondLevel$.next(this.getChildArray(parent, select));
  }

  getSelected() {
    let _child = [];
    this.selectedValues$.forEach((res: any) => {
      return _child.push(...res.child);
    });
    return _child;
  }
*/


}

