import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeModule } from '../../../app/theme/theme.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { TicketViewComponent } from './ticket-view/ticket-view.component';
// import { TicketListComponent } from './ticket-list/ticket-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketViewComponent } from './ticket-view/ticket-view.component';

const routes: Routes = [
  {
    path: '',
    component: TicketViewComponent,
  },
  // {
  //   path: 'list',
  //   component: TicketListComponent,

  // },

];

@NgModule({
  declarations: [
    TicketViewComponent
  ],
  imports: [
    CommonModule, CKEditorModule, NgbModule, FormsModule, ReactiveFormsModule, NgSelectModule, ThemeModule, RouterModule
  ]
})
export class TicketsModule { }


