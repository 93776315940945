<div cdkDropListGroup class="container-fluid">
    <div class="row mt-3">
        <h4 class="col-8">Assignment : Attribute Family </h4>
        <div class="col-4 text-end">
            <button class="btn btn-md btn-primary me-2" type="submit" (click)="reset()">Reset</button>
            <button class="btn btn-md btn-primary" type="submit" (click)="save()">Save</button>

        </div>
    </div>
    <div class="card  mt-3">
        <div class="card-body row">
            <div class="container col-lg-5">
                <h2>Available</h2>

                <div cdkDropList [cdkDropListData]="attribute" class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let item of attribute" cdkDrag>{{item.code}}</div>
                </div>
            </div>

            <div class="container col-lg-5">
                <h2>Assigned</h2>
                <div *ngFor="let item of attributes">
                    <h6>{{item.name}}</h6>
                    <div cdkDropList [cdkDropListData]="item.assigned" class="example-list"
                        (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let items of item.assigned" cdkDrag>{{items.code}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>