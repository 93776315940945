<div class="container-fluid">
    <div class="row">
        <h4>Attribute Family </h4>
        <div class="col-lg-4">
            <div class="card mt-3">
                <div class="card-body">
                    <h4>{{ familyId ? 'Edit Attribute Family' : 'Create Attribute Family'}}</h4>
                    <form [formGroup]="familyCreateForm">

                        <div class="form-group mb-2">
                            <label>Family Name</label>
                            <input class="form-control" id="name" type="text" placeholder="Enter attribute family name"
                                formControlName="name" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && familyCreateForm.get('name').errors">
                                <p class="text-danger mb-0" *ngIf="familyCreateForm.get('name').errors.required">
                                    Attribute Family Name is required
                                </p>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label>Attribute Family Code</label>
                            <input class="form-control" id="code" type="text" placeholder="Enter Attribute Code"
                                formControlName="code" />
                            <div class="errors mb-0" *ngIf="submitted && familyCreateForm.get('code').errors">
                                <p class="text-danger mb-0" *ngIf="familyCreateForm.get('code').errors.required">
                                    Attribute Family Code is required
                                </p>
                            </div>
                        </div>

                        <div id="store-front-properties" class="mt-2 pt-2">

                            <div class="row form-switch p-l-0">
                                <div class="text-start my-2">
                                    <label class="col-6 form-label" for="system_attribute">
                                        Is User Defined
                                    </label>
                                    <input class="col-6 form-check-input" type="checkbox" role="switch"
                                        formControlName="is_user_defined" id="is_user_defined" checked>
                                </div>
                            </div>

                            <div class="row form-switch p-l-0">
                                <div class="text-start my-2">
                                    <label class="col-6 form-label" for="flexSwitchCheckChecked">
                                        Status
                                    </label>
                                    <input class="col-6 form-check-input" type="checkbox" role="switch"
                                        formControlName="status" id="status-btn" checked>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group mb-2">
                            <label>System Attribute Family</label>
                            <input class="form-control" id="is_user_defined" type="text"
                                placeholder="Enter Attribute Code" formControlName="is_user_defined" />

                            <div class="errors mb-0"
                                *ngIf="submitted && familyCreateForm.get('is_user_defined').errors">
                                <p class="text-danger mb-0"
                                    *ngIf="familyCreateForm.get('is_user_defined').errors.required">
                                    System Attribute is required
                                </p>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label>Status</label>

                            <ng-select [items]="status" bindValue="id" [clearable]="false" bindLabel="value"
                                class="custom" placeholder="Select status" formControlName="status"></ng-select>

                            <div class="errors mb-0" *ngIf="submitted && familyCreateForm.get('status').errors">
                                <p class="text-danger mb-0" *ngIf="familyCreateForm.get('status').errors.required">
                                    Status is required
                                </p>
                            </div>
                        </div> -->

                        <div class="row mt-2">
                            <div class="col-12 text-end">
                                <button class="btn btn-md btn-primary" type="submit" (click)="onSubmit()">Save</button>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>

        <div class="col-lg-8 ">
            <div class="card mt-3">

                <div class="card-header pt-1">
                    <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label">Search</label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="search" placeholder="igeet">
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label class="col-form-label">Sort By</label>
                                    <div class="input-group">
                                        <ng-select [items]="sortBy" bindValue="value" [clearable]="false"
                                            bindLabel="value" class="custom col-12" formControlName="sortBy"
                                            placeholder="Select sort order" formControlName="sort"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center mt-3">
                                    <a class="btn btn-secondary mx-2" (click)="onClear()">Clear</a>
                                    <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div *ngIf="attributeFamily.length > 0">
                    <div class="card-body">
                        <h4>Attribute Family</h4>
                        <mat-table class="" [dataSource]="attributeFamily" matSort matSortActive="id"
                            matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="slNo">
                                <mat-header-cell *matHeaderCellDef>Sl.No</mat-header-cell>
                                <mat-cell class="slNo-cell"
                                    *matCellDef="let attributeFamily; let i = index">{{i+1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                                <mat-cell class="name-cell"
                                    *matCellDef="let attributeFamily">{{attributeFamily.name}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Code</mat-header-cell>
                                <mat-cell class="code"
                                    *matCellDef="let attributeFamily">{{attributeFamily.code}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                                <mat-cell class="status-cell" *matCellDef="let attributeFamily">
                                    {{getStatusName(attributeFamily.status)}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                <mat-cell class="action" *matCellDef="let attributeFamily">
                                    <li class="edit me-3">
                                        <a
                                            [routerLink]="['/catalog/attribute-family/edit', attributeFamily.attribute_family_id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li class="delete me-3" (click)="onDelete(attributeFamily.attribute_family_id)">
                                        <a>
                                            <i class="icon-trash"></i>
                                        </a>
                                    </li>
                                    <li class="assign">
                                        <a
                                            [routerLink]="['/catalog/attribute-family/assign', attributeFamily.attribute_family_id]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 24 24">
                                                <path fill="black"
                                                    d="m14.1 9l.9.9L5.9 19H5v-.9L14.1 9m3.6-6c-.2 0-.5.1-.7.3l-1.8 1.8l3.7 3.8L20.7 7c.4-.4.4-1 0-1.4l-2.3-2.3c-.2-.2-.5-.3-.7-.3m-3.6 3.2L3 17.2V21h3.8l11-11.1l-3.7-3.7M7 2v3h3v2H7v3H5V7H2V5h3V2h2Z" />
                                            </svg>
                                        </a>
                                    </li>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                            <mat-row *matRowDef="let row; columns: displayedColumns"
                                (click)="onRowClicked(row)"></mat-row>

                        </mat-table>
                    </div>
                    <div class="card-footer">
                        <mat-paginator [length]="paginationMeta.totalItems" [pageIndex]=0
                            [pageSize]="paginationMeta.itemsPerPage" [pageSizeOptions]="[5, 10, 20, 50]"
                            (page)="pageEvent = getServerData($event)" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>