import { content } from './../../../routes/routes';
import { ImageCropComponent } from './../image-crop/image-crop.component';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions } from 'ngx-image-cropper';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})

export class ImageUploadComponent implements OnInit {

  @ViewChild("cropModal")

  selectedFile: ImageSnippet;
  defaultImage: boolean = true;
  image: any = null

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = true;
  transform: ImageTransform = {};



  @Output() images = new EventEmitter<any>();
  @Output() images1 = new EventEmitter<any>();
  @Output() images2 = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal
  ) {

  }
  ngOnInit(): void {

  }

  processFile(imageInput: any, event) {
    const file: File = imageInput.files[0];
    this.imageChangedEvent = event;
    const reader = new FileReader();

    reader.addEventListener('load', (e: any) => {
      this.defaultImage = false;
      this.selectedFile = new ImageSnippet(e.target.result, file);
      console.log(this.selectedFile);

      this.images.emit(this.selectedFile);
      this.images1.emit(this.selectedFile);
      this.images2.emit(this.selectedFile);
    });

   reader.readAsDataURL(file);
  }

  remove() {
    this.defaultImage = true;
    this.selectedFile = null;
  }

  crop(event, content) {
    /* const modalRes = this.modalService.open(ImageCropComponent, { size: 'lg' });
    modalRes.componentInstance.image = this.selectedFile.src;
    modalRes.componentInstance.responseEvent.subscribe((res: any) => {
      console.log(res);
    }); */
    this.showCropper = true;
    this.modalService.open(content, { size: 'lg' });
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }
  loadImageFailed() {
    console.log('Load Failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }
}