// import { getUserState, logout, setUserState, updateUserState } from "./user.actions";
import { User } from "./user.model";
import { createReducer, on } from '@ngrx/store';

export const initialState: User = {
    token: '',
    username: '',
    auth_key: '',
    created_at: 0,
    email: '',
    last_login: 0,
    mobile_no: '',
    profile: {
        address: '',
        bank_details: '',
        biography: '',
        blood_group: '',
        department_id: 0,
        designation_id: 0,
        dob: '',
        doh: '',
        first_name: '',
        gender: 0,
        job_title: '',
        last_name: '',
        mobile_no: '',
    },
    roles: [
        {
            id: 0,
            role_id: 0,
            user_id: 0,
            user_type: 0,
        }
    ],
    isLogged: false,
    isLoading: false
}

export const UserReducer = createReducer(
    initialState,
    // on(getUserState, (state) => ({
    //     ...state,
    // })),
    // on(setUserState, (state) => ({
    //     ...state,
    // })),
    // on(updateUserState, (state, payload) => ({
    //     ...state,
    //     user: payload,
    //     isLogged: true,
    // })),
    // on(logout, (state) => (
    //     initialState
    // ))
)