import { AttributeServices } from './services/attribute-services';
import { Routes, RouterModule } from '@angular/router';
import { ThemeModule } from './../../../theme/theme.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AttributeListComponent } from './attribute-list/attribute-list.component';
import { AttributeCreateComponent } from './attribute-create/attribute-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { AttributeEditComponent } from './attribute-edit/attribute-edit.component'

const routes: Routes = [
  {
    path: '',
    component: AttributeListComponent,
  },
  {
    path: 'create',
    component: AttributeCreateComponent,
  },
  {
    path: 'edit/:id',
    component: AttributeEditComponent
  }
];

@NgModule({
  declarations: [
    AttributeListComponent,
    AttributeCreateComponent,
    AttributeEditComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatFormFieldModule, 
    MatInputModule,
    MatDatepickerModule, 
    MatSortModule,
    MatNativeDateModule,
  ],
  providers: [AttributeServices]
})
export class AttributeModule { }
