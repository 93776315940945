
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginotpComponent } from './loginotp/loginotp.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';



const routes: Routes = [
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path: 'loginotp',
    component:LoginotpComponent,
  },
  {
    path: 'forgetpassword',
    component:ForgetPasswordComponent,
  },
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes)]
})
export class AuthRoutingModule { }
