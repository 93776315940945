export const pageList = [
    {
        title: 'Item 1', img: "../../../../../assets/images/logo/logo-icon.png",
        keywords: 'keyword1',
        parent: 'Parent A',
        status: 'Active'
    },
    { title: 'Item 2', img: "../../../../../assets/images/logo/logo-icon.png", keywords: 'keyword4', parent: 'Parent B', status: 'Inactive' },
    {
        title: 'Item 3', img: "../../../../../assets/images/logo/logo-icon.png",
        keywords: 'keyword1', parent: 'Parent A', status: 'Active'
    }, {
        title: 'Item 1', img: "../../../../../assets/images/logo/logo-icon.png",
        keywords: 'keyword1',
        parent: 'Parent A',
        status: 'Active'
    },
    { title: 'Item 2', img: "../../../../../assets/images/logo/logo-icon.png", keywords: 'keyword4', parent: 'Parent B', status: 'Inactive' },
    {
        title: 'Item 3', img: "../../../../../assets/images/logo/logo-icon.png",
        keywords: 'keyword1', parent: 'Parent A', status: 'Active'
    },
    // Add more items as needed
];