import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ThemeModule } from '../../theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { SystemSettingsService } from './system-settings/system-settings.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';

const routes: Routes = [
  {
    path: '',
    component: SystemSettingsComponent,
  },

  {
    path: 'edit/:id',
    component: SystemSettingsComponent,
  },

];

// const routes: Routes = [
//   {
//       path: '',
//       component: SystemSettingsComponent,
//   },

// ];

@NgModule({
  declarations: [SystemSettingsComponent],
  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatSortModule,
    MatNativeDateModule,

    RouterModule.forChild(routes)
  ],
  providers: [SystemSettingsService]
})
export class SettingsModule { }
