import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: "catalog",
        loadChildren: () => import('src/app/modules/catalog/catalog.module').then(m => m.CatalogModule),
    },
    {
        path: "customer-service",
        loadChildren: () => import('src/app/modules/customer-service/customer-service.module').then((m) => m.CustomerServiceModule)
    },
    {
        path: "payment",
        loadChildren: () => import('src/app/modules/payments/payments.module').then((m) => m.PaymentsModule)
    },
    {
        path: "seller-marketing",
        loadChildren: () => import('src/app/modules/seller-marketing/seller-marketing.module').then((m) => m.SellerMarketingModule)
    },
    {
        path: "marketplace-management",
        loadChildren: () => import('src/app/modules/marketplace-management/marketplace-management.module').then((m) => m.MarketplaceManagementModule)
    },
    {
        path: "user-management",
        loadChildren: () => import('src/app/modules/users/users.module').then((m) => m.UsersModule)
    },
    {
        path: "user-management",
        loadChildren: () => import('src/app/modules/user-management/employee/employee.module').then((m) => m.EmployeeModule)
    },
    {
        path:"masters",
        loadChildren: () =>import ('src/app/modules/masters/masters.module').then((m) =>m.MastersModule)
    },

    {
        path: "settings",
        loadChildren: () => import('src/app/modules/settings/settings.module').then((m) => m.SettingsModule)
    },
    {
        path: "menu-master",
        loadChildren: () => import('src/app/modules/menu-master/menu-master.module').then((m) => m.MenuMasterModule)
    },
    {
        path: "content",
        loadChildren: () => import('src/app/modules/content/content.module').then((m) => m.ContentModule)
    },
    {
        path: "marketing",
        loadChildren: () => import('src/app/modules/marketplace-marketing/marketplace-marketing.module').then((m) => m.MarketplaceMarketingModule)
    },
    {
        path: "help-center",
        loadChildren: () => import('src/app/modules/help-center/help-center.module').then((m) => m.HelpCenterModule)
    },
    {
        path: "report",
        loadChildren: () => import('src/app/modules/reports/reports.module').then((m) => m.ReportsModule)
    },
    {
        path: "analytics",
        loadChildren: () => import('src/app/modules/analytics/analytics.module').then((m) => m.AnalyticsModule)
    },
    {
        path: "file-management",
        loadChildren: () => import('src/app/modules/file-management/file-management.module').then((m) => m.FileManagementModule)
    },
    {
        path: "logs",
        loadChildren: () => import('src/app/modules/logs/logs.module').then((m) => m.LogsModule)
    },
    {
        path: "maintaince-mode",
        loadChildren: () => import('src/app/modules/maintaince-mode/maintaince-mode.module').then((m) => m.MaintainceModeModule)
    },

    {
        path: "attribute",
        loadChildren: () => import('src/app/modules/catalog/attribute/attribute.module').then(m => m.AttributeModule),
    },
    {
        path: "cms",
        loadChildren: () => import('src/app/modules/cms/cms.module').then(m => m.CmsModule),
    },
    {
        path: "marketplace-marketing",
        loadChildren: () => import('src/app/modules/marketplace-marketing/marketplace-marketing.module').then(m => m.MarketplaceMarketingModule),
    },
    {
        path: "help-desk",
        loadChildren: () => import('src/app/modules/help-desk/help-desk.module').then(m => m.HelpDeskModule),
    },
    // {
    //     path: "ticket",
    //     loadChildren: () => import('src/app/modules/ticket/ticket.module').then(m => m.TicketModule),
    // }, 
    {
        path: "ticket",
        loadChildren: () => import('src/app/modules/tickets/tickets.module').then(m => m.TicketsModule),
    },
];
