import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { NgModule, isDevMode } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GlobalEffects, GlobalStore } from './store/global-store.state';

import { ThemeModule } from "./theme/theme.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AdminGuard } from './theme/guard/admin.guard';
import { AppComponent } from './app.component';

import { UserModule } from './modules/users/user/user.module';
import { MastersModule } from './modules/masters/masters.module';
import { CatalogModule } from './modules/catalog/catalog.module';
import { SettingsModule } from './modules/settings/settings.module';
import { AuthModule } from './auth/auth.module';
import { MenuMasterModule } from '././modules/menu-master/menu-master.module'
import { CoreModule } from './core/core.module';
import { CmsModule } from './modules/cms/cms.module'
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AppInitService } from './core/services/appinit.services';
import { TicketsModule } from './modules/tickets/tickets.module';
import { environment } from '../environments/environment';
import { AppInterceptor } from 'src/app/core/interceptor/app.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SettingsModule,
    ReactiveFormsModule,
    OverlayModule,
    ThemeModule,
    MenuMasterModule,
    HttpClientModule, TicketsModule,
    NgbModule,
    RouterModule,
    SharedModule,
    CoreModule,
    AuthModule,
    StoreModule.forRoot(GlobalStore),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot(GlobalEffects),
    CatalogModule,
    CmsModule,
    UserModule,
    MastersModule,
    //Ng2SearchPipeModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    StoreModule.forRoot(GlobalStore),
    EffectsModule.forRoot(GlobalEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    AdminGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: () => addInitFactory, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [ReactiveFormsModule]
})
export class AppModule { }

export function addInitFactory(appInitService: AppInitService) {
  return () => {
    return appInitService.init()
  }
}
