export const categoryArray = [
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    }
    ,
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    }, {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    }
    ,
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        categoryDetails: 'Category 1',
        folder: '2',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: 'Bhadri',
        status: 'Live'
    }
]