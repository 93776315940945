<div class="container-fluid">

    <div class="row mb-0">
        <form [formGroup]="myForm">


            <div class="card mt-3">
                <div class="card-body">
                    <h5>Create New Category</h5>
                    <hr>
                    <div class="row mt-4">
                        <div class="col-lg-10 mx-auto">



                            <!-- <div class="mb-2 col-12 col-md-6 pe-0">
                                <label>Folder Type</label> <br>
                                <input class="form-check-input" type="radio" name="folderType" id="business"
                                    formControlName="folderType" value="Business">
                                <label class="ms-1" for="business">Parent Folder</label>
                                <input class="form-check-input ms-2" type="radio" name="folderType" id="individual"
                                    formControlName="folderType" value="Individual">
                                <label class="ms-1" for="individual">Sub Folder</label>
                                <div class="errors mb-0" *ngIf="submitted && myForm.get('folderType').errors">
                                    <p class="text-danger mb-0" *ngIf="myForm.get('folderType').errors.required">
                                        Folder type is required
                                    </p>
                                </div>
                            </div> -->
                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Name <sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="name" type="text" placeholder="Enter Name"
                                        formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('name').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('name').errors.required">
                                            Name is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-group">

                                <div class="mb-2 col-2 text-end">
                                    <label>Description <sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10">
                                    <textarea class="form-control" name="" id="" cols="30" rows="7"
                                        formControlName="description"></textarea>

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('description').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('description').errors.required">
                                            Description is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2">
                                <div class="input-group row ">
                                    <div class="mb-2 col-2 text-end">
                                        <label class="col-form-label "> <span class="mb-1">Folders<sup
                                                    class="text-danger"> * </sup></span>

                                        </label>
                                    </div>
                                    <div class="col-10">
                                        <ng-select [items]="filters" [clearable]="false" (click)="setValue()"
                                            class="custom" bindValue="name" bindLabel="name" formControlName="folder"
                                            [multiple]="true" [closeOnSelect]="false"></ng-select>

                                        <div class="errors mb-0" *ngIf="submitted && myForm.get('folder').errors">
                                            <p class="text-danger mb-0" *ngIf="myForm.get('folder').errors.required">
                                                Folder is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group mb-2">
                                <div class="input-group row">
                                    <div class="mb-2 col-2 text-end">
                                        <label>Sorting<sup class="text-danger">*</sup></label>
                                    </div>
                                    <div class="mb-2 col-10">
                                        <select class="form-select form-select-md text-dark"
                                            aria-label=".form-select-md example" formControlName="sorting">
                                            <option disabled selected value="" class="text-muted">
                                                Select Sorting
                                            </option>
                                            <option value="1">Ascending Order</option>
                                            <option value="2">Descending Order</option>
                                            <option value="3">Based on Popularity</option>
                                        </select>

                                        <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                Sorting is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>User Group <sup class="text-danger">*</sup></label> <br>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-check-input" type="checkbox" id="business"
                                        formControlName="userGroup" value="Business">
                                    <label class="ms-1" for="business">Admin</label>
                                    <input class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Quality</label><input
                                        class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Finance</label><input
                                        class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Quality</label>
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('userGroup').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('userGroup').errors.required">
                                            User Group is required
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Status<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10">
                                    <select class="form-select form-select-md text-dark"
                                        aria-label=".form-select-md example" formControlName="status">
                                        <option disabled selected value="" class="text-muted">
                                            Select Status
                                        </option>
                                        <option value="1">Draft</option>
                                        <option value="2">Live</option>

                                    </select>
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('status').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('status').errors.required">
                                            Status is required
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group mb-2">
                                <div class="input-group row ">
                                    <div class="mb-2 col-2 text-end">
                                        <label class="col-form-label "> <span class="mb-1">Related Article <sup
                                                    class="text-danger"> * </sup></span>

                                        </label>
                                    </div>
                                    <div class="col-10">
                                        <ng-select [items]="filters" [clearable]="false" (click)="setValue()"
                                            class="custom" bindValue="name" bindLabel="name" formControlName="folder"
                                            [multiple]="true" [closeOnSelect]="false"></ng-select>

                                        <div class="errors mb-0" *ngIf="submitted && myForm.get('folder').errors">
                                            <p class="text-danger mb-0" *ngIf="myForm.get('folder').errors.required">
                                                Folder is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 text-center">

                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-light font-primary">
                                            Cancel
                                        </button></span>
                                    <!-- </div>
                                <div class="col-1 text-end"> -->
                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-primary"
                                            (click)="onSubmit()" type="submit">
                                            Save
                                        </button></span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>