import { StorageService } from './services/storage.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from './pipes/pips.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropComponent } from './components/image-crop/image-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
  
    ImageCropComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
  ],
  providers: [
    StorageService,
  ],
})
export class SharedModule { }
