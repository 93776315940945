import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CategoryServices } from '../services/category-service';
import { CategoryDB } from 'src/app/theme/data/category/category';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
})
export class CategoryCreateComponent implements OnInit {


  languages: Array<any> = environment.languages;
  categoryForm: FormGroup;
  categoryGroupDiv = false;
  categoryData: any = [];
  parent_id: number = 0;
  level: number = 1;
  categoryLevelData: any = [];
  parentsData: any = [];
  parents: any = [];
  searchResults:any = [];
  categoryLevels: Array<any> = [];
  firstLevelId:number;
  secondLevelId:number;
  thirdLevelId:number;
  firstLevel: Array<any> = [];
  secondLevel: Array<any> = [];
  thirdLevel: Array<any> = [];
  banners_list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  layoutsList = [
    { id: 1, name: 'Layout 1' }, { id: 2, name: 'Layout 2' }, { id: 3, name: 'Layout 3' }
  ];

  attrubitesList = [
    { id: 1, name: 'Electronics' }, { id: 2, name: 'Books' }, { id: 3, name: 'Fashion' }
  ];

  filters = [
    { id: 1, name: 'Option 1' }, { id: 2, name: 'Option 2' }, { id: 3, name: 'Option 3' }
  ];

  commission = [
    { id: 1, name: '3 %' }, { id: 2, name: '5 %' }, { id: 3, name: '10 %' }
  ];

  translationIndex: any;
  public activePillIndex: number = 0;

  step1Validate = new BehaviorSubject(false);
  step2Validate = new BehaviorSubject(false);
  step3Validate = new BehaviorSubject(false);
  step4Validate = new BehaviorSubject(false);
  step5Validate = new BehaviorSubject(false);
  step6Validate = new BehaviorSubject(false);


  public validate: any;
  submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private categoryService: CategoryServices,
  ) {
  }


  ngOnInit(): void {
    this.categoryForm = this.fb.group({
      first: this.fb.group({
        top_level_type: [null, [Validators.required]],
      }),
      translations: this.fb.array([]),
      third: this.fb.group({
        enable_category: [true, Validators.required],
        visible_front: [true, Validators.required],
        visible_seller: [true, [Validators.required]],
        restricted_seller: [true, Validators.required],
        seller_access: [null, [Validators.required]],
        position: [null, [Validators.required, Validators.pattern(/^(?=.*[0-9])/)]],
        layout_id: [null, [Validators.required]],
      }),
      fourth: this.fb.group({
        attribute_set_id: [null, [Validators.required]],
        filter_list: [null, [Validators.required]],
      }),
      fifth: this.fb.group({
        commission: [null, [Validators.required, Validators.pattern(/^(?=.*[0-9])/)]],
      }),
      sixth: this.fb.group({
        thumb: this.fb.array([]),
        banners: this.fb.array([]),
        products: this.fb.array([]),
      })
    });
    this.addTranslation();
  }

  get form() {
    return this.categoryForm.controls;
  }

  get first() {
    return this.form.first;
  }

  get third() {
    return this.form.third;
  }


  get fourth() {
    return this.form.fourth;
  }

  get fifth() {
    return this.form.fifth;
  }

  get sixth() {
    return this.form.sixth;
  }

  public selectPill(index: number) {
    this.activePillIndex = index;
    console.log(this.activePillIndex + 1);
  }


  /* Translation Section */
  translationFormGroup(): FormGroup {
    return this.fb.group({
      language_id: [null],
      name: [null, [Validators.required]],
      meta_title: [null, [Validators.required]],
      description: null,
      meta_keywords: null,
      meta_description: null,
    });
  }

  bannerFileGroup() {
    return this.fb.group({
      file: [null]
    })
  }

  get translations(): FormArray {
    return this.categoryForm.get('translations') as FormArray
  }

  get thumb(): FormArray {
    return this.sixth.get('thumb') as FormArray
  }

  get banners(): FormArray {
    return this.sixth.get('banners') as FormArray
  }

  get products(): FormArray {
    return this.sixth.get('products') as FormArray
  }

  addTranslation() {
    this.languages.forEach(lang => {
      this.translations.push(this.translationFormGroup());
    });
  }

  bannerFromGroup() {
    this.banners_list.forEach(banner => {
      this.banners.push(this.bannerFileGroup());
    })
  }

  saveTransalation(index: number) {
  }

  async search(event){
    let search: string = event.target.value;
    console.log(search.length);
    if(search.trim().length >= 3) {
      const response = await this.categoryService.getSearch(search).subscribe((res: any) => {
        console.log(res);
        this.searchResults = res.data;
      });
  } else {
    this.searchResults = [];
  }
}

searchSelect(search: any) {
  console.log(search);
  this.searchResults = [];
  this.parents = JSON.parse(search.parents);
  let category_id : number = search.category_id;  
    this.categoryService.findOne(category_id).subscribe((res: any) => {
      this.categoryLevelData = res.data.category;
      this.parentsData = res.data.parents;
      this.parents = JSON.parse(this.categoryLevelData.parents);

      this.setLevelsData();
      this.setSelectedCategories();
    });
    
  }

  setLevelsData() {
    if (this.parentsData.length > 0) {
      this.parentsData.forEach((parent: any) => {
        if (parent.level == 1) {
          this.firstLevel = parent.categories;
        } else if (parent.level == 2) {
          this.secondLevel = parent.categories;
        } else if (parent.level == 3) {
          this.thirdLevel = parent.categories;
        }
      });
    }
  }

  setSelectedCategories() {
    if (this.parents != null) {
      this.parents.forEach((parent: any) => {
        if (parent.level == 1) {
          this.firstLevelId = parent.category_id;
          this.updateCategoryLevel(parent, 1);
        } else if (parent.level == 2) {
          this.secondLevelId = parent.category_id;
          this.updateCategoryLevel(parent, 2);
        } else if (parent.level == 3) {
          this.updateCategoryLevel(parent, 3);
          this.thirdLevelId = parent.category_id;
        }
      });
    }
    if (this.categoryLevelData.level == 1) {
      this.firstLevelId = this.categoryData.id;
    } else if (this.categoryLevelData.level == 2) {
      this.secondLevelId = this.categoryData.id;
    } else if (this.categoryLevelData.level == 3) {
      this.thirdLevelId = this.categoryData.id;
    }
    this.updateCategoryLevel({
      category_id: this.categoryData.id,
      parent_id: this.categoryData.parent_id,
      code: this.categoryData.code,
      level: this.categoryData.level
    }, this.categoryData.level)

  }
 

  getFirstLevelData() {
    this.categoryService.findGetChild(this.parent_id).subscribe((res: any) => {
      this.categoryData = res.data.categories;
      this.firstLevel = [];
      this.categoryData.forEach((category: any) => {
        this.firstLevel.push(category);
      })
    })
  }

  getSecondLevelData(item: any) {
    this.categoryLevels = [];
    this.level = 2;
    this.parent_id = item.category_id;
    this.firstLevelId = item.category_id;
    this.categoryService.findGetChild(this.parent_id).subscribe((res: any) => {
      this.categoryData = res.data.categories;
      this.secondLevel = [];
      this.thirdLevel = [];
      this.categoryData.forEach((category: any) => {
        this.secondLevel.push(category);
      })
    });
    this.updateCategoryLevel(item, 1);
  }

  getThirdLevelData(item: any) {
    this.parent_id = item.category_id;
    this.level = 3;
    this.secondLevelId = item.category_id;
    this.categoryService.findGetChild(this.parent_id).subscribe((res: any) => {
      this.categoryData = res.data.categories;
      this.thirdLevel = [];
      this.categoryData.forEach((category: any) => {
        this.thirdLevel.push(category);
      })
    })
    this.updateCategoryLevel(item, 2);
  }

  updateCategoryLevel(item: any, level: number) {
    if (level == 1) {
      this.categoryLevels = [];
      this.categoryLevels.push(item);
    } else {
     this.categoryLevels.map((element: any, index) => {
        if (element.level == item.level) {
          this.categoryLevels.splice(index);
        }
      });
      this.categoryLevels.push(item)
    }
  }

  setParent(item) {
    this.parent_id = item.category_id;
    this.level = 3;
    this.thirdLevelId = item.category_id;
    this.updateCategoryLevel(item, 3);
  }

  getSelectedPath(): string {
    let path = [];
    this.categoryLevels.forEach((element: any) => {
      path.push(element.name);
    });
    return (path.length == 0) ? '' : path.join(' > ');
  }

  rootCategorySelectOption(event) {
    this.categoryLevels = [];
    this.categoryGroupDiv = (event.target.value == 0) ? false : true;
    if (event.target.value == 1) {
      this.getFirstLevelData();
    }
  }

  selectedFile = [];
  selectedFile1 = [];
  selectedFile2 = [];

  onSelect(files: File[] = []) {
    this.selectedFile = files;
  }

  onSelect1(files1: File[] = []) {
    this.selectedFile1.push(files1);
    this.selectedFile1.push(files1);
  }

  onSelect2(files2: File[] = []) {
    this.selectedFile2 = files2;
  }

  moveDirection = (validityStatus, direction) => {
    return validityStatus;
  };

  step1() {
    this.step1Validate.next(true);
    // console.log(this.first.status);
    // console.log(this.parent_id);
    // this.categoryLevels.push(this.thirdLevelData);
    // console.log(this.categoryLevels);
  }

  step2() {
    this.step2Validate.next(true);
    console.log(this.translations);
  }

  step3() {
    this.step3Validate.next(true);
  }

  step4() {
    this.step4Validate.next(true);
  }

  step5() {
    this.step5Validate.next(true);
  }

  async step6() {
    const img = this.fb.group({
      files: [this.selectedFile],
    });

    if (this.selectedFile.length != 0) {
      this.thumb.push(img);
    }
    // console.log(this.thumb.status);

    const img1 = this.fb.group({
      files: [this.selectedFile1],
    });
    if (this.selectedFile1.length != 0) {
      this.banners.push(img1);
    }

    // console.log(this.selectedFile1);

    const img2 = this.fb.group({
      files2: [this.selectedFile2],
    });

    if (this.selectedFile2.length != 0) {
      this.products.push(img2);
    }

    this.step3Validate.next(true);
    this.submitted = true;
    this.validate = !this.validate;

    const postData = {
      parent_id: this.parent_id,
      position: this.third.value.position,
      level: this.level,
      //  thumb: this.sixth.value.thumb,
      //  icon: this.sixth.value.thumb,
      visible_front: this.third.value.visible_front,
      visible_seller: this.third.value.visible_seller,
      layout_id: this.third.value.layout_id,
      attribute_set_id: this.fourth.value.attribute_set_id,
      value: this.fifth.value.commission,
      translations: this.translations.value,
      categoryLevels: this.categoryLevels
    }

    // console.log(this.categoryForm);
    // console.log(postData);

    if (this.categoryForm.status == 'VALID') {
      const response = await this.categoryService.create(postData).subscribe((res: any) => {
        console.log(res);
        if (res.status == true) {
          // this.toast.success('User Profile has been added successfully');
          // return this.router.navigate(['/user-management/user']) && res.data;
        } else {
          // this.toast.error(res.errors);
          // this.errors = res;
          return res;
        }
        // }, (error) => {
        //   return error;
      }
      );
      // console.log(response);
    }
  }

  enterStep(event) {
    console.log(event);
  }

  
  // if (this.parents == null) {
  //   this.parent_id = search.parent_id;
  //   this.firstLevelId = search.category_id;
    
  // } else {
  //   this.parents.forEach((parent: any) => {
  //     if(parent.level == 1) {
  //       this.parent_id = parent.parent_id;
  //       this.firstLevelId = parent.category_id;
        
  //     } else if (parent.level == 2) {
  //       this.parent_id = parent.parent_id;
  //       this.secondLevelId = parent.category_id;
  //       this.categoryService.findGetChild(this.parent_id).subscribe((res: any) => {
  //         this.secondLevel=[];
  //             this.categoryLevelData = res.data.categories;
  //             this.categoryLevelData.forEach((category: any) => {
  //               console.log(category);
  //               this.secondLevel.push(category);
  //             });
  //           });
  //     }
  //   });
  // }

}