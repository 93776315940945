import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../state.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectComponent } from '@ng-select/ng-select';
import { State } from './../models/State';
import { NgbdSortableHeader } from 'src/app/theme/directives/NgbdSortableHeader';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent {

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  displayedColumns = ["slNo", "name", "country_id", "action"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;
  isRateLimitReached = false;
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  sortBy = [{ value: "ASC", name: "Ascending Order" }, { value: "DESC", name: "Descending Order" }];

  countryList = [{ country_id: 1, name: "India" }]

  public searchText;
  total$: Observable<number>;
  public selected = [];
  state: any = [];

  stateCreateForm: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  validate: any;
  errors: any = [];
  stateId: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private toast: ToastrService,) {
      const routeParams = this.route.snapshot.paramMap;
    this.stateId = Number(routeParams.get("id"));
  }
  ngOnInit() {

    this.stateCreateForm = this.fb.group({
      name: ['', Validators.required],
      country_id: ['', Validators.required],
    });

    this.stateService.findOne(this.stateId).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        const stateedit: State = res.data;
        this.stateCreateForm.get('name').setValue(stateedit.name);
        this.stateCreateForm.get('country_id').setValue(stateedit.country_id);
      }
    });

    this.searchForm = this.fb.group({
      search: [null],
      sort: [null]
    });

  }

  async ngAfterViewInit() {
    await this.getStateList();
  }

  async getStateList() {
    await this.stateService.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.state = res.data.data;
        this.paginationMeta = res.data.meta;
        this.isLoadingResults = false;
        console.log(this.paginationMeta);
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getStateList();
  }

  getCountryName(id: number) {
    let country_name: string = ''
    this.countryList.forEach((item) => {
      if (item.country_id == id) {
        country_name = item.name;
      }
    })
    return country_name;
  }

  onRowClicked(row) {
    console.log('Row clicked: ', row);
  }


  async onSelect(id: number) {

    const postData = {
      id: id,
      // status: 0,
    };
    const response = await this.stateService.delete(postData).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.state = this.state.filter(route => route.id !== id);
        console.log(this.state);
        this.getStateList();
        //return this.router.navigate(['/masters/state']) && res.data;
      } else {
        this.toast.error(res.errors);
        console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }

  async onSubmit() {
    this.submitted = true;
    this.validate = !this.validate;
    console.log(this.stateCreateForm.value);
    if(this.stateId) {
      const postData = {
        id: this.stateId,
        name: this.stateCreateForm.value.name,
        country_id: this.stateCreateForm.value.country_id,
      }
      console.log(postData);
  
      if (this.stateCreateForm.status == 'VALID') {
        const response = await this.stateService.update(postData).subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.stateCreateForm.reset();
            this.toast.success('State has been updated successfully');
            this.getStateList();
            return this.router.navigate(['/masters/state']);
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        });
        console.log(response);
      }
    }else {
      const postData: State = this.stateCreateForm.value;

    if (this.stateCreateForm.status == 'VALID') {

      const response = await this.stateService.create(postData)
        .subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.stateCreateForm.reset();
            this.toast.success('State has been added successfully');
            this.getStateList();
            //return this.router.navigate(['/masters/state']);
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        }
        );
      console.log(response);
    }
    }

  }

  get f() {
    return this.stateCreateForm.controls;
  }

  onSearch() {
    console.log(this.searchForm.value);
    if (this.searchForm.value.search != null) {
      this.pagination.search = this.searchForm.value.search;
    }

    this.getStateList();

  }

  onClear(){
    this.searchForm.reset();
    this.pagination.search = '';
    this.getStateList();

  }
}
