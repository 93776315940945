<div class="container-fluid">
    <div class="row">
        <h4>System Settings</h4>
        <div class="col-lg-4">
            <div class="card mt-3">
                <div class="card-body">
                     <h4>{{ settingId ? 'Edit Settings' : 'Add Settings'}}</h4>
                    <form [formGroup]="settingCreateForm" >

                        <div class="form-group mb-2">
                            <label>Setting Key</label>
                            <input class="form-control" id="key" type="text" placeholder="Enter Settings Key"
                                formControlName="key" />
                            <div class="errors mb-0" *ngIf="submitted && settingCreateForm.get('key').errors">
                                <p class="text-danger mb-0" *ngIf="settingCreateForm.get('key').errors.required">
                                    Setting Key is required
                                </p>
                                <!-- <p class="text-danger mb-0" *ngIf="settingCreateFormcontrols.key.errors.pattern">
                                    Setting key not valid.
                                </p>  -->
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label>Setting Value</label>
                            <input class="form-control" id="value" type="text" placeholder="Enter Settings Value"
                                formControlName="value" />
                            <div class="errors mb-0" *ngIf="submitted && settingCreateForm.get('value').errors">
                                <p class="text-danger mb-0" *ngIf="settingCreateForm.get('value').errors.required">
                                    Setting Value is required
                                </p>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label>Setting Group</label>
                            <input class="form-control" id="group" type="text" placeholder="Enter group"
                            formControlName="group"  />
                            <div class="errors mb-0" *ngIf="submitted && settingCreateForm.get('group').errors">
                                <p class="text-danger mb-0" *ngIf="settingCreateForm.get('group').errors.required">
                                    Group is required
                                </p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 text-end">
                                <button class="btn btn-md btn-primary" type="submit" (click)="onSubmit()">Save</button>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>

        <div class="col-lg-8 ">
            <div class="card mt-3">

                <div class="card-header pt-1">
                    <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label">Search</label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="search" placeholder="igeet">
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label class="col-form-label">Sort By</label>
                                    <div class="input-group">
                                        <ng-select [items]="sortBy" bindValue="value" [clearable]="false"
                                            bindLabel="name" class="custom col-12" formControlName="sortBy"
                                            placeholder="Select sort order" formControlName="sort"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center mt-3">
                                    <a class="btn btn-secondary mx-2" (click)="onClear()">Clear</a>
                                    <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div *ngIf="settings.length > 0">
                    <div class="card-body">
                        <h4>System Settings</h4>
                        <mat-table class="" [dataSource]="settings" matSort matSortActive="id" matSortDirection="asc"
                            matSortDisableClear>

                            <ng-container matColumnDef="slNo">
                                <mat-header-cell *matHeaderCellDef>Sl.No</mat-header-cell>
                                <mat-cell class="slNo-cell" *matCellDef="let settings; let i = index">{{i+1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="key">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Key</mat-header-cell>
                                <mat-cell class="key-cell" *matCellDef="let settings">{{settings.key}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Value</mat-header-cell>
                                <mat-cell class="value" *matCellDef="let settings">{{settings.value}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="group">
                                <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
                                <mat-cell class="group-cell"
                                    *matCellDef="let settings"> {{settings.group}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                <mat-cell class="action" *matCellDef="let settings">
                                    <li class="edit me-3">
                                        <a [routerLink]="['/settings/edit', settings.setting_id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li class="delete" (click)="onDelete(settings.setting_id)">
                                        <a>
                                            <i class="icon-trash"></i>
                                        </a>
                                    </li>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                            <mat-row *matRowDef="let row; columns: displayedColumns"
                                (click)="onRowClicked(row)"></mat-row>

                        </mat-table>
                    </div>
                    <div class="card-footer">
                        <mat-paginator [length]="paginationMeta.totalItems" [pageIndex]=0
                            [pageSize]="paginationMeta.itemsPerPage" [pageSizeOptions]="[5, 10, 20, 50]"
                            (page)="pageEvent = getServerData($event)" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-lg-4">
            <h3 class="mt-3"> System Settings</h3>
            <div class="card">
                <div class="card-body  ">

                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <h4>Add Settings</h4>
                        <div class="form-group mb-2">
                            <label>Key</label>
                            <input class="form-control" id="key" type="text" placeholder="Enter Key"
                                formControlName="key" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && form.get('key').errors">
                                <p class="text-danger mb-0" *ngIf="form.get('key').errors.required">
                                    Key is required
                                </p>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <label>Value</label>
                            <input class="form-control" id="key" type="text" placeholder="Enter Value"
                                formControlName="value" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && form.get('value').errors">
                                <p class="text-danger mb-0" *ngIf="form.get('value').errors.required">
                                    Value is required
                                </p>
                            </div>
                        </div>

                        <div class="mb-2 col-12">
                            <label>Group</label>
                            <select class="form-select form-select-md text-dark" aria-label=".form-select-md example"
                                formControlName="group">
                                <option disabled selected value="" class="text-muted">
                                    Select group
                                </option>
                                <option value="1">Config</option>
                                <option value="2">Site Settings</option>

                            </select>
                            <div class="errors mb-0" *ngIf="submitted && form.get('group').errors">
                                <p class="text-danger mb-0" *ngIf="form.get('group').errors.required">
                                    Group is required
                                </p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 text-end">
                                <button class="btn btn-md btn-primary" type="submit" (click)="onSubmit()">Save</button>

                            </div>
                        </div>
                    </form>
                   
                </div>
            </div>
        </div>
        <div class="col-lg-8 settingslist">

            <div class="card">
                <div class="card-body ">
                    <h4>Settings List</h4>

                    <div class="table-responsive">
                        <table class="table  ">
                            <thead>
                                <tr class="align-items-center text-dark py-2 border-bottom mx-0">
                                    <th scope="col">#</th>
                                    <th scope="col" class="">Key</th>
                                    <th scope="col" class="">Value</th>
                                    <th scope="col" class="">Group</th>
                                    <th scope="col text-end" class="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>

                                    <td class="text-start" scope="col">
                                        <input type="text" />
                                    </td>
                                    <td class="" scope="col"><input type="text" /></td>
                                    <td class="" scope="col"><input type="text" /></td>
                                    <td></td>

                                </tr>
                                <tr *ngFor="let settings of paginatedList; let i = index">



                                    <td>{{settings.sNo }}</td>
                                    <td>{{settings.key }}</td>
                                    <td>{{settings.value }}</td>
                                    <td>{{settings.group }}</td>
                                    <td>
                                        <div class="d-flex justify-content-end action-icon">
                                            <a href="#" class="ms-1 pencil-icon"><i class="icon-pencil-alt"></i></a>
                                            <a href="#" class="ms-1 trash-icon"><i class="icon-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                
                </div>

                <nav *ngIf="totalPages > 1" class="me-4 mb-2">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" [class.disabled]="currentPage === 1">
                            <a class="page-link" href="javascript:void(0)" (click)="previousPage()">Previous</a>
                        </li>
                        <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages">
                            <a class="page-link" href="javascript:void(0)" (click)="goToPage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage === totalPages">
                            <a class="page-link" href="javascript:void(0)" (click)="nextPage()">Next</a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </div>
</div> -->