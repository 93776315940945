
export class Brands {
    public static types =[
        'Corporate', 'Personal', 'Common'
    ];
    public static BrandDatas = [
        {
            BrandId : 1,
            brandNo : 1001,
            brandName : "Samsung",
            brandType : 1,
            brandLogo : "../../../../../assets/images/logo/logo-icon.png",
            brandWebsite : "http://www.samsung.com",
            categroies : [
                {
                    parent: "Electronics",
                    childs : ['Mobile', 'Laptop', 'Washing Machine', 'Home Appliance'],
                },
                {
                    parent: "Fashion",
                    childs: ['Kids', 'Women', 'Men'],
                }
            ],
            status : 1
        },
        {
            BrandId: 2,
            brandNo: 1002,
            brandName: "Apple",
            brandType: 1,
            brandLogo: "https://img.alicdn.com/imgextra/i2/224120411/O1CN01456_224120411_120x120.jpg",
            brandWebsite: "http://www.apple.com",
            categroies: [
                {
                    parent: "Electronics",
                    childs: ['Mobile', 'Laptop', 'Washing Machine', 'Home Appliance'],
                },
                {
                    parent: "Fashion",
                    childs: ['Kids', 'Women', 'Men'],
                }
            ],
            status: 1
        },
        {
            BrandId: 3,
            brandNo: 1003,
            brandName: "One Plus",
            brandType: 1,
            brandLogo: "https://img.alicdn.com/imgextra/i2/224120411/O1CN01456_224120411_120x120.jpg",
            brandWebsite: "http://www.oneplus.com",
            categroies: [
                {
                    parent: "Electronics",
                    childs: ['Mobile', 'Laptop', 'Washing Machine', 'Home Appliance'],
                },
                {
                    parent: "Fashion",
                    childs: ['Kids', 'Women', 'Men'],
                }
            ],
            status: 0
        }
    ];
}