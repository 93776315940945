export class Data {
public static attributeTypes = [
    // {
    //     id: 0,
    //     type: 'Select'
    // },
    {
        id: 1,
        type: 'Text Field'
    },
    {
        id: 2,
        type: 'Text Area'
    },
    {
        id: 3,
        type: 'Drop Down'
    },
    {
        id: 4,
        type: 'Date'
    },
    {
        id: 5,
        type: 'Multi Select'
    },
];

public static inputTypes = [
    // {
    //     id: 0,
    //     type: 'Select'
    // },
    {
        id: 1,
        type: 'Alphabet Only'
    },
    {
        id: 2,
        type: 'Integers'
    },
    {
        id: 3,
        type: 'Alpha Numeric'
    },
    {
        id: 4,
        type: 'Url'
    },
    {
        id: 5,
        type: 'Text'
    },
];
}