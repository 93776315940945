import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeModule } from '../../../../src/app/theme/theme.module'
const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./create-modules/create-folder.module').then(m => m.CreateModule)
  },



]

@NgModule({
  imports: [RouterModule.forChild(routes), ThemeModule],
  exports: [RouterModule]
})
export class CmsRoutingModule { }

