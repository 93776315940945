<div class="container-fluid brand-list pt-3">
    <h4>Brand List</h4>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>Search Fiter</h5>
                    <div class="category-filter mt-3">
                        <form (ngSubmit)="onSearch()" [formGroup]="brandlistForm">
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Brand Name</label>
                                        <div class="input-group">
                                            <input class="form-control" required="" placeholder=""
                                                formControlName="brandName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Brand Number</label>
                                        <div class="input-group">
                                            <input class="form-control" required="" placeholder=""
                                                formControlName="brandName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Catagory</label>
                                        <div class="input-group">
                                            <input class="form-control" required="" placeholder=""
                                                formControlName="catagory">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Brand Website Link</label>
                                        <div class="input-group">
                                            <input class="form-control" required="" placeholder=""
                                                formControlName=" brandWebsiteLink">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 text-center mt-3">
                                    <a class="btn btn-secondary mx-2">Reset</a>
                                    <a class="btn btn-primary mx-2">Search</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                 
                        <div class="col-12 text-end">
                            <a class="btn btn-primary mx-2" routerLink='/catalog/brand/create' routerLinkActive="brand">
                                Add Brand</a>
                        </div>
                    </div>
                </div>

                <div class="card-body mb-2">

                    <div class="border rounded-1 row bg-light text-dark mx-2 py-1">
                        <div class="col-6 text-center"><b>Brand Details | Status</b></div>
                        <div class="col-4"><b>Brand Category</b></div>
                        <div class="col-2 text-md-start"><b>Action</b></div>
                    </div>

                    <div class="justify-content-center brand-list-details  rounded-3 border m-2"
                    *ngFor="let brand of brands; let i=index">
                        <div class="row align-items-center bg-light text-dark rounded-top  border-bottom mx-0">
                            <div class="col-6 py-2">
                                <span class="status-label">Status:</span>
                                <span class="badge task-priority"
                                    [ngClass]="brand.status === 1 ? 'badge-light-success' : 'badge-light-danger'">
                                    {{ brand.status === 1 ? 'Active' : 'Inactive' }}
                                </span>
                            </div>
                            <div class="col-6 py-2 text-end">
                                Brand No: {{brand.brandNo}}
                            </div>
                        </div>

                        <div class="row brand-details m-2">

                            <div class="col-md-2  col-12  my-2  align-items-center   justify-content-center ">
                                <img src="{{brand.brandLogo}}" alt="logo" style="height: 70px; width: 70px;"
                                    class=" border rounded-1 bg-light p-2 text-dark">
                            </div>
                            <div class="col-md-4">
                                <b>{{ brand.brandName}}</b><br>
                                Website: {{ brand.brandWebsite}}<br>
                                Brand Type: {{types[brand.brandType-1]}}<br>
                            </div>
                            <div class="col-12 col-md-4">
                                <ul>
                                    <li *ngFor="let category of categories | slice:0:4">{{ category.category }}</li>
                                </ul>
                                <ul *ngIf="showAllCategories[i]">
                                    <li *ngFor="let category of categories.slice(4)">{{ category.category }}</li>
                                </ul>
                                <a (click)="toggleShowAllCategories(i)" class="view-category">
                                    {{ showAllCategories[i] ? 'Show Less Categories' : 'View all Categories' }}
                                </a>
                            </div>


                            <div class="col-md-2 text-md-start">
                                <p><a href="#"><i class="icon-receipt"></i> <span class="ms-1">Detail</span></a></p>
                                <p><a href="#"><i class="icon-pencil-alt"></i> <span class="ms-1">Edit</span></a></p>
                                <p><a href="#"><i class="icon-trash"></i> <span class="ms-1">Delete</span></a></p>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="card-footer">
                    <nav class="m-b-30" aria-label="Page navigation example">
                        <ul class=" pagination justify-content-end pagination pagination-sm pagination-primary">
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">Previous</a>
                            </li>
                            <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    </div>
</div>