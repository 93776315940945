import { Injectable } from '@angular/core';
import  jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})

export class JwtTokenService {

  jwtToken: string | null;
  decodedToken: { [key: string]: string } = {};

  constructor() {
    this.jwtToken = localStorage.getItem('token');
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
      //console.log(this.decodedToken);
    }
  }

  getDecodeToken() {
    return jwt_decode(this.jwtToken!);
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['exp'] : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: any = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }
}
