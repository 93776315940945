<div class="model p-3 ">

    <div class="modal-header ">
        <h4 id="modal-basic-title" class="modal-title">Select Category</h4>
        <button type="button" aria-label="Close" (click)="dismiss()" class="btn-close"></button>
    </div>
    <div class="modal-body bg-light ">
        <div class="container-main bg-light">
            <div class="row">
                <div class="col-11">
                    <div class="input-group  ms-2">
                        <input type="search" id="form1" placeholder="Enter Category Name" class="form-control" />
                        <span class="input-group-text"><i class="icofont icofont-search font-dark"></i></span>
                        <span class="select-catagory-heading ms-5"> Selected items</span>
                    </div>
                </div>
            </div>
            <div class="row border-bottom p-2" id="categoryGroupDiv">

                <div class="col-4 list-category firstLevel" id="level-1">
                    <ul *ngIf="firstLevel$" class="list-group ">
                        <li *ngFor="let item of firstLevel$; let fi = index" class="list-group-item d-flex row py-1">
                            <div class="col-1">
                                <input type="checkbox" (change)="onFirstLevelClick($event,item)" [checked]="item?.selected == true">
                            </div>
                            <div class="col-9" (click)="onFirstLevelSelected($event, item)">
                                <span class="select-item col-8 text-start ms-1">
                                    {{item.name}} </span>
                            </div>
                            <span class="col-1 text-end  search-icon ">
                                <i class="fa fa-angle-right font-dark"></i>
                            </span>
                        </li>
                    </ul>
                    <div class="vl "></div>
                </div>

                <div *ngIf="secondLevel$" class="col-4 secondLevel list-category" id="level-2">
                    <ul class="list-group">
                        <li *ngFor="let item of secondLevel$; let si = index"
                            class="list-group-item d-flex p-1 ">
                            <input type="checkbox" [checked]="item.selected == true"
                                (change)="onChangeSecondLevel($event.target.checked,item)">
                            <span class="ms-3">{{item.name}}</span>
                        </li>
                    </ul>
                </div>

                <div class="col-4  selectedCatagory">
                    <div *ngIf="selectedItems$.length">
                        <ul>
                            <li *ngFor="let item of selectedItems$ let si = index" class="p-1">
                                <label>{{item.path.join(' > ')}}</label>
                                <span (click)="removeItem(item, index)" class="text-end">
                                    <i class=" icon-close "></i>
                                </span>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer p-0 mb-2 mt-3 ">
        <button type="button" class="btn btn-outline-dark" (click)="confirm()">Cancel</button>
        <button type="button" class="btn btn-primary ms-2 " (click)="confirm()">Confirm</button>
    </div>
</div>