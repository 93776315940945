import { Component } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "src/app/shared/validators/custom_validator";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  signupForm: FormGroup;

  show = false;
  public submitted = false;
  public errors = '';
  passwordShow: boolean = false;
  emailButtonEnabled = false;
  resetPwdField = false;
  emailOtpField = false;
  emailVerfiy = false;


  constructor(private fb: FormBuilder, private route: Router,) { }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      emailGroup: this.fb.group({
        mail:
          [null, [Validators.required, CustomValidators.emailPhone]],

        emailOtp: [null,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern('^[0-9]+$'),
          ],
        ],
      }),
      password: [null, [
        Validators.required,
        /* Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ), */
        Validators.minLength(8),
      ]],
      confirmPassword: ["", Validators.required,],
      // password: [null, [
      //   Validators.required,
      //   // Validators.pattern(
      //   //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
      //   //   )
      //   Validators.minLength(8)]]

      // },
      //   { validator: CustomValidators.passwordMatcher });
    })

    console.log();
  }

  get f() {
    return this.signupForm.controls;
  }

  onsubmit() {
    this.submitted = true;
    if (!this.signupForm.valid) {
      // alert('Please fill all the required fields to create a super hero!');
      return false;
    } else {
      return console.log(this.signupForm.value);
    }
  }

  validateEmail() {

    let email = this.signupForm.get('');
    if (email?.errors == null) {
      console.log(email?.value);
      this.emailButtonEnabled = true;
    } else {
      this.emailButtonEnabled = false;
    }
  }

  showPassword() {
    this.passwordShow = !this.passwordShow
  }

  verfiyEmailOtp() {
    let verifyOtp = this.signupForm.get('emailGroup.emailOtp');
    if (verifyOtp?.errors == null) {
      console.log(verifyOtp?.value);
      this.resetPwdField = true;
    } else {
      this.resetPwdField = false;

    }
  }

  sendEmailOtp() {
    let email = this.signupForm.get('emailGroup.mail');
    if (email?.errors == null) {
      console.log(email?.value);
      this.emailOtpField = true;
    } else {
      this.emailOtpField = false;

    }
  }
}

