
// import { Component } from '@angular/core';
// import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { ToastrService } from 'ngx-toastr';
// import { environment } from 'src/environments/environment';
// import { AttributeServices } from '../services/attribute-services';
// import { Attribute } from '../services/attribute-modal';
// import { Data } from '../attribute-params';
// import { ActivatedRoute } from '@angular/router';

// @Component({
//   selector: 'app-attribute-edit',
//   templateUrl: './attribute-edit.component.html',
//   styleUrls: ['./attribute-edit.component.scss']
// })
// export class AttributeEditComponent {
//   userId: number;
//   roles: any = [];
//   submitted: boolean = false;
//   languages: Array<any> = environment.languages;
//   attributeForm: FormGroup;
//   translationForm: FormGroup;
//   visible: boolean = true;
//   allowDependent: boolean = true;
//   errors: any = [];
//   msg:any = [];
//   attributeTypes = Data.attributeTypes;
//   inputTypes = Data.inputTypes;
//   result:any =[];
//   dependent_attributes = [
//     // {
//     //   id: 0,
//     //   type: 'Select'
//     // },
//     {
//       id: 1,
//       type: 'Weight'
//     },
//     {
//       id: 2,
//       type: 'Ram Unit'
//     }
//   ];

//   dependents = [
//     {id:1, type:'No', value: 0},  {id:2, type:'Yes', value:1}
//   ];

//   allow_dependent: string ;

//   selectedType: number = 1;
//   public activePillIndex: number = 0;
//   attributeData: any;

//   constructor(
//     private route: ActivatedRoute,
//     private fb: FormBuilder,
//     private attributeService: AttributeServices,
//     private toast : ToastrService,
//   ) { 
//     const routeParams = this.route.snapshot.paramMap;
//     this.userId = Number(routeParams.get("id"));
//   }

//   ngOnInit(): void {
//     // this.attributeForm = this.fb.group({
//     //   mandatory: [false, [Validators.required]],
//     //   type_id: ['', Validators.required],
//     //   code: ['', [Validators.required]],
//     //   allow_dependent: [null, [Validators.required]],
//     //   dependent_attribute: [null],
//     //   default_value: [null],
//     //   input_type: [null, [Validators.required]],
//     //   is_filterable: [0, [Validators.required]],
//     //   is_searchable: [0, [Validators.required]],
//     //   is_comparable: [0, [Validators.required]],
//     //   is_user_defined: [0, [Validators.required]],
//     //   status: [1, [Validators.required]],
//     //   translations: this.fb.array([]),
//     //   options: this.fb.array([]),
//     // });
//     this.attributeForm = this.fb.group({
//       attributes: this.fb.array([]),
//     });

//     this.attributeData = this.attributeService.findOne(this.userId).subscribe((res:any) =>{
//       console.log(res.data);
//       this.attributeForm.setControl('attributes', this.fb.array(res.data));
//     });

//   //  this.addTranslation();
//    // this.addOption();


//    // this.attibute.findOne(this.userId).subscribe((res: any) => {   

//       // const _attribute: Attribute = res.data[0];
//       // this.attributeForm.get('type_id').setValue(_attribute.type_id);
//       // this.attributeForm.get('code').setValue(_attribute.code);
//       // this.attributeForm.get('allow_dependent').setValue(_attribute.allow_dependent);
//       // this.attributeForm.get('dependent_attribute').setValue(_attribute.dependent_attribute);
//       // this.attributeForm.get('input_type').setValue(_attribute.input_type);
//       // this.attributeForm.get('default_value').setValue(_attribute.default_value);
//       // this.attributeForm.get('is_filterable').setValue(_attribute.is_filterable);
//       // this.attributeForm.get('is_searchable').setValue(_attribute.is_searchable);
//       // this.attributeForm.get('is_comparable').setValue(_attribute.is_comparable);
//       // this.attributeForm.get('is_user_defined').setValue(_attribute.is_user_defined);
//       // this.attributeForm.get('status').setValue(_attribute.status);
//     //  this.translationsFrom.get('name').setValue(_attribute.translations[0].name);
//       // _attribute.translations.forEach(element => {
//       //   console.log(element);      
//       //   this.translationsFrom.get('name').setValue(element.name);
//       //   console.log(this.translationsFrom.value);
//       // });
//       // console.log(_translation);
//      // console.log(this.attributeForm.value.translations[0]);
//       // console.log(this.attributeForm.value.translations[0].name);
//       // console.log(_attribute.translations);
//       // console.log(this.translationsFrom.value);
//    // });

//   }


//   get attributesArray() {
//     return <FormArray>this.attributeForm.get('attributes');
//   }

//   createAttributeFormGroup(attribute: any){
//     return this.fb.group({
//       mandatory: [false, [Validators.required]],
//       attribute_id: [{ value: attribute.attribute_id, disabled: true }],
//       type_id: [attribute.type_id, [Validators.required]],
//       code: [attribute.code, [Validators.required]],
//       allow_dependent: [attribute.allow_dependent, [Validators.required]],
//       dependent_attribute: [attribute.dependent_attribute, [Validators.required]],
//       default_value: [attribute.default_value, [Validators.required]],
//       input_type: [attribute.input_type, [Validators.required]],
//       is_filterable: [attribute.is_filterable, [Validators.required]],
//       is_searchable: [attribute.is_searchable, [Validators.required]],
//       is_comparable: [attribute.is_comparable, [Validators.required]],
//       is_user_defined: [attribute.is_user_defined, [Validators.required]],
//       status: [attribute.status, [Validators.required]],
//       translations: this.fb.array(this.loadTranslationsArray(attribute.translations)),
//       options: this.fb.array(this.loadTranslationsArray(attribute.options)),
//     })
//   }

//   loadTranslationsArray(attributes: any[]) {
//     let transformedTranslations = attributes.map((attribute: any) => this.createTranslationFormGroup(attribute));
//     return transformedTranslations;
//   }

//   createTranslationFormGroup(translation: any) {
//     return this.fb.group({
//       language_id: [translation.language_id],
//       name: [translation.name, [Validators.required]],
//       tooltip_text: [translation.tooltip_text],
//       place_holder_text: [translation.place_holder_text],
//       error_message: [translation.error_message]
//     });
//   }

//   loadOptionsArray(options: any[]){
//     let transformedOptions = options.map((option: any) => this.createOptionFormGroup(option));
//     return transformedOptions;
//   }

//   createOptionFormGroup(option){

//   }

//   track(item: any, index: number) {
//     return index;
//   }




//   // get f() {
//   //   return this.attributeForm.controls;
//   // }

//   // onclick() {
//   //   this.visible = !this.visible;
//   // }

//   /* Translation Section */
//   // translationFormGroup(): FormGroup {
//   //   return this.fb.group({
//   //     language_id: [],
//   //     name: ['', [Validators.required]],
//   //     tooltip_text: [null],
//   //     place_holder_text: [null],
//   //     error_message: [null]
//   //   });
//   // }

//   // optionsFormGroup(): FormGroup {
//   //   let optionGroup = [];
//   //   optionGroup['default'] = [];
//   //   this.languages.forEach(lang => {
//   //     optionGroup[lang.code] = [''];

//   //   });
//   //   return this.fb.group(optionGroup);
//   // }

//   // get translations(): FormArray {
//   //   return this.attributeForm.get('translations') as FormArray
//   // }

//   // get translationsFrom() {
//   //   return this.attributeForm.get('translations');
//   // }

//   // get optionsForm(): FormArray {
//   //   return this.attributeForm.get('options') as FormArray;
//   // }

//   // addTranslation() {
//   //   this.languages.forEach(lang => {
//   //     this.translations.push(this.translationFormGroup());
//   //   });

//   // }

//   // changeAttributeType(event) {
//   //   // console.log(event);
//   //   // console.log(event.id);
//   //  //  this.selectedType = event.id;
//   //   this.selectedType = event.target.value; 
//   //   console.log(event.target.value);
//   // }

//   async save() {
//     this.submitted = true;
//     this.errors = [];
//     // const attribute_form = {
//     //   code: this.attributeForm.value.code,
//     //   type_id: this.attributeForm.value.type_id,
//     //   is_required: this.attributeForm.value.is_required,
//     //   is_unique: this.attributeForm.value.is_unique,
//     //   is_filterable: this.attributeForm.value.is_filterable,
//     //   is_searchable: this.attributeForm.value.is_searchable,
//     //   is_comparable: this.attributeForm.value.is_comparable,
//     //   input_type: this.attributeForm.value.input_type,
//     //   is_user_defined: this.attributeForm.value.is_user_defined,
//     //   is_visible_frontend: this.attributeForm.value.is_visible_frontend,
//     //   use_in_flat: this.attributeForm.value.use_in_flat,
//     //   is_configurable: this.attributeForm.value.is_configurable,
//     //   allow_dependent: this.attributeForm.value.allow_dependent,
//     //   dependent_id: this.attributeForm.value.dependent_id,
//     //   default_value: this.attributeForm.value.default_value,
//     //   status: this.attributeForm.value.status,
//     //   translations: [
//     //     {
//     //       language_id: this.translationsFrom.value.language_id,
//     //       name : this.translationsFrom.value.name,
//     //       is_primary: this.translationsFrom.value.is_primary,
//     //       place_holder_text: this.translationsFrom.value.place_holder_text,
//     //       tooltip_text: this.translationsFrom.value.tooltip_text,
//     //       error_message: this.translationsFrom.value.error_message,
//     //       // language_id: this.translationsFrom.value.forEach(element => {
//     //       //   element.language_id
//     //       // }),
//     //       // name: this.translationsFrom.value.forEach(element => {
//     //       //   element.name
//     //       // }),
//     //       // is_primary: this.translationsFrom.value.forEach(element => {
//     //       //   element.is_primary
//     //       // }),
//     //       // place_holder_text: this.translationsFrom.value.forEach(element => {
//     //       //   element.place_holder_text
//     //       // }),
//     //       // tooltip_text: this.translationsFrom.value.forEach(element => {
//     //       //   element.tooltip_text
//     //       // }),
//     //       // error_message: this.translationsFrom.value.forEach(element => {
//     //       //   element.error_message
//     //       // }),
//     //     }
//     //   ],
//     //   options: [
//     //     {
//     //       language_id: this.optionsForm.value.language_id,
//     //       text: this.optionsForm.value.en || this.optionsForm.value.hin,
//     //       place_holder_text: this.optionsForm.value.place_holder_text,
//     //       tooltip_text: this.optionsForm.value.tooltip_text,
//     //       error_message: this.optionsForm.value.error_message
//     //     }
//     //   ]
//     // }
//     // console.log(attribute_form);
//   //  if (this.attributeForm.status == 'VALID') {
//       //console.log(this.attributeForm.value);
//       const postData: Attribute = this.attributeForm.value;
//       console.log(postData);
//       const response = await this.attributeService.create(postData).subscribe((res: any) => {
//         console.log(res);
//         if (res.status == true) {
//         //  this.attributeForm.reset();
//           this.toast.success('Attribute has been added successfully');
//           return res.data;
//         } else {
//           this.errors = res.errors;
//           return res.errors;
//         }
//       });
//    // }
//   }

//   // addOption() {
//   //   this.optionsForm.push(this.optionsFormGroup());
//   // }

//   // removeTranslation(index: number) {
//   //   this.optionsForm.removeAt(index);
//   // }

//   cancel() {
//     this.attributeForm.reset()
//   }

//   selectLanguage(index) {
//     this.activePillIndex = index;
//   }

//   setValue() {
//     this.allow_dependent=this.attributeForm.get('allow_dependent')?.value;
//     console.log(this.allow_dependent);
//   }

//   // dependentStatus(event) {
//   //   let value = event.target.value;
//   //   if (value == 0) {
//   //     this.allowDependent = true;
//   //   } else {
//   //     this.allowDependent = false;
//   //   }
//   // }
// }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttributeServices } from '../services/attribute-services';
import { ActivatedRoute } from '@angular/router';
import { Data } from '../attribute-params';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attribute-edit',
  templateUrl: './attribute-edit.component.html',
  styleUrls: ['./attribute-edit.component.scss']
})

export class AttributeEditComponent implements OnInit, AfterViewInit {
  errors: any = [];
  msg: any = [];
  attributeForm: FormGroup;
  attributeData$: any;
  userId: number;
  attributeTypes = Data.attributeTypes;
  selectedType: number = 1;
  dependents = [{ id: 1, type: 'No', value: 0 }, { id: 2, type: 'Yes', value: 1 }];
  allow_dependent: string;
  dependent_attributes = [{ id: 1, type: 'Weight' }, { id: 2, type: 'Ram Unit' }];
  inputTypes = Data.inputTypes;
  languages: Array<any> = environment.languages;
  public activePillIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private attributeService: AttributeServices,
    private route: ActivatedRoute,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.userId = Number(routeParams.get("id"));
    this.attributeForm = this.fb.group({
      attributes: this.fb.array([]),
    });
  }

  get f() {
    return this.attributeForm.controls;
  }

  async ngOnInit() {
    await this.attributeService.findOne(this.userId).subscribe((res: any) => {
      this.attributeData$ = res.data;
      let transformedData = this.createAttributeFormGroup(this.attributeData$);
      this.attributeForm.setControl('attributes', transformedData);
      console.log(this.attributeForm);
    });
  }

  ngAfterViewInit() {
    console.log('after view init');
  }

  createTranslationFormGroup(translation: any) {
    return this.fb.group({
      language_id: [translation.language_id],
      name: [translation.name, [Validators.required]],
      tooltip_text: [translation.tooltip_text],
      place_holder_text: [translation.place_holder_text],
      error_message: [translation.error_message]
    });
  }

  loadTranslationsArray(tansalations: any[]) {
    let _translations = tansalations.map((transalation: any) => this.createTranslationFormGroup(transalation));
    console.log(_translations);
    return _translations;
  }

  createAttributeFormGroup(attribute: any) {
    return this.fb.group({
      mandatory: [false, [Validators.required]],
      type_id: [attribute.type_id, [Validators.required]],
      code: [attribute.code, [Validators.required]],
      allow_dependent: [attribute.allow_dependent, [Validators.required]],
      dependent_attribute: [attribute.dependent_attribute, [Validators.required]],
      default_value: [attribute.default_value, [Validators.required]],
      input_type: [attribute.input_type, [Validators.required]],
      is_filterable: [attribute.is_filterable, [Validators.required]],
      is_searchable: [attribute.is_searchable, [Validators.required]],
      is_comparable: [attribute.is_comparable, [Validators.required]],
      is_user_defined: [attribute.is_user_defined, [Validators.required]],
      status: [attribute.status, [Validators.required]],
      translations: this.fb.array(this.loadTranslationsArray(attribute.translations)),
    });
  }

  selectLanguage(index) {
    this.activePillIndex = index;
  }

  cancel(){
    
  }

}
