<div class="container-fluid">
    <div class="email-wrap">
        <div class="row">
            <div class="col-xl-3 col-md-6 box-col-6">
                <div class="email-left-aside">
                    <div class="card">
                        <div class="card-body">
                            <div class="email-app-sidebar">
                                <div class="media">
                                    <div class="media-size-email">
                                        <img class="me-3 rounded-circle img-60" src="assets/images/user/1.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <h6 class="f-w-600">MARKJENCO</h6>
                                        <p>Markjecno@gmail.com</p>
                                    </div>
                                </div>
                                <ul class="nav main-menu" role="tablist">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="btn-primary btn-block btn-mail w-100 active"
                                            role="tab" (click)="compose=true">
                                            <i class="icofont icofont-envelope me-2"></i>NEW MAIL
                                        </a>
                                    </li>
                                    <li class="nav-item" (click)="getUserEmail(type='inbox')">
                                        <a href="javascript:void(0)" class="show" id="pills-darkprofile-tab"
                                            data-bs-toggle="pill" role="tab" aria-controls="pills-darkprofile"
                                            aria-selected="false">
                                            <span class="title"><i class="icon-import"></i> Inbox</span>
                                            <span class="badge pull-right digits">({{ getUserEmail('inbox').length
                                                }})</span>
                                        </a>
                                    </li>
                                    <li (click)="getUserEmail(type='allmail')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-folder"></i> All
                                                mail</span></a>
                                    </li>
                                    <li (click)="getUserEmail(type='sent')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-new-window"></i>
                                                Sent</span>
                                            <span class="badge pull-right digits">({{ getUserEmail('sent').length
                                                }})</span>
                                        </a>
                                    </li>
                                    <li (click)="getUserEmail(type='draft')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-pencil-alt"></i>
                                                DRAFT</span>
                                            <span class="badge pull-right digits">({{ getUserEmail('draft').length
                                                }})</span>
                                        </a>
                                    </li>
                                    <li (click)="getUserEmail(type='trash')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-trash"></i>
                                                TRASH</span>
                                            <span class="badge pull-right digits">({{ getUserEmail('trash').length
                                                }})</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-info-alt"></i>
                                                IMPORTANT</span></a>
                                    </li>
                                    <li (click)="getUserEmail(type='favourite')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-star"></i>
                                                Starred</span></a>
                                    </li>
                                    <li>
                                        <hr>
                                    </li>
                                    <li (click)="getUserEmail(type='unread')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-email"></i>
                                                UNREAD</span></a>
                                    </li>
                                    <li (click)="getUserEmail(type='spam')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-export"></i>
                                                SPAM</span></a>
                                    </li>
                                    <li (click)="getUserEmail(type='outbox')">
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-share"></i>
                                                OUTBOX</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-file"></i>
                                                UPDATE</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-bell"></i>
                                                ALERT</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><span class="title"><i class="icon-notepad"></i>
                                                NOTES</span>
                                            <span class="badge pull-right digits">(20)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 box-col-6">
                <div class="email-right-aside">
                    <div class="card email-body">
                        <div class="pe-0 b-r-light">
                            <div class="email-top">
                                <div class="row">
                                    <div class="col">
                                        <h5>{{type ? ( type | titlecase ) : 'Inbox' }}</h5>
                                    </div>
                                    <div class="col text-end">
                                        <div ngbDropdown class="dropdown">
                                            <button class="btn bg-transparent dropdown-toggle p-0 text-muted"
                                                id="dropdownMenuButton" ngbDropdownToggle>More</button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton"
                                                class="email-option-position">
                                                <a href="javascript:void(0)" class="dropdown-item"
                                                    (click)="moveEmails('draft')">Move to
                                                    Draft</a>
                                                <a href="javascript:void(0)" class="dropdown-item"
                                                    (click)="moveEmails('trash')">Move to
                                                    Trash</a>
                                                <a href="javascript:void(0)" class="dropdown-item"
                                                    (click)="moveEmails('spam')">Move to Spam</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="inbox custom-scrollbar">
                                <a href="javascript:void(0)"
                                    *ngFor="let userEmail of getUserEmail(type ? type : 'inbox') | slice:0:7">
                                    <div class="media">
                                        <label class="d-block" for="chk-ani">
                                            <input class="checkbox_animated" id="chk-ani" name="chk-ani" type="checkbox"
                                                (change)=selectedmail($event,userEmail)>
                                        </label>
                                        <div class="media-size-email" (click)="selectedUserEmail(userEmail)"><img
                                                class="me-3 rounded-circle img-50" [src]='userEmail.image' alt=""></div>
                                        <div class="media-body" (click)="selectedUserEmail(userEmail)">
                                            <h6>{{userEmail.name}} <small>(<span
                                                        class="digits">{{userEmail.date}}</span>)</small></h6>
                                            <p>{{userEmail.cc}}</p>
                                        </div>
                                    </div>
                                </a>
                                <ng-container *ngIf="!getUserEmail(type ? type : 'inbox').length">
                                    <div class="search-not-found text-center">
                                        <div class="">
                                            <img src="assets/images/search-not-found.png" alt="" class="second-search">
                                            <p class="mb-0">No mail found</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-12 box-col-12">
                <div class="email-right-aside">
                    <div class="card email-body radius-left">
                        <div class="ps-0">
                            <div class="tab-content">
                                <div class="tab-pane fade" [ngClass]="{'active show':compose}" id="pills-darkhome"
                                    role="tabpanel" aria-labelledby="pills-darkhome-tab">
                                    <div class="email-compose">
                                        <div class="email-top compose-border">
                                            <div class="row">
                                                <div class="col-sm-8 xl-50">
                                                    <h4 class="mb-0">New Message</h4>
                                                </div>
                                                <div class="col-sm-4 btn-middle xl-50">
                                                    <button class="btn btn-primary btn-mail text-center mb-0 mt-0 w-100"
                                                        type="button"><i class="fa fa-paper-plane me-2"></i>
                                                        SEND</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="email-wrapper">
                                            <form class="theme-form">
                                                <div class="form-group">
                                                    <label class="col-form-label pt-0"
                                                        for="exampleInputEmail1">To</label>
                                                    <input class="form-control" id="exampleInputEmail1" type="email">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Subject</label>
                                                    <input class="form-control" id="exampleInputPassword1" type="text">
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label>Message</label>
                                                    <ckeditor [editor]="ClassicEditor" data="<p>Hello, world!</p>">
                                                    </ckeditor>
                                                    <!-- <ck-editor name="editor" id="text-box" [(ngModel)]="editorValue" skin="moono-lisa" language="en"></ck-editor> -->
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" [ngClass]="{'active show':!compose}" id="pills-darkprofile"
                                    role="tabpanel" aria-labelledby="pills-darkprofile-tab">
                                    <div class="email-content">
                                        <div class="email-top">
                                            <div class="row">
                                                <div class="col-md-6 xl-100 col-sm-12">
                                                    <div class="media">
                                                        <img class="me-3 rounded-circle img-50"
                                                            src="{{selectEmail?.image}}" alt="image">
                                                        <div class="media-body">
                                                            <h6>{{selectEmail?.name}} <small><span
                                                                        class="digits">({{selectEmail?.date}})</span>
                                                                    <span class="digits"> 6:00</span> AM</small></h6>
                                                            <p>{{selectEmail?.cc}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="float-end d-flex">
                                                        <p class="user-emailid">{{selectEmail?.email}}</p>
                                                        <i class="fa fa-star-o f-18 mt-1"
                                                            [class.starred]="selectEmail?.favourite"
                                                            (click)="addFavourite(selectEmail)"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="email-wrapper">
                                            <span [innerHTML]="selectEmail?.text"></span>
                                            <hr>
                                            <div class="d-inline-block">
                                                <h6 class="text-muted"><i class="icofont icofont-clip"></i> ATTACHMENTS
                                                </h6><a class="text-muted text-end right-download"
                                                    href="javascript:void(0)"><i
                                                        class="fa fa-long-arrow-down me-2"></i>Download All</a>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="attachment">
                                                <ul class="list-inline">
                                                    <li class="list-inline-item"><img class="img-fluid"
                                                            src="assets/images/email/1.jpg" alt="">
                                                    </li>
                                                    <li class="list-inline-item"><img class="img-fluid"
                                                            src="assets/images/email/2.jpg" alt="">
                                                    </li>
                                                    <li class="list-inline-item"><img class="img-fluid"
                                                            src="assets/images/email/3.jpg" alt="">
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr>
                                            <div class="action-wrapper">
                                                <ul class="actions">
                                                    <li><a class="text-muted" href="javascript:void(0)"><i
                                                                class="fa fa-reply me-2"></i>Reply</a>
                                                    </li>
                                                    <li><a class="text-muted" href="javascript:void(0)"><i
                                                                class="fa fa-reply-all me-2"></i>Reply
                                                            All</a></li>
                                                    <li><a class="text-muted" href="javascript:void(0)"><i
                                                                class="fa fa-share me-2"></i></a>Forward</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>