export const flashAnnouncementList = [{
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    targetLink: 'Announcement Link',
    folder: 'user123',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    targetLink: 'Announcement Link',
    folder: 'user456',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}]