import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipePipe } from './capitalize-pipe.pipe';
import { FilterPipe } from './filter.pipe';
import { InitialsPipe } from './initials.pipe';
import { RoundPipePipe } from './round-pipe.pipe';
import { SafePipe } from './safe.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';
import { TimingPipePipe } from './timing-pipe.pipe';

@NgModule({
    declarations: [
        CapitalizePipePipe,
        FilterPipe,
        InitialsPipe,
        RoundPipePipe,
        SafePipe,
        StripHtmlPipe,
        TimingPipePipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipeModule { }
