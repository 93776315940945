import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'src/app/shared/validators/custom_validator';
import { UserCreate } from 'src/app/modules/users/models/UserFrom';
import { UserService } from 'src/app/modules/users/services/user.service';
import { Router } from '@angular/router';
import { userModel } from '../model/user.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RolesService } from '../../services/roles.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})


export class UserCreateComponent implements OnInit {
  submitted:any;
  validate:any;
  passwordShow:any;
  name: string;
  errors:any = [];
  createUserForm: FormGroup;
  submited: boolean = false;
  step1Validate = new BehaviorSubject(false);
  step2Validate = new BehaviorSubject(false);
  step3Validate = new BehaviorSubject(false);
  // public validate = false;
  // public tooltipValidation = false;

  assignDepartment = [{ id: 1, name: "Digital Marketing" }];
  assignDesignation = [{ id: 1, name: "Digital Marketing" }];
  assignRoles:any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userCreate: UserService,
    private userRole: RolesService,
    private storage: StorageService,
    private toast: ToastrService,) {
  }
  // isSubmitted = false;

  ngOnInit(): void {

    this.createUserForm = this.fb.group({
      firstFormGroup: this.fb.group({
        firstName: ['', [Validators.required, Validators.minLength(3)]],
        lastName: [''],
        address: ['', Validators.required],
        phoneno: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        dob: ['', Validators.required],
        bloodGroup: [''],
        biography: [''],
        gender: [0, Validators.required],
      }),
      secondFormGroup: this.fb.group({
        jobtitle: ['', Validators.required],
        assignDepartment: ['', Validators.required],
        assignDesignation: ['', Validators.required],
        hiredate: ['', Validators.required],
      }),
      thirdFormGroup: this.fb.group({
        userName: [''],
        email: [null, [Validators.required,
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]],
        password: [null, [Validators.required, Validators.pattern
          (/^(?=.*[a-z])(?=.*[0-9])/), Validators.minLength(8)]],
        bankDetails: [''],
        assignRoles: ['', Validators.required],
      })
    })

    this.userRole.getAll().subscribe((res: any) =>{
      console.log(res);
      this.assignRoles = res.data;
    })
  }

  get f() {
    return this.createUserForm.controls;
  }

  get firstFormGroup() {
    return this.f.firstFormGroup;
  }

  get secondFormGroup() {
    return this.f.secondFormGroup;
  }

  get thirdFormGroup() {
    return this.f.thirdFormGroup;
  }

  showPassword() {
    this.passwordShow = !this.passwordShow
  }

  step1() {
    this.step1Validate.next(true);
    //console.log(this.firstFormGroup);
  }

  step2() {
    this.step2Validate.next(true);
    //console.log(this.secondFormGroup);
  }

  generate() {
    const value = this.userCreate.generatePassword().subscribe((res:any)=>{
      console.log(res);
      console.log(res.data);
      if(res.status === true){
          this.thirdFormGroup.get('password').setValue(res.data)
      }
    })
  }

  async onsubmit() {
    this.step3Validate.next(true);
    this.submitted = true;
    this.validate = !this.validate;
    //console.log(this.createUserForm.value)

    const postData = {
      username: this.thirdFormGroup.value.userName,
      email: this.thirdFormGroup.value.email,
      password: this.thirdFormGroup.value.password,
      roles: this.thirdFormGroup.value.assignRoles,
      firstname: this.firstFormGroup.value.firstName,
      lastname: this.firstFormGroup.value.lastName,
      address: this.firstFormGroup.value.address,
      phoneno: this.firstFormGroup.value.phoneno,
      dob: this.firstFormGroup.value.dob,
      bloodGroup: this.firstFormGroup.value.bloodGroup,
      biography: this.firstFormGroup.value.biography,
      gender: Number (this.firstFormGroup.value.gender),
      jobtitle: this.secondFormGroup.value.jobtitle,
      assignDepartment: this.secondFormGroup.value.assignDepartment,
      assignDesignation: this.secondFormGroup.value.assignDesignation,
      hiredate: this.secondFormGroup.value.hiredate,
      bankdetails: this.thirdFormGroup.value.bankDetails,
      // auth_key: this.storage.get('uuid'),
    }
    console.log(postData);
    if (this.createUserForm.status == 'VALID') {
      const response = await this.userCreate.create(postData).subscribe((res: any) => {
        console.log(res);
          if (res.status == true) {
            this.createUserForm.reset();
            this.toast.success('User Profile has been added successfully');
            return this.router.navigate(['/user-management/user']) && res.data;
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        // }, (error) => {
        //   return error;
        }
      );
      console.log(response);
    }
  }

}