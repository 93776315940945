import { AuthenticationService } from 'src/app/auth/services';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(
    public router: Router,
    private authServices: AuthenticationService
  ) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      this.userName = user.username;
    } else {
    }
  }

  ngOnInit() { }

  logoutFunc() {
    this.authServices.logout();
    this.router.navigateByUrl('auth/login');
  }
}
