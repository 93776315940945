import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { NgbdSortableHeader } from 'src/app/theme/directives/NgbdSortableHeader';
import { CityService } from './city.service';
import { City } from './models/City';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '../state/state.service';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent {

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  displayedColumns = ["slNo", "name", "state_id", "action"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;
  isRateLimitReached = false;
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  sortBy = [{ value: "ASC", name: "Ascending Order" }, { value: "DESC", name: "Descending Order" }];

  public searchText;
  total$: Observable<number>;
  public selected = [];
  city: any = [];
  state: any = [];
  getState: any = [];

  cityCreateForm: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  validate: any;
  errors: any = [];
  cityId: number;
  stateId: number;
  stateName: string;
  state_id: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cityService: CityService,
    private stateService: StateService,
    private toast: ToastrService,) {
    const routeParams = this.route.snapshot.paramMap;
    this.cityId = Number(routeParams.get("id"));
  }
  ngOnInit() {

    this.stateService.getStateList().subscribe((res: any) => {
      console.log(res);
      this.getState = res.data;
      this.state_id = res.data.id;
      this.stateName = res.data.name;
    })

    this.cityService.getCityState().subscribe((res:any) => {
      console.log(res);
    //  let State =res.data.state;
    //   console.log(State);
    })

    this.cityCreateForm = this.fb.group({
      name: ['', Validators.required],
      state_id: ['', Validators.required],
    });


    this.cityService.findOne(this.cityId).subscribe((res: any) => {
      if (res.status == true) {
        console.log(res);
        const cityedit: City = res.data[0];
    
        this.cityCreateForm.get('name').setValue(cityedit.name);
        this.cityCreateForm.get('state_id').setValue(cityedit.state_id);
      }
    });

    this.searchForm = this.fb.group({
      search: [null],
      sort: [null],
      state: [null]
    });

  }

  async ngAfterViewInit() {
    await this.getCityList();
  }

  selectState(state) {
    if (state) {
      this.state = this.getState;
    }
  }

  async getCityList() {
    await this.cityService.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.city = res.data.data;
        this.paginationMeta = res.data.meta;
        this.isLoadingResults = false;
        console.log(this.paginationMeta);
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getCityList();
  }

  getStateName(id: number) {
    let state_name: string = ''
    this.getState.forEach((item) => {
      if (item.id == id) {
        state_name = item.name;
      }
    })
    return state_name;
  }

  onRowClicked(row) {
    console.log('Row clicked: ', row);
  }


  async onSelect(id: number) {

    const postData = {
      id: id,
      // status: 0,
    };
    const response = await this.cityService.delete(postData).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.city = this.city.filter(city => city.id !== id);
        console.log(this.city);
        this.getCityList();
        //return this.router.navigate(['/masters/city']) && res.data;
      } else {
        this.toast.error(res.errors);
        console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }

  async onSubmit() {
    this.submitted = true;
    this.validate = !this.validate;
    console.log(this.cityCreateForm.value);
    if (this.cityId) {
      const postData = {
        id: this.cityId,
        name: this.cityCreateForm.value.name,
        state_id: this.cityCreateForm.value.state_id,
      }
      console.log(postData);

      if (this.cityCreateForm.status == 'VALID') {
        const response = await this.cityService.update(postData).subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.cityCreateForm.reset();
            this.toast.success('City has been updated successfully');
            this.getCityList();
            return this.router.navigate(['/masters/city']);
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        });
        console.log(response);
      }
    } else {
      const postData: City = this.cityCreateForm.value;

      if (this.cityCreateForm.status == 'VALID') {

        const response = await this.cityService.create(postData)
          .subscribe((res: any) => {
            console.log(res);
            if (res.status == true) {
              this.cityCreateForm.reset();
              this.toast.success('City has been added successfully');
              this.getCityList();
              //return this.router.navigate(['/masters/city']);
            } else {
              this.toast.error(res.errors);
              this.errors = res;
              return res;
            }
          }
          );
        console.log(response);
      }
    }

  }

  get f() {
    return this.cityCreateForm.controls;
  }

  onSearch() {
    console.log(this.searchForm.value);
    if (this.searchForm.value.search != null) {
      this.pagination.search = this.searchForm.value.search;
    }

    this.pagination.sortBy = this.searchForm.value.sort || ["id"];

    this.getCityList();

  }

  onClear(){
    this.searchForm.reset();
    this.pagination.search = '';
    this.getCityList();

  }
}
