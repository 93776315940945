export const folderArray = [
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 1",
        categories: "1",
        createdOn: "12-08-2023 9.00",
        article: "2",

        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: '2-06-2023 6:00',

        updatedBy: "Bhadri",
        status: "Active",
        action: "Edit"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        article: "2",
        categories: "2",
        updatedOn: '2-06-2023 6:00',
        userGroup: ['admin', 'seller', 'quality'],
        createdOn: "12-08-2023 9.00",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 1",
        categories: "1",
        article: "2",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "14-08-2023",
        updatedBy: "Bhadri",
        status: "Active",
        action: "Edit"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        categories: "2",
        article: "3",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "13-08-2023",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 1",
        categories: "1",
        article: "2",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "14-08-2023",
        updatedBy: "Bhadri",
        status: "Active",
        action: "Edit"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        categories: "2",
        article: "3",
        createdOn: "12-08-20 23",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "13-08-2023",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        categories: "2",
        article: "3",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "13-08-2023",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 1",
        categories: "1",
        article: "2",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "14-08-2023",
        updatedBy: "Bhadri",
        status: "Active",
        action: "Edit"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        categories: "2",
        article: "3",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "13-08-2023",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 1",
        categories: "1",
        article: "2",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "14-08-2023",
        updatedBy: "Bhadri",
        status: "Active",
        action: "Edit"
    },
    {
        img: "../../../../../assets/images/logo/logo-icon.png",
        folderDetails: "Folder 2",
        categories: "2",
        article: "3",
        createdOn: "12-08-2023 9.00",
        userGroup: ['admin', 'seller', 'quality'],
        updatedOn: "13-08-2023",
        updatedBy: "Bhadri",
        status: "Inactive",
        action: "Delete"
    },
    // Add more objects as needed
];
