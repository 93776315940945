<div class="container-fluid">
    <div class="row ">

        <div class="card  mt-3">
            <div class="card-body">
                <h5>Create New Flash Announcement</h5>
                <hr>
                <form [formGroup]="myForm">
                    <div class="row mt-4">
                        <div class="col-lg-10 mx-auto">
                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Title <sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="title" type="text" placeholder="Enter Title"
                                        formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('title').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('title').errors.required">
                                            Title is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2">
                                <div class="input-group row">
                                    <div class="mb-2 col-2 text-end">
                                        <label> Image<sup class="text-danger">*</sup></label>
                                    </div>
                                    <div class="mb-2 col-10">

                                    </div>
                                </div>
                            </div>

                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Target Link<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="name" type="text" placeholder="Enter Target Link"
                                        formControlName="link" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('link').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('link').errors.required">
                                            Target link is required
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 text-start">
                                    <p><b>Settings</b></p>
                                </div>
                            </div>

                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>User Group <sup class="text-danger">*</sup></label> <br>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-check-input" type="checkbox" id="business"
                                        formControlName="userGroup" value="Business">
                                    <label class="ms-1" for="business">Admin</label>
                                    <input class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Seller</label><input
                                        class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Buyer</label>
                                    <!-- <input
                                        class="form-check-input ms-2" type="checkbox" id="individual"
                                        formControlName="userGroup" value="Individual">
                                    <label class="ms-1" for="individual">Quality</label> -->
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('userGroup').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('userGroup').errors.required">
                                            User Group is required
                                        </p>
                                    </div>
                                </div>
                            </div>






                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Status<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10">
                                    <select class="form-select form-select-md text-dark"
                                        aria-label=".form-select-md example" formControlName="status">
                                        <option disabled selected value="" class="text-muted">
                                            Select Status
                                        </option>
                                        <option value="1">Active</option>
                                        <option value="2">Draft</option>


                                    </select>
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('status').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('status').errors.required">
                                            Status is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end">
                                    <label>Active Duration <sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="title" type="date" placeholder=""
                                        formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('title').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('title').errors.required">
                                            Active Duration is required
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-light font-primary">
                                            Cancel
                                        </button></span>
                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-primary"
                                            (click)="onSubmit()" type="submit">
                                            Save
                                        </button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>