<div class="container-fluid pt-3">
  <h4>Create New User</h4>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="createUserForm" class="needs-validation">
            <mat-stepper linear #stepper>
              <mat-step [stepControl]="firstFormGroup" label="User Information">
                <div class="setup-content" id="step-1">
                  <div formGroupName="firstFormGroup">
                    <div class="col-xs-12 mt-2">
                      <div class="inputgroup row">
                        <div class="mb-2 col-6">
                          <div class="form-group">
                            <label for="fname">First Name<sup class="text-danger">*</sup></label>
                            <input class="form-control" id="fname" type="text" placeholder=""
                              formControlName="firstName" required="required">
                          </div>
                          <div *ngIf="(step1Validate | async) && firstFormGroup.get('firstName').errors">
                            <div *ngIf="(step1Validate | async) && firstFormGroup.get('firstName').errors['required']"
                              class="text text-danger"><span>First Name is required</span>
                            </div>
                            <div *ngIf="(step1Validate | async) && firstFormGroup.get('firstName').errors['minlength']"
                              class="text text-danger"><span>Invalid First Name</span>
                            </div>
                          </div>
                        </div>
                        <div class="mb-2 col-6">
                          <div class="form-group">
                            <label for="lname">Last Name</label>
                            <input class="form-control" id="lname" type="text" placeholder=""
                              formControlName="lastName">
                          </div>
                        </div>
                      </div>
                      <div class="mb-2 ">
                        <div class="form-group">
                          <label for="address">Address<sup class="text-danger">*</sup></label>
                          <textarea class="form-control" id="address" formControlName="address" placeholder=""
                            required="required" rows="3"></textarea>
                        </div>

                        <div *ngIf="(step1Validate | async) && firstFormGroup.get('address').errors"
                          class="text text-danger"><span>Address is required</span>
                        </div>

                      </div>

                      <div class="inputgroup row">
                        <div class="mb-2 col-6">
                          <label for="phoneno"> Phone Number<sup class="text-danger">*</sup></label>
                          <input class="form-control" id="phoneno" type="text" placeholder="" formControlName="phoneno"
                            required="required" onlyNumbers>

                          <div *ngIf="(step1Validate | async) && firstFormGroup.get('phoneno').errors">
                            <div *ngIf="(step1Validate | async) && firstFormGroup.get('phoneno').errors['required']"
                              class="text text-danger"><span>Phone number is required</span>
                            </div>
                            <div *ngIf="(step1Validate | async) && firstFormGroup.get('phoneno').errors['pattern']"
                              class="text text-danger"><span>Please enter 10 digit phone number</span>
                            </div>
                          </div>

                        </div>
                        <div class="mb-2 col-6">
                          <label for="bloodGroup">Blood Group<sup class="text-danger">*</sup></label>
                          <input class="form-control" id="email" type="text" placeholder="" formControlName="bloodGroup"
                            required="required">
                        </div>

                      </div>

                      <div class="inputgroup row">
                        <div class="mb-2 col-6">
                          <label for="dob">Date of Birth<sup class="text-danger">*</sup></label>
                          <input class="form-control" id="dob" type="date" placeholder="" formControlName="dob"
                            required="required" onlyNumbers>

                          <div *ngIf="(step1Validate | async) && firstFormGroup.get('dob').errors"
                            class="text text-danger"><span>Date of Birth is required</span>
                          </div>
                        </div>
                        <div class=" mb-2 col-6 custom-control custom-radio">
                          <div for="gender" class="mb-2 mt-1">Gender<sup class="text-danger">*</sup></div>
                          <label for="male">Male</label>
                          <input type="radio" class="ms-1" id="male" value=1 name="gender" formControlName="gender"
                            required="required">
                          <label for="female" class="ms-3">Female</label>
                          <input type="radio" class="ms-1" id="female" value=2 name="gender" formControlName="gender">
                          <label for="female" class="ms-3">Other</label>
                          <input type="radio" class="ms-1" id="other" value=3 name="gender" formControlName="gender">

                          <div *ngIf="(step1Validate | async) && firstFormGroup.get('gender').errors"
                            class="text text-danger"><span>Gender is required</span>
                          </div>

                        </div>
                      </div>

                      <div class="mb-2 ">
                        <div class="form-group">
                          <label for="bio">Biography<sup class="text-danger">*</sup></label>
                          <textarea class="form-control" id="bio" formControlName="biography" placeholder=""
                            required="required" rows="3"></textarea>
                        </div>
                      </div>

                      <div class="col-10 offset-1 text-end mt-3">
                        <button class="btn btn-primary btn-sm" type="submit" (click)="step1()"
                          matStepperNext>Next</button>

                      </div>
                    </div>
                  </div>
                </div>
              </mat-step>
              <mat-step [stepControl]="secondFormGroup" label="Job Information">
                <div class="setup-content" id="step-2">
                  <div formGroupName="secondFormGroup">
                    <div class="col-xs-12 mt-2">
                      <div class="inputgroup row">
                        <div class="mb-2  ">
                          <label>Job Title<sup class="text-danger">*</sup></label>
                          <input class="form-control" id="jobtitle" type="text" placeholder="" required="required"
                            formControlName="jobtitle">

                          <div *ngIf="(step2Validate | async) && secondFormGroup.get('jobtitle').errors"
                            class="text text-danger"><span>Job Title is required</span>
                          </div>

                        </div>
                      </div>
                      <div class="inputgroup row">
                        <div ngbDropdown class="mb-2">
                          <label for="address" class="col-form-label col-1 px-0">Department<sup
                              class="text-danger">*</sup></label>

                          <ng-select [items]="assignDepartment" bindValue="id" [clearable]="false" bindLabel="name"
                            class="custom" placeholder="Select Department"
                            formControlName="assignDepartment"></ng-select>

                        </div>
                      </div>
                      <div class="inputgroup row">
                        <div ngbDropdown class="mb-2">
                          <label for="address" class="col-form-label col-1 px-0">Designation<sup
                              class="text-danger">*</sup></label>

                          <ng-select [items]="assignDesignation" bindValue="id" [clearable]="false" bindLabel="name"
                            class="custom" placeholder="Select Designation"
                            formControlName="assignDesignation"></ng-select>

                        </div>
                      </div>
                      <div class="inputgroup row">
                        <div class="mb-2">
                          <label for="hire">Date of Hire<sup class="text-danger">*</sup></label>
                          <input class="form-control" id="hire" type="date" placeholder="" formControlName="hiredate"
                            required="required" onlyNumbers>

                          <div *ngIf="(step2Validate | async) && secondFormGroup.get('hiredate').errors"
                            class="text text-danger"><span>Hire date is required</span>
                          </div>

                        </div>
                      </div>

                      <div class="col-10 offset-1 text-end mt-3">
                        <button class="btn btn-primary btn-sm pull-right" type="button" (click)="step2()"
                          matStepperNext>Next</button>
                        <button class="btn btn-secondary btn-sm pull-right me-1" type="button"
                          matStepperPrevious>Previous</button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="thirdFormGroup" label="Account Details">
                <div class="setup-content" id="step-3">
                  <div formGroupName="thirdFormGroup">
                    <div class="col-xs-12 mt-2">
                      <div class="inputgroup row">
                        <div class="form-group">
                          <label class="col-form-label">User Name<span class="text-danger">*</span></label>
                          <input class="form-control" formControlName="userName" required="" placeholder="">
                        </div>
                      </div>
                      <div class="inputgroup row">

                        <div class="form-group">
                          <label class="col-form-label">Email Address<span class="text-danger">*</span></label>
                          <div class="input-group">
                            <input class="form-control" type="email" placeholder="Enter Your Email Address"
                              formControlName="email">
                          </div>

                          <div *ngIf="(step3Validate | async) && thirdFormGroup.get('email').errors">
                            <div *ngIf="(step3Validate | async) && thirdFormGroup.get('email').errors['required']"
                              class="text text-danger"><span>Email is required</span>
                            </div>
                            <div *ngIf="(step3Validate | async) && thirdFormGroup.get('email').errors['pattern']"
                              class="text text-danger"><span>Invalid Email</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="inputgroup row">
                        <div class="form-group col-11 pe-0 ">
                          <label class="col-form-label">Password <span class="text-danger">*</span></label>
                          <input class="form-control" formControlName="password" required="" placeholder="*********">
                          <!-- <span><i class="fa fa-eye"></i></span> -->

                          <div *ngIf="(step3Validate | async) && thirdFormGroup.get('password').errors">
                            <div *ngIf="(step3Validate | async) && thirdFormGroup.get('password').errors['required']"
                              class="text text-danger"><span>Password is required</span>
                            </div>
                            <div *ngIf="(step3Validate | async) && thirdFormGroup.get('password').errors['pattern']"
                              class="text text-danger"><span>Password must be strong</span>
                            </div>
                            <div *ngIf="(step3Validate | async) && thirdFormGroup.get('password').errors['minlength']"
                              class="text text-danger"><span>Password must be 8 characters</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-1 mt-3 pt-1">
                          <label class="col-form-label mt-2">
                            <button (click)="generate()" class="btn btn-secondary pt-1 px-2">Generate</button>
                          </label>
                        </div>
                        <!-- <div class="form-group mt-3 pt-1">
                            <label class="col-form-label mt-2">
                            <button class="btn btn-secondary pt-1"><i class="fa fa-eye"></i></button>
                          </label>
                          </div> -->
                      </div>
                      <div class="inputgroup row">
                        <div class="form-group">
                          <label class="col-form-label">Bank Details<span class="text-danger">*</span></label>
                          <input class="form-control" formControlName="bankDetails" required="" placeholder="">
                        </div>
                      </div>
                      <div class="inputgroup row">
                        <div ngbDropdown class="mb-2">
                          <label for="address" class="col-form-label col-1 px-0">Rloes<sup
                              class="text-danger">*</sup></label>

                          <ng-select [items]="assignRoles" bindValue="role_id" [clearable]="false" bindLabel="role_name"
                            class="custom" [multiple]="true" placeholder="Select Roles" formControlName="assignRoles"></ng-select>

                        </div>
                      </div>

                      <div class="col-10 offset-1 text-end mt-3">
                        <button class="btn btn-primary btn-sm pull-right" type="button"
                          (click)="onsubmit()">Submit</button>
                        <button class="btn btn-secondary btn-sm pull-right me-1" type="button"
                          matStepperPrevious>Previous</button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-step>
            </mat-stepper>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>