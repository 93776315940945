<!-- Container-fluid starts
step-1 -->
<div class="container-fluid pt-3">
    <h4>Update Category</h4>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" *ngIf="categoryData.length != 0">
                    <form [formGroup]="categoryForm" class="form-needs-validation">
                        <div formArrayName="category">
                            <mat-stepper linear #stepper>
                                <mat-step [stepControl]="first" label="Select Category">
                                    <div class="setup-content mt-2" id="step-1" formGroupName="first">
                                        <div class="row">
                                            <div *ngIf = "!getSelectedPath()" class="col-12 py-1">
                                                <h5 class="text-center">
                                                    Root Category 
                                                </h5>
                                            </div>
                                            <div *ngIf = "getSelectedPath()" class="col-12 py-1">
                                                <h5 class="text-center">
                                                    Sub-Category Path :
                                                    <span class="font-primary">
                                                        {{ getSelectedPath() }}
                                                    </span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-10 offset-1 text-end">
                                        <button (click)="step1()" class="btn
                                        btn-primary btn-sm pull-right" matStepperNext>
                                            Next
                                        </button>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="translations" label="Category Translation">
                                    <div class="setup-content row" id="step-2">
                                        <div class="col-6 offset-3">
                                            <h5 class="title text-center py-3">Add
                                                Translation</h5>
                                        </div>


                                        <div class="col-12">
                                            <div class="row border-bottom
                                            border-top">
                                                <div class="col-md-2 border-end">
                                                    <div class="nav flex-column
                                                    nav-pills me-3 mt-3" id="language-tab" role="tablist"
                                                        aria-orientation="vertical">
                                                        <button *ngFor="let item of
                                                        languages; let index=
                                                        index" [ngClass]="{
                                                        active:
                                                        index ===
                                                        activePillIndex }" (click)="selectPill(index)" class="nav-link border
                                                        my-2" id="v-pills-{{
                                                        item.code
                                                        }}-tab" data-bs-toggle="pill" data-bs-target="#v-pills-{{
                                                        item.id }}" type="button" role="tab" aria-controls="v-pills-{{
                                                        item.code }}" aria-selected="true">
                                                            {{ item.label }}
                                                        </button>
                                                    </div>
                                                </div>



                                                <div class="col-md-6" formArrayName="translations">
                                                    <div class="tab-content" id="language-tabContent">
                                                        <div *ngFor="let item of
                                                        languages; let index=
                                                        index" [ngClass]="{
                                                        'show
                                                        active': index ===
                                                        activePillIndex }" [formGroupName]="index" class="translation-form
                                                        my-2 tab-pane fade" id="v-pills-{{ item.id
                                                        }}" role="tabpanel" aria-labelledby="v-pills-{{
                                                        item.code }}-tab">
                                                            <h5 class="text-title">
                                                                Enter Category
                                                                Details -
                                                                {{ item.label }}
                                                            </h5>
                                                            <input type="text" formControlName="language_id"
                                                                class="form-control" hidden />
                                                            <div class="mt-1
                                                            form-group">
                                                                <label for="description" class="form-label">
                                                                    Category Name
                                                                </label>
                                                                <input type="text" formControlName="name"
                                                                    class="form-control"
                                                                    placeholder="Enter category name" required />
                                                            </div>

                                                            <div class="mt-1
                                                            form-group">
                                                                <label for="meta_title" class="form-label">
                                                                    Category Title
                                                                </label>
                                                                <input type="text" formControlName="meta_title"
                                                                    class="form-control"
                                                                    placeholder="Enter category title" required />
                                                            </div>

                                                            <div class="mt-1
                                                            form-group">
                                                                <label for="description" class="form-label">
                                                                    Category
                                                                    Description
                                                                </label>
                                                                <textarea type="text" formControlName="description"
                                                                    class="form-control"
                                                                    placeholder="Enter category description">
                                                            </textarea>
                                                            </div>
                                                            <div class="mt-1
                                                            form-group">
                                                                <label for="meta_keywords" class="form-label">
                                                                    Meta Keywords
                                                                </label>
                                                                <textarea type="text" formControlName="meta_keywords"
                                                                    class="form-control"
                                                                    placeholder="Enter meta Keywords">
                                                                </textarea>
                                                            </div>
                                                            <div class="mt-1
                                                            form-group">
                                                                <label for="meta_keywords" class="form-label">
                                                                    Meta Description
                                                                </label>
                                                                <textarea type="text" formControlName="meta_description"
                                                                    class="form-control"
                                                                    placeholder="Enter meta description">
                                                            </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-10 offset-1 mt-3 text-end">
                                        <button (click)="step2()" class="btn
                                        btn-primary btn-sm pull-right" type="submit" matStepperNext>
                                            Next
                                        </button>
                                        <button class="btn btn-secondary btn-sm
                                        pull-right me-1" type="button" matStepperPrevious>
                                            Previous
                                        </button>
                                    </div>
                                </mat-step>



                                <mat-step [stepControl]="third" label="Category Options">
                                    <div class="setup-content" formGroupName="third" id="step-3">
                                        <div class="col-6 offset-3">
                                            <h5 class="title text-center py-3">Visibility</h5>
                                        </div>

                                        <div class="row mt-1">
                                            <div class="col-9 offset-2">
                                                <div class="media row">
                                                    <label class="col-form-label
                                                    col-5">Enable Category</label>
                                                    <div class="media-body col-5">
                                                        <label class="switch">
                                                            <input type="checkbox" checked
                                                                formControlName="enable_category" required />
                                                            <span class="switch-state"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="media row">
                                                    <label class="col-form-label
                                                    col-5">
                                                        Visibility In Front Menu
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="switch">
                                                            <input type="checkbox" checked
                                                                formControlName="visible_front" required />
                                                            <span class="switch-state"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="media row">
                                                    <label class="col-form-label
                                                    col-5">
                                                        Visibility In Seller Menu
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="switch">
                                                            <input type="checkbox" checked
                                                                formControlName="visible_seller" required />
                                                            <span class="switch-state"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="media row">
                                                    <label class="col-form-label
                                                    col-5">
                                                        Restricted Seller Menu
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="switch">
                                                            <input type="checkbox" checked
                                                                formControlName="restricted_seller" required />
                                                            <span class="switch-state"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div ngbDropdown class="mt-1
                                                form-group media row">
                                                    <label for="seller_access" class="col-form-label
                                                    col-5">
                                                        Restricted Seller Menu
                                                        Access
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="form-label col-12">
                                                            <ng-select [items]="filters" class="custom me-1"
                                                                bindValue="id" placeholder="Select" [clearable]="false"
                                                                bindLabel="name"
                                                                formControlName="seller_access"></ng-select>
                                                        </label>
                                                    </div>
                                                    <div class="media-body text-end">
                                                        <button class="btn
                                                        btn-primary" type="submit">
                                                            Add Seller Group
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="mt-1 form-group media
                                                row">
                                                    <label for="position" class="col-form-label
                                                    col-5">
                                                        Position
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="form-label pe-2">
                                                            <input type="Number" formControlName="position"
                                                                class="form-control" placeholder="Number" required />
                                                        </label>
                                                    </div>
                                                </div>


                                                <div ngbDropdown class="mt-1
                                                form-group media row">
                                                    <label for="layout_id" class="col-form-label
                                                    col-5">
                                                        Select Design Set
                                                    </label>
                                                    <div class="media-body col-5">
                                                        <label class="form-label col-12">
                                                            <ng-select [items]="layoutsList" class="custom me-1"
                                                                dropdownPosition="top" bindValue="id"
                                                                placeholder="Select" [clearable]="false"
                                                                bindLabel="name"
                                                                formControlName="layout_id"></ng-select>
                                                        </label>
                                                    </div>
                                                    <div class="media-body">

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-10 offset-1 mt-3 text-end py-3">
                                        <button (click)="step3()" class="btn
                                        btn-primary btn-sm pull-right" type="submit" matStepperNext>
                                            Next
                                        </button>
                                        <button class="btn btn-secondary btn-sm
                                        pull-right me-1" type="button" matStepperPrevious>
                                            Previous
                                        </button>
                                    </div>
                                </mat-step>



                                <mat-step [stepControl]="fourth" label="Attributes">
                                    <div class="card-body" formGroupName="fourth" id="step-4">
                                        <div class="col-6 offset-3">
                                            <h5 class="title text-center py-3">Attribute
                                                Mapping</h5>
                                        </div>

                                        <div class="row vh-100">
                                            <div class="col-sm-3 box-col-6
                                            offset-1">
                                                <div class="mt-1
                                                form-group">
                                                    <label for="attribute_set_id" class="form-label">
                                                        Select Attribute Set
                                                    </label>
                                                    <ng-select [items]="attrubitesList" class="custom" bindValue="id"
                                                        placeholder="Select" [clearable]="false" bindLabel="name"
                                                        formControlName="attribute_set_id"></ng-select>
                                                </div>

                                            </div>

                                            <div class="col-sm-6 box-col-6 ps-5">
                                                <div class="mt-1
                                                form-group">
                                                    <label for="filter_list" class="form-label">
                                                        Select Filterable from the
                                                        selected Attribute Set
                                                    </label>
                                                    <ng-select [items]="filters" [multiple]="true" bindLabel="name"
                                                        bindValue="id" [multiple]="true" [closeOnSelect]="false"
                                                        [clearable]="false" placeholder="Select Filterable Attribute"
                                                        formControlName="filter_list"></ng-select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-10 offset-1 mt-3 text-end">
                                        <button (click)="step4()" class="btn
                                        btn-primary btn-sm pull-right" type="submit" matStepperNext>
                                            Next
                                        </button>
                                        <button class="btn btn-secondary btn-sm
                                        pull-right me-1" type="button" matStepperPrevious>
                                            Previous
                                        </button>
                                    </div>
                                </mat-step>



                                <mat-step [stepControl]="fifth" label="Commission">
                                    <div class="setup-content" formGroupName="fifth" id="step-5">
                                        <div class="col-6 offset-3">
                                            <h5 class="title text-center py-3">Commission</h5>
                                        </div>

                                        <div class="col-6 offset-3 text-center">
                                            <h6 class="title py-2">
                                                Select Category Commission Option
                                            </h6>
                                        </div>

                                        <div class="row offset-3">
                                            <button class="btn btn-primary col-4 me-3">
                                                Categories Commission
                                            </button>
                                            <button class="btn btn-light col-4">
                                                Categories Commission Price Range
                                            </button>
                                        </div>

                                        <div class="row pb-2 pt-3 offset-1">
                                            <div class="col-sm-3 box-col-6">
                                                <div ngbDropdown class="mt-1
                                                form-group">
                                                    <label for="commission" class="form-label">
                                                        Category Commission
                                                    </label>
                                                    <div class="input-group">
                                                        <input class="form-control" type="Number"
                                                            formControlName="commission" />
                                                        <span class="input-group-text text-primary">%</span>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>

                                    <div class="col-10 offset-1 mt-3 text-end">
                                        <button (click)="step5()" class="btn
                                        btn-primary btn-sm pull-right" type="submit" matStepperNext>
                                            Next
                                        </button>
                                        <button class="btn btn-secondary btn-sm
                                        pull-right me-1" type="button" matStepperPrevious>
                                            Previous
                                        </button>
                                    </div>
                                </mat-step>



                                <mat-step [stepControl]="sixth" label="Images">
                                    <div class="setup-content" formGroupName="sixth" id="step-6 ">
                                        <div class="row">
                                            <h5 class="title text-center py-3">Images
                                                and Banners</h5>
                                        </div>



                                        <div class="container-fluid">
                                            <div class="row dropzone-custom">
                                                <div class="col-sm-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5>Category Menu</h5>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="image-container">
                                                                <app-image-upload (images)="onSelect($event)"
                                                                    formArrayName="thumb" required>
                                                                </app-image-upload>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-sm-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h5>Category banner</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="image-container">
                                                        <app-image-upload (images1)="onSelect1($event)"
                                                            class="f-left m-1" *ngFor="let items of banners_list"
                                                            formArrayName="banners" required>
                                                            {{items}}
                                                        </app-image-upload>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-sm-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h5>Product Listing Image</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="image-container">
                                                        <app-image-upload (images2)="onSelect2($event)"
                                                            formArrayName="products" required>
                                                        </app-image-upload>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-10 offset-1 mt-3 text-end">
                                        <button (click)="step6()" class="btn
                                        btn-primary btn-sm pull-right" type="submit">
                                            Submit
                                        </button>
                                        <button class="btn btn-secondary btn-sm
                                        pull-right me-1" type="button" matStepperPrevious>
                                            Previous
                                        </button>
                                    </div>
                                </mat-step>
                            </mat-stepper>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>