// import { Component } from "@angular/core";
// import {
//   FormArray,
//   FormBuilder,
//   FormControl,
//   FormGroup,
//   Validators,
// } from "@angular/forms";
// import { ToastrService } from "ngx-toastr";
// import { environment } from "src/environments/environment";
// import { AttributeServices } from "../services/attribute-services";
// import { Attribute } from "../services/attribute-modal";
// import { Data } from "../attribute-params";

// @Component({
//   selector: "app-attribute-create",
//   templateUrl: "./attribute-create.component.html",
//   styleUrls: ["./attribute-create.component.scss"],
// })
// export class AttributeCreateComponent {
//   submitted: boolean = false;
//   languages: Array<any> = environment.languages;
//   attributeForm: FormGroup;
//   translationForm: FormGroup;
//   visible: boolean = true;
//   allowDependent: boolean = true;
//   errors: any = [];
//   msg: any = [];
//   attributeTypes = Data.attributeTypes;
//   inputTypes = Data.inputTypes;
//   dependent_attributes = [
//     // {
//     //   id: 0,
//     //   type: 'Select'
//     // },
//     {
//       id: 1,
//       type: "Weight",
//     },
//     {
//       id: 2,
//       type: "Ram Unit",
//     },
//   ];

//   dependents = [
//     { id: 1, type: "No", value: 0 },
//     { id: 2, type: "Yes", value: 1 },
//   ];

//   allow_dependent: string;

//   selectedType: number = 1;
//   public activePillIndex: number = 0;

//   constructor(
//     private fb: FormBuilder,
//     private attibute: AttributeServices,
//     private toast: ToastrService
//   ) { }

//   ngOnInit(): void {
//     this.attributeForm = this.fb.group({
//       mandatory: [false, [Validators.required]],
//       type_id: ["", Validators.required],
//       code: ["", [Validators.required]],
//       allow_dependent: [null, [Validators.required]],
//       dependent_attribute: [null],
//       default_value: [null],
//       input_type: [null, [Validators.required]],
//       is_filterable: [0, [Validators.required]],
//       is_searchable: [0, [Validators.required]],
//       is_comparable: [0, [Validators.required]],
//       is_user_defined: [0, [Validators.required]],
//       status: [1, [Validators.required]],
//       translations: this.fb.array([]),
//       options: this.fb.array([]),
//     });

//     this.addTranslation();
//     this.addOption();
//   }

//   get f() {
//     return this.attributeForm.controls;
//   }

//   onclick() {
//     this.visible = !this.visible;
//   }

//   /* Translation Section */
//   translationFormGroup(): FormGroup {
//     return this.fb.group({
//       language_id: [],
//       name: ["", [Validators.required]],
//       tooltip_text: [null],
//       place_holder_text: [null],
//       error_message: [null],
//     });
//   }

//   optionsFormGroup(): FormGroup {
//     return this.fb.group({
//       default: [],
//       aot: this.fb.array([])
//     });
//   }

//   optionTranslationFormGroup(): FormGroup {
//     return this.fb.group({
//       lang_id: [],
//       lang_code: ["", [Validators.required]],
//       value: ["", [Validators.required]],
//     });
//   }

//   get translations(): FormArray {
//     return this.attributeForm.get("translations") as FormArray;
//   }

//   get translationsFrom() {
//     return this.attributeForm.get("translations");
//   }

//   get optionsForm(): FormArray {
//     return this.attributeForm.get("options") as FormArray;
//   }

//   get optionTranslations(): FormArray {
//     return this.optionsForm.get("aot") as FormArray;
//   }


//   get optionsTranslationForm() {
//     return this.attributeForm.get("options.aot");
//   }

//   addTranslation() {
//     this.languages.forEach((lang) => {
//       this.translations.push(this.translationFormGroup());
//     });
//   }

//   addOption() {
//     this.optionsForm.push(this.optionsFormGroup());
//     this.addOptionTranslation();
//   }

//   addOptionTranslation() {
//     this.languages.forEach((lang) => {
//       this.optionTranslations.push(this.optionTranslationFormGroup());
//     })
//   }

//   changeAttributeType(event) {
//     // console.log(event);
//     // console.log(event.id);
//     //  this.selectedType = event.id;
//     this.selectedType = event.target.value;
//     console.log(event.target.value);
//   }

//   async save() {
//     this.submitted = true;
//     this.errors = [];
//     //  if (this.attributeForm.status == 'VALID') {
//     //console.log(this.attributeForm.value);
//     const postData: Attribute = this.attributeForm.value;
//     console.log(postData);
//     const response = await this.attibute
//       .create(postData)
//       .subscribe((res: any) => {
//         console.log(res);
//         if (res.status == true) {
//           //  this.attributeForm.reset();
//           this.toast.success("Attribute has been added successfully");
//           return res.data;
//         } else {
//           this.errors = res.errors;
//           return res.errors;
//         }
//       });
//     // }
//   }


//   removeTranslation(index: number) {
//     this.optionsForm.removeAt(index);
//   }

//   cancel() {
//     this.attributeForm.reset();
//   }

//   selectLanguage(index) {
//     this.activePillIndex = index;
//   }

//   setValue() {
//     this.allow_dependent = this.attributeForm.get("allow_dependent")?.value;
//     console.log(this.allow_dependent);
//   }

//   // dependentStatus(event) {
//   //   let value = event.target.value;
//   //   if (value == 0) {
//   //     this.allowDependent = true;
//   //   } else {
//   //     this.allowDependent = false;
//   //   }
//   // }
// }

import { Attribute, Component, OnInit } from "@angular/core";
import { Data } from "../attribute-params";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AttributeServices } from "../services/attribute-services";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-attribute-create",
  templateUrl: "./attribute-create.component.html",
  styleUrls: ["./attribute-create.component.scss"],
})

export class AttributeCreateComponent implements OnInit {

  errors: any = [];
  msg: any = [];
  selectedType: number = 1;
  attributeTypes = Data.attributeTypes;
  dependents = [{ id: 1, type: 'No', value: 0 }, { id: 2, type: 'Yes', value: 1 }];
  allow_dependent: string;
  attributeForm: FormGroup;
  dependent_attributes = [{ id: 1, type: 'Weight' }, { id: 2, type: 'Ram Unit' }];
  inputTypes = Data.inputTypes;
  languages: Array<any> = environment.languages;
  submitted: boolean = false;
  public activePillIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private attributeService: AttributeServices,
    private toast: ToastrService
  ) {
    this.attributeForm = this.fb.group({
      attributes: this.fb.array([]),
    });
  }

  get f() {
    return this.attributeForm.controls;
  }

  async ngOnInit() {
    this.attributeForm.setControl('attributes', this.createAttributeFormGroup());
  }

  ngAfterViewInit() {
    console.log('after view init');
  }

  createTranslationFormGroup(translation: any) {
    return this.fb.group({
      language_id: [translation.language_id],
      name: ['', [Validators.required]],
      tooltip_text: [''],
      place_holder_text: [''],
      error_message: ['']
    });
  }

  createOptionFormGroup() {
    return this.fb.group({
      default: [''],
      aot: this.fb.array(this.loadOptionTranslationArray())
    });
  }

  optionTranslationFormGroup(translation: any) {
    return this.fb.group({
      lang_id: [translation.id],
      lang_code: [translation.code],
      value: ['']
    })
  }

  loadTranslationsArray() {
    let _translations = this.languages.map((transalation: any) => this.createTranslationFormGroup(transalation));
    return _translations;
  }

  loadOptionTranslationArray() {
    let _translations = this.languages.map((transalation: any) => this.optionTranslationFormGroup(transalation));
    return _translations;
  }


  createAttributeFormGroup() {
    return this.fb.group({
      mandatory: [false, [Validators.required]],
      type_id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      allow_dependent: ['', [Validators.required]],
      dependent_attribute: [''],
      default_value: [''],
      input_type: ['', [Validators.required]],
      is_filterable: ['', [Validators.required]],
      is_searchable: ['', [Validators.required]],
      is_comparable: ['', [Validators.required]],
      is_user_defined: ['', [Validators.required]],
      status: ['', [Validators.required]],
      translations: this.fb.array(this.loadTranslationsArray()),
      options: this.fb.array([]),
    });
  }

  get optionsForm(): FormArray {
    return this.attributeForm.get('attributes.options') as FormArray;
  }

  get translations(): FormArray {
    return this.attributeForm.get('attributes.translations') as FormArray;
  }

  changeAttributeType(event) {
    this.selectedType = event.target.value;
    //  console.log(event.target.value);
    if (this.selectedType == 3 || this.selectedType == 5) {
      this.addOption();
    }
  }

  setValue() {
    this.allow_dependent = this.attributeForm.get("allow_dependent")?.value;
    // console.log(this.allow_dependent);
  }

  removeOptionTranslation(index: number) {
    this.optionsForm.removeAt(index);
  }


  addOption() {
    this.optionsForm.push(this.createOptionFormGroup());
  }

  selectLanguage(index) {
    this.activePillIndex = index;
  }

  cancel() {
    this.attributeForm.reset();
  }

  removeTranslation(index: number) {
    this.optionsForm.removeAt(index);
  }

  async save() {
    this.submitted = true;
    this.errors = [];
    console.log(this.attributeForm.value.attributes)
    //  if (this.attributeForm.status == 'VALID') {
    //console.log(this.attributeForm.value);
    //const postData: Attribute = this.attributeForm.value;
    const postData = {
      mandatory: this.attributeForm.value.attributes.is_mandatory,
      type_id: this.attributeForm.value.attributes.type_id,
      code: this.attributeForm.value.attributes.code,
      allow_dependent: this.attributeForm.value.attributes.allow_dependent,
      dependent_attribute: this.attributeForm.value.attributes.dependent_attribute,
      default_value: this.attributeForm.value.attributes.defaultValue,
      input_type: this.attributeForm.value.attributes.input_type,
      is_filterable: this.attributeForm.value.attributes.is_filterable,
      is_searchable: this.attributeForm.value.attributes.is_searchable,
      is_comparable: this.attributeForm.value.attributes.is_comparable,
      is_user_defined: this.attributeForm.value.attributes.is_user_defined,
      status: this.attributeForm.value.attributes.status || 1,
      translations: this.translations.value,
      options: this.optionsForm.value,
    }
    console.log(postData);
    const response = await this.attributeService
      .create(postData)
      .subscribe((res: any) => {
        console.log(res);
        if (res.status == true) {
          //  this.attributeForm.reset();
          this.toast.success("Attribute has been added successfully");
          return res.data;
        } else {
          this.errors = res.errors;
          return res.errors;
        }
      });
    // }
  }

}