import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ServerResponseState } from 'src/app/core/modals/response-type';

@Injectable({
    providedIn: 'root'
})
export class AttributeServices {

    constructor(
        private http: HttpClient,
    ) { }

    public create(post) {
        return this.http
            .post<ServerResponseState>(`${environment.apiUrl}/attribute/create`, post)
            .pipe(
                map((res:ServerResponseState) => {
                    console.log(res);
                    if (res.statusCode == 201 && res.status == 'success') {
                        return { status: true, data: res.data };
                    } else if (res.statusCode == 201 && res.status == 'failed') {
                        return { status: false, errors: res.error, message: res.message };
                    } else {
                        console.log(res);
                    }
                }),
                catchError(this.handleError)
            );
    }

    findOne(id: number) {
        return this.http.get<ServerResponseState>(`${environment.apiUrl}/attribute/${id}`).pipe(
          map((res: any) => {
            console.log(res);
            if (res.statusCode == 200 && res.status == 'success') {
              return { status: true, data: res.data };
            } else if (res.statusCode == 200 && res.status == 'failed') {
              return { status: false, errors: res.error, message: res.message };
            } else {
              console.log(res);
            }
          }),
          catchError(this.handleError)
        );
      }

    findAll(params) {
        return this.http.get<ServerResponseState>(`${environment.apiUrl}/attribute/all`, { params: params }).pipe(
            map((res: any) => {
                if (res.statusCode == 200 && res.status == 'success') {
                    return { status: true, data: res.data };
                } else if (res.statusCode == 200 && res.status == 'failed') {
                    return { status: false, errors: res.error, message: res.message };
                } else {
                    console.log(res);
                }
            }),
            catchError(this.handleError)
        );
    }

    update(post) {
        return this.http.post<ServerResponseState>(`${environment.apiUrl}/user/update`, post).pipe(
          map((res: any) => {
            console.log(res);
            if (res.statusCode == 201 && res.status == 'success') {
              return { status: true, data: res.data };
            } else if (res.statusCode == 201 && res.status == 'failed') {
              return { status: false, errors: res.error, message: res.message };
            } else {
              console.log(res);
            }
          }),
          catchError(this.handleError)
        );
      }
    

    delete() {

    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }
}
