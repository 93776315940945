import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CategoryComponent } from '../components/category/category.component';

@Component({
  selector: 'app-brand-create',
  templateUrl: './brand-create.component.html',
  styleUrls: ['./brand-create.component.scss']
})
export class BrandCreateComponent {

  masterSelected:boolean;
  brandCreateForm: FormGroup;
  submitted: boolean = false;
  selectedBrands:string[];
  public activePillIndex: number = 0;

  files: File[] = [];
  files1: File[] = [];
  files2: File[] = [];

 brandTypes =[
    {id:'1', label: 'Coporate brands', value: 'Coporate brands' },
    {id:'2', label: 'Personal brands', value: 'Personal brands' },
    {id:'3', label: 'Products brands', value: 'Products brands' },
    {id:'4', label: 'Service brands', value: 'Service brands' }
];
 
brandOrgin= [
  { id:'1', label: 'India', value: 'India' },
  { id:'2', label: 'USA', value: 'USA' },
  { id:'3', label: 'China', value: 'China' }
];
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal) {
  }
  ngOnInit(): void {
    this.brandCreateForm = this.fb.group({
      brandName: ['', [Validators.required]],
      catagory: ['', [Validators.required]],
      brandWebsiteLink: ['', [Validators.required]],
      brandLogo: ['', [Validators.required]],
      registrant: ['', [Validators.required]],
      // productImage: ['', [Validators.required]],
      // status: ['', [Validators.required]],
      brandType: ['', [Validators.required]],
      brandOrgin: ['', [Validators.required]],
      
    });


  }
  public selectPill(index: number) {
    this.activePillIndex = index;
    console.log(this.activePillIndex);
  }

get selectedOptions(){
  return this.brandCreateForm.get('brandTypeDropdown').value;
}
  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) {
      this.replaceFile();
    }
  }
  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.files1.splice(this.files1.indexOf(event), 1);
    this.files2.splice(this.files2.indexOf(event), 1);
  }

  onSelect1(event) {
    this.files1.push(...event.addedFiles);
  }
  onSelect2(event) {
    this.files2.push(...event.addedFiles);
  }


  onsubmit() {
    this.submitted = true;
    console.log(this.brandCreateForm.value)
    if (!this.brandCreateForm.valid) {

    } else {
      return console.log(this.brandCreateForm.value);
    }
  }
  get f() {
    return this.brandCreateForm.controls;
  }

  loadCategoryModal() {
    const modalRes = this.modalService.open(CategoryComponent, { size: 'lg' })
  }
}
