import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandListComponent } from './brand-list/brand-list.component';
import { BrandEditComponent } from './brand-edit/brand-edit.component';
import { BrandCreateComponent } from './brand-create/brand-create.component';
import { RouterModule, Routes } from '@angular/router';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CategroyServices } from "../brand/services/category-service";
import { CategoryComponent } from './components/category/category.component';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  {
    path: '',
    component: BrandListComponent,  
  },
  { 
    path: 'create',
    component: BrandCreateComponent,
  },
  {
    path: 'edit',
    component: BrandEditComponent,
  },
];



@NgModule({
  declarations: [
    BrandListComponent,
    BrandEditComponent,
    BrandCreateComponent,
    CategoryComponent,

    
  ],
  imports: [
    CommonModule,
    ThemeModule,
    ReactiveFormsModule,
    NgSelectModule,

    RouterModule.forChild(routes)
  ],
  providers: [
    CategroyServices
  ]

})
export class BrandModule { }
