import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { ServerResponseState } from "../../../core/modals/response-type";
import { Observable, throwError } from "rxjs";
import { IServerSideDatasource } from "ag-grid-community";

@Injectable({
  providedIn: "root",
})
export class RolesService {

  public errorMsg: string;
  constructor(private http: HttpClient) { }

  public create(post) {
    return this.http.post<ServerResponseState>(`${environment.apiUrl}/roles/create`, post)
      .pipe(
        map((res: ServerResponseState) => {
          console.log(res);
          if (res.statusCode == 201 && res.status == 'success') {
            return { status: true, data: res.data };
          } else if (res.statusCode == 201 && res.status == 'failed') {
            return { status: false, errors: res.error, message: res.message };
          } else {
            console.log(res);
          }
        }),
        catchError(this.handleError)
      );
  }

  findAll(params) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/roles/list`, { params: params }).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  getAll(){
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/roles/list`).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

 
  update(post) { 
     return this.http.post<ServerResponseState>(`${environment.apiUrl}/roles/update`, post).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 201 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 201 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  delete(data) { 
    return this.http.post<ServerResponseState>(`${environment.apiUrl}/roles/delete`, data).pipe(
      map((res: any) => {
        console.log(res);
        if (res.statusCode == 201 && res.status == 'success') {
          return { status: true, data: res.data, message: res.message};
        } else if (res.statusCode == 201 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else {
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );

  }

  deleteAll(data) { }

  findOne(id: number) {
    return this.http.get<ServerResponseState>(`${environment.apiUrl}/roles/${id}`).pipe(
      map((res: ServerResponseState) => {
        console.log(res);
        if (res.statusCode == 200 && res.status == 'success') {
          return { status: true, data: res.data };
        } else if (res.statusCode == 200 && res.status == 'failed') {
          return { status: false, errors: res.error, message: res.message };
        } else{
          console.log(res);
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 400) {
      return error.error.message;
    }
    return throwError(error);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }

      case 403: {
        return `Access Denied: ${error.message}`;
      }

      case 500: {
        return `Internal Server Error: ${error.message}`;
      }

      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
