import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../../src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsRoutingModule } from './cms-routing.module';
import { CreateModule } from './create-modules/create-folder.module'

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    CmsRoutingModule,
    CreateModule,
    ReactiveFormsModule,
    // NgxDropzoneModule,
    // NgSelectModule,
    ThemeModule,

  ]
})
export class CmsModule { }
