import { AuthenticationService } from '../services/authentication.service';
import { Component } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from '../../shared/validators/custom_validator'
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Store } from '@ngrx/store';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  passwordShow: boolean = false;
  public submitted: boolean = false;
  loginForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private _toastrService: ToastrService,
    private route: Router,
    private storage: StorageService,
    private loginService: AuthenticationService) {
  }
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      emailphone: [null, [Validators.required, CustomValidators.emailPhone]],
      password: [null, [
        Validators.required,
        /* Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ), */
        Validators.minLength(8),
      ]],
      remember: [false, Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }
  onsubmit() {
    if (this.loginForm.status !== 'INVALID') {
      this.submitted = true;
      this._toastrService.clear();
      this._toastrService.info('Please Wait')
      const user = {
        username: this.loginForm.get('emailphone').value,
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('remember').value,
        trust_device: true,
        app_token: this.storage.get('uuid'),
        login_attampt: 1,
        browser_data: []
      };
      this.loginService.login(user).subscribe((res: any) => {
        console.log(res);
        if (res.status == true) {
          this._toastrService.clear();
          this._toastrService.success('Login Successfully');
          this.route.navigateByUrl('/dashboard');
        }else {
          this._toastrService.clear();
          this._toastrService.error(res.errors);
        }
      });
    } else {
      this._toastrService.error('Please Check Inputs');
    }
  }

  showPassword() {
    this.passwordShow = !this.passwordShow
  }

}
