<div class="container-fluid">
    <div class="row mb-0">
        <form [formGroup]="myForm">

            <div class="row ">
                <div class="col-md-8 mt-4">
                    <div class="card mt-3 p-0 mx-0">
                        <div class="card-body">
                            <h5>Add New Pages</h5>
                            <hr>

                            <div class="mb-2 col-12">
                                <label>Title</label>
                                <input class="form-control" id="name" type="text" placeholder="Enter  Title"
                                    formControlName="title" onlyAlphabets />
                                <div class="errors mb-0" *ngIf="submitted && myForm.get('title').errors">
                                    <p class="text-danger mb-0" *ngIf="myForm.get('title').errors.required">
                                        Title name is required
                                    </p>
                                </div>
                            </div>

                            <div class="mb-2 col-12 pe-0">
                                <div class="form-group">
                                    <label>Name</label>

                                    <input class="form-control" id="code" type="text" placeholder="Enter Name"
                                        formControlName="name" onlyAlphabets />

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('name').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('name').errors.required">
                                            Name is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 col-12 pe-0">
                                <div class="form-group">
                                    <label>Keywords</label>
                                    <textarea style="min-height: 300px; min-width:100%"></textarea>
                                    <!-- <input class="form-control" id="phone" type="text" placeholder="Enter Keywords"
                                        formControlName="keywords" onlyAlphabets />-->
                                </div>
                                <div class="errors mb-0" *ngIf="submitted && myForm.get('keywords').errors">
                                    <p class="text-danger mb-0" *ngIf="myForm.get('keywords').errors.required">
                                        Keyword is required
                                    </p>
                                </div>
                            </div>
                            <div class="mb-2 col-12  pe-0">
                                <div class="form-group">
                                    <label>Short Description</label> <br>
                                    <textarea style="min-height: 300px; min-width:100%"></textarea>

                                    <!-- <div class=" errors mb-0"
                                        *ngIf="submitted && myForm.get('sortDescription').errors">
                                        <p class="text-danger mb-0"
                                            *ngIf="myForm.get('sortDescription').errors.required">
                                            Sort Description is required
                                        </p>
                                    </div> -->
                                </div>
                            </div>


                            <div class="mb-2 col-12  pe-0">
                                <div class="form-group">


                                    <label> Description</label> <br>


                                    <ckeditor class="form-control" [editor]="editor" [config]="config" [data]="data"
                                        formControlName="sortDescription" style="min-height: 300px;"></ckeditor>

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('sortDescription').errors">
                                        <p class="text-danger mb-0"
                                            *ngIf="myForm.get('sortDescription').errors.required">
                                            Sort Description is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-4">
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="mb-2 col-12  pe-0">
                                <div class="form-group">
                                    <label>Parent</label>
                                    <select class="form-select form-select-md text-dark"
                                        aria-label=".form-select-md example" formControlName="parent">
                                        <option disabled selected value="" class="text-muted">
                                            select
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div class="errors mb-0" *ngIf="submitted && myForm.get('parent').errors">
                                    <p class="text-danger mb-0" *ngIf="myForm.get('parent').errors.required">
                                        Parent is required
                                    </p>
                                </div>
                            </div>
                            <div class="mb-2 col-12  pe-0">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-select form-select-md text-dark"
                                        aria-label=".form-select-md example" formControlName="status">
                                        <option disabled selected value="" class="text-muted">
                                            select
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div class="errors mb-0" *ngIf="submitted && myForm.get('status').errors">
                                    <p class="text-danger mb-0" *ngIf="myForm.get('status').errors.required">
                                        Status is required
                                    </p>
                                </div>
                            </div>


                            <div class="mb-2 col-12  pe-0">
                                <div class="form-group">
                                    <label for="imageInput">Images:</label>
                                    <input type="file" class="form-control" id="imageInput" multiple
                                        formControlName="image" />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('image').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('image').errors.required">
                                            Image is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 text-end">

                            <a class="btn btn-light font-primary ms-2"> Cancel</a>
                            <a class="btn btn-primary  ms-2" (click)="onSubmit()">Save</a>






                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>