import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Brands } from '../brand-data';
// import { Observable } from 'rxjs';
// import { NgbdSortableHeader, SortEvent } from 'src/app/theme/directives/NgbdSortableHeader';
// import { COMPANYDB, CompanyDB } from 'src/app/theme/data/tables/company';
// import { TableService } from 'src/app/theme/services/table.service';


@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent {
  [x: string]: any;
  types = Brands.types
  categories: any[] = [];
  public brands = Brands.BrandDatas;
  public showAllCategories = new Array(this.brands.length).fill(false);
  brandlistForm: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {

    this.brandlistForm = this.fb.group({
      brandName: [null, Validators.required],
      catagory: [null, Validators.required],
      brandWebsiteLink: [null, Validators.required],
      brandLogo: [null, Validators.required],
      control: [null]
    });

    Brands.BrandDatas.forEach((brand) => {
      brand.categroies.forEach((category) => {
        category.childs.forEach((child) => {
          this.categories.push({
            category: category.parent + ' > ' + child
          });
        });
      });
      this.showAllCategories.push(false);
    });
  }
  toggleShowAllCategories(index: number) {
    this.showAllCategories[index] = !this.showAllCategories[index];
  }

  onSearch() {

  }

}


