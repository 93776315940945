import { map } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CategoryServices } from '../services/category-service';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, AfterViewInit {

  searchForm: FormGroup;
  category: any = [];
  errors: any = [];
  parents: any = [];
  categoryLevels: any = [];
  parentData: any = [];
  displayedColumns = ["name", "level", "value", "parent_id", "action"];
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/',
  };

  status = [{ id: 0, name: "Deleted" }, { id: 1, name: "Active" }, { id: 2, name: "Inactive" }];
  sortBy = [{ value: "id:ASC", name: "Ascending Order" }, { value: "id:DESC", name: "Descending Order" }]

  constructor(private fb: FormBuilder,
    private categories: CategoryServices,
    private toast: ToastrService,
    private router: Router,
  ) {

  }


  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.searchForm = this.fb.group({
      category_search: [''],
      status: [null],
      sort: [null]
    });
  }

  ngAfterViewInit() {
    this.getCategoryList();
  }

  async getCategoryList() {
    await this.categories.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        let _category = res.data.data;
        if (_category.length > 0) {
          this.category = _category.map((cat: any) => {
            return {
              id: cat.id,
              name: cat?.translation[0]?.name,
              parents: this.getSelectedPath(cat?.parents, cat?.translation[0]?.name),
              level: cat.level,
              commission: cat?.commission?.value ?? 0,
              parent_id: cat?.parent_id
            }
          });
        }
        //this.category = res.data.data;
        this.paginationMeta = res.data.meta;
      }
    });
  }


  getSelectedPath(parents, category): string {
    let _parents = (parents != 'null') ? JSON.parse(parents) : [];
    let path = [];
    if (parents != 'null') {
      _parents.forEach((parent: any) => {
        path.push(parent.name)
      });
    }
    path.push(category);
    return (path.length == 0) ? '' : path.join(' > ');
  }

  getServerData(event) {
    console.log(this.pagination);
    //console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getCategoryList();
  }

  async onSelect(id: number) {
    const postData = {
      id: id,
      status: 0,
    };
    const response = await this.categories.delete(postData).subscribe((res: any) => {
      //console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.category = this.category.filter(user => user.id !== id);
        //console.log(this.category);
        return this.router.navigate(['/catalog/category']);
      } else {
        this.toast.error(res.errors);
        //console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }

  onSearch() {
    this.pagination.search = this.searchForm.value.category_search || '';
    this.pagination.sortBy = this.searchForm.value.sort || ["id:DESC"];
    if (this.searchForm.value.status) {
      this.pagination.filter.status = '$EQ:' + this.searchForm.value.status;
    }
    this.getCategoryList();
  }

  onClear() {
    this.searchForm.reset();
    this.pagination.search = '';
    this.getCategoryList();
  }
}
