// import { ValidatePassword } from '../../shared/validator/validate-password';
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-loginotp",
  templateUrl: "./loginotp.component.html",
  styleUrls: ["./loginotp.component.scss"],
})
export class LoginotpComponent {
  signupForm:FormGroup;
  public submitted = false;
  public error = "";
  emailButtonEnabled = false;
  emailOtpField = false;
  emailVerfiy = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      emailGroup: this.fb.group({
        mail: [
          null,
          [
            Validators.required,
            Validators.pattern(
              /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
            ),
            // Validators.pattern('^[0-9]+$')
          ],
        ],
        emailOtp: [
          null,
          [
            Validators.required,
            Validators.maxLength(6),
            Validators.pattern("^[0-9]+$"),
          ],
        ],
      }),
    });
    console.log();
  }

  get f() {
    return this.signupForm.controls;
  }

  onsubmit() {
    this.submitted = true;
    if (!this.signupForm.valid) {
      // alert('Please fill all the required fields to create a super hero!');
      return false;
    } else {
      return console.log(this.signupForm.value);
    }
  }

  validateEmail() {
    let email = this.signupForm.get("emailGroup.mail");
    if (email?.errors == null) {
      console.log(email?.value);
      this.emailButtonEnabled = true;
    } else {
      this.emailButtonEnabled = false;
    }
  }

  sendEmailOtp() {
    let email = this.signupForm.get("emailGroup.mail");
    if (email?.errors == null) {
      console.log(email?.value);
      this.emailOtpField = true;
    } else {
      this.emailOtpField = false;
    }
  }

  verfiyEmailOtp() { }
}
