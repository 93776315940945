<div class="container-fluid pt-3">
    <h4>Create Band</h4>
    <div class="row">
        <div class="col-sm-9">
            <div class="card ">
                <div class="card-body">
                    <form [formGroup]="brandCreateForm">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label for=" brandName" class="col-sm-3 col-form-label">
                                        Brand Name<span class="text-danger">*</span></label>
                                    <div class="col-sm-9">
                                        <input class="form-control" type="text" formControlName="brandName" />
                                        <div *ngIf="submitted && f['brandName'].errors" class="errors">
                                            <p *ngIf="f['brandName'].errors['required']" class="text-danger">
                                                Please Enter your brand name
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="catagory" class="col-sm-3 col-form-label">
                                        Catagory<span class="text-danger">*</span></label>
                                    <div class="col-sm-9">
                                        <a class="btn btn-secondary" formControlName="catagory"
                                            (click)="loadCategoryModal()">Select Category</a>
                                        <div *ngIf="submitted && f['catagory'].errors" class="errors">
                                            <p *ngIf="f['catagory'].errors['required']" class="text-danger">
                                                Please enter catagory
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="brandWebsiteLink" class="col-sm-3 col-form-label">
                                        Brand Website URL<span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-9 ">
                                        <div class="input-group">
                                            <span class="input-group-text text-dark">https:www.igeet.com/stores/</span>
                                            <input class="form-control" type="url" formControlName="brandWebsiteLink"
                                                id="brandWebsiteLink" placeholder="" />

                                        </div>
                                        <div *ngIf="submitted && f['brandWebsiteLink'].errors" class="errors">
                                            <p *ngIf="f['brandWebsiteLink'].errors['required']" class="text-danger">
                                                Please enter brand website link
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="brandLogo" class="col-sm-3 col-form-label">
                                        Brand Logo<span class="text-danger">*</span></label>
                                    <div class="col-sm-9">
                                        <input class="form-control text-dark" type="file" formControlName="brandLogo" />
                                        <div *ngIf="submitted && f['brandLogo'].errors" class="errors">
                                            <p *ngIf="f['brandLogo'].errors['required']" class="text-danger">
                                                Please enter brand logo
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="mb-3 row">
                                    <label for="brandLogo" class="col-sm-3 col-form-label">
                                        Registrant</label>

                                    <div class="col-sm-9">
                                        <input class="form-control" type="url" formControlName="registrant"
                                            id="registrant" placeholder="Enter registrant" />

                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="brandType" class="col-sm-3 col-form-label">Brand Type:<span
                                            class="text-danger">*</span></label>

                                    <div class="col-sm-9">
                                        <ng-select [items]="brandTypes" [multiple]="true" [(ngModel)]="selectedBrands"
                                            placeholder="Select brand types" [closeOnSelect]="false" bindValue="id"
                                            formControlName="brandType">
                                        </ng-select>
                                        <div *ngIf="submitted && f['brandType'].errors" class="errors">
                                            <p *ngIf="f['brandType'].errors['required']" class="text-danger">
                                                Please enter brand type
                                            </p>
                                        </div>

                                    </div>
                                </div>


                                <div class="mb-3 row">
                                    <label for="brandOrgin" class="col-sm-3 col-form-label">Brand Orgin<span
                                            class="text-danger">*</span></label>
                                    <div class="col-sm-9">
                                        <ng-select [items]="brandOrgin" [(ngModel)]="selectedBrands" bindValue="id"
                                            placeholder="Select brand orgin" formControlName="brandOrgin">
                                        </ng-select>
                                        <div *ngIf="submitted && f['brandOrgin'].errors" class="errors">
                                            <p *ngIf="f['brandOrgin'].errors['required']" class="text-danger">
                                                Please enter brand orgin
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-3">
                                </div>
                                <div class="text-end">
                                    <button (click)="onsubmit()" class="btn btn-primary align-right" type="button">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>