import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusModule } from './status/status.module'
const routes: Routes = [
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then(m => m.StatusModule)
  }
];

@NgModule({
  imports: [StatusModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuMasterRoutingModule { }
