<div class="container-fluid">

    <div class="row mb-0">
        <form [formGroup]="myForm">
            <div class="card mt-3">
                <div class="card-body">
                    <h5>Create New Article</h5>
                    <hr>
                    <div class="row mt-4">
                        <div class="col-lg-10 mx-auto">
                            <div class="row">
                                <div class="col-12 text-start ">
                                    <p><b>Article</b></p>
                                </div>
                            </div>
                            <div class="row input-group mb-2 ">
                                <div class="col-2 text-end pt-1">
                                    <label>Title<sup class="text-danger">*</sup></label>
                                </div>
                                <div class=" col-10 ">
                                    <input class="form-control" id="name" type="text" placeholder="Enter Title"
                                        formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('name').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('name').errors.required">
                                            Name is required
                                        </p>
                                    </div>
                                </div>
                            </div>





                            <div class="row input-group">

                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Content<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 h-100">

                                    <ckeditor class="form-control pt-4" [editor]="editor" [config]="config"
                                        [data]="data" formControlName="description" h-70></ckeditor>

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('description').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('description').errors.required">
                                            Content is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-start">
                                    <p><b>SEO</b></p>
                                </div>
                            </div>

                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Slug<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="slug" type="text" placeholder="Enter slug"
                                        formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('slug').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('slug').errors.required">
                                            Slug is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Meta Title<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10 ">
                                    <input class="form-control" id="metatittle" type="text"
                                        placeholder="Enter meta tittle" formControlName="name" onlyAlphabets />
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('metatitle').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('metatitle').errors.required">
                                            Meta Title is required
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end  pt-1">
                                    <label>Meta Keywords<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10">
                                    <textarea class="form-control" name="" id="" cols="30" rows="7"
                                        formControlName="metaKeywords"></textarea>

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('metaKeywords').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('metaKeywords').errors.required">
                                            Meta Keywords is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row input-group">

                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Meta Description</label>
                                </div>
                                <div class="mb-2 col-10">
                                    <textarea class="form-control" name="" id="" cols="30" rows="7"></textarea>

                                    <!-- <div class="errors mb-0" *ngIf="submitted && myForm.get('metadescription').errors">
                                        <p class="text-danger mb-0"
                                            *ngIf="myForm.get('metadescription').errors.required">
                                            Meta Description is required
                                        </p>
                                    </div> -->
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 text-start">
                                    <p><b>Settings</b></p>
                                </div>
                            </div>

                            <div class="form-group mb-2">
                                <div class="input-group row">
                                    <div class="mb-2 col-2 text-end pt-1">
                                        <label>Sorting<sup class="text-danger">*</sup></label>
                                    </div>
                                    <div class="mb-2 col-10">
                                        <select class="form-select form-select-md text-dark"
                                            aria-label=".form-select-md example" formControlName="sorting">
                                            <option disabled selected value="" class="text-muted">
                                                Select Sorting
                                            </option>
                                            <option value="1">Ascending Order</option>
                                            <option value="2">Descending Order</option>
                                            <option value="3">Based on Popularity</option>
                                        </select>

                                        <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                Sorting is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2">
                                <div class="input-group row ">
                                    <div class="mb-2 col-2 text-end pt-1">
                                        <label class="col-form-label "> <span class="mb-1">Categories<sup
                                                    class="text-danger"> * </sup></span>

                                        </label>
                                    </div>
                                    <div class="col-10">
                                        <ng-select [items]="categories" [clearable]="false" (click)="setValueCategory()"
                                            class="custom" bindValue="name" bindLabel="name"
                                            formControlName="categories" [multiple]="true"
                                            [closeOnSelect]="false"></ng-select>

                                        <div class="errors mb-0" *ngIf="submitted && myForm.get('categories').errors">
                                            <p class="text-danger mb-0"
                                                *ngIf="myForm.get('categories').errors.required">
                                                Categories is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row input-group">

                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Tag<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="col-10">
                                    <ng-select [items]="filters" [clearable]="false" class="custom" bindValue="name"
                                        bindLabel="name" formControlName="categories" [multiple]="true"
                                        [closeOnSelect]="false"></ng-select>

                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('categories').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('categories').errors.required">
                                            Tag is required
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group mb-2">
                                <div class="input-group row ">
                                    <div class="mb-2 col-2 text-end pt-1">
                                        <label class="col-form-label "> <span class="mb-1">Related Article<sup
                                                    class="text-danger"> * </sup></span>

                                        </label>
                                    </div>
                                    <div class="col-10">
                                        <ng-select [items]="relatedArticle" [clearable]="false" class="custom"
                                            bindValue="name" bindLabel="name" formControlName="relatedArticle"
                                            [multiple]="true" [closeOnSelect]="false"></ng-select>

                                        <div class="errors mb-0"
                                            *ngIf="submitted && myForm.get('relatedArticle').errors">
                                            <p class="text-danger mb-0"
                                                *ngIf="myForm.get('relatedArticle').errors.required">
                                                Related Article is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row input-group">
                                <div class="mb-2 col-2 text-end pt-1">
                                    <label>Status<sup class="text-danger">*</sup></label>
                                </div>
                                <div class="mb-2 col-10">
                                    <select class="form-select form-select-md text-dark"
                                        aria-label=".form-select-md example" formControlName="status">
                                        <option disabled selected value="" class="text-muted">
                                            Select Status
                                        </option>
                                        <option value="1">Draft</option>
                                        <option value="2">Live</option>

                                    </select>
                                    <div class="errors mb-0" *ngIf="submitted && myForm.get('status').errors">
                                        <p class="text-danger mb-0" *ngIf="myForm.get('status').errors.required">
                                            Status is required
                                        </p>
                                    </div>
                                </div>
                            </div>




                            <div class="row">
                                <div class="col-12 text-center">

                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-light font-primary">
                                            Cancel
                                        </button></span>

                                    <span class=" text-end ms-2"> <button class="btn btn-md btn-primary"
                                            (click)="onSubmit()" type="submit">
                                            Save
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>