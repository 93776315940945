import { Component } from '@angular/core';

@Component({
  selector: 'app-attribute-mapping',
  templateUrl: './attribute-mapping.component.html',
  styleUrls: ['./attribute-mapping.component.scss']
})
export class AttributeMappingComponent {

}
