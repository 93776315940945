<div class="container-fluid ">
    <div class="row ">
        <div class="card mt-3 p-0 mx-0">
            <div class="card-body m-0 p-0">
                <div class="px-4 pt-4 ">
                    <h5>Announcement</h5>
                    <!-- <h3>Knowledge Center</h3> -->
                    <!-- <p class="text-muted">Manage products and stock </p> -->
                    <hr>
                    <div class="px-2 ">
                        <ul #nav="ngbNav" ngbNav class="nav-tabs nav">

                            <li [ngbNavItem]="4" class="mx-1">
                                <!-- <a ngbNavLink class="nav-link">
                                    Announcement </a> -->
                                <ng-template ngbNavContent>

                                    <form [formGroup]="myForm">
                                        <div class="form-group media row p-0 ">

                                            <div class="col-12">
                                                <div class="form-group media row ">
                                                    <div class="input-group media-body">
                                                        <label class="col-form-label col-3">
                                                            <ng-select [items]="filters" (click)="setValue()"
                                                                class="custom" bindValue="name" [clearable]="false"
                                                                bindLabel="name"
                                                                formControlName="selectedValue"></ng-select>
                                                        </label>
                                                        <label class="col-form-label col-9 ">
                                                            <input [hidden]="selectedValue!= item.name"
                                                                *ngFor="let item of filters" type="search"
                                                                class="form-control pb-1 round"
                                                                placeholder="{{item.ph}}" style="max-width: 99%;" />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row p-0 mt-2">
                                                <div class="col-6 p-0">
                                                    <div class="input-group row">
                                                        <div class=" col-2 text-start"
                                                            style="padding-top: 5px;padding-left: 37px;">
                                                            <label style="margin-top: 2px;">Status</label>
                                                        </div>
                                                        <div class=" col-10 p-0">
                                                            <select class="form-select form-select-md text-dark"
                                                                aria-label=".form-select-md example">
                                                                <!--          formControlName="sorting" -->
                                                                <option disabled selected value="" class="text-muted">
                                                                    Select Sorting
                                                                </option>
                                                                <option value="1">All</option>
                                                                <option value="2">Live</option>
                                                                <option value="3">Expired</option>
                                                                <option value="3">Upcomming</option>
                                                            </select>

                                                            <!-- <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                                Sorting is required
                                                            </p>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 p-0 ">
                                                    <div class="input-group row">
                                                        <div class="col-4 text-end px-0;pe-1" style="padding-top: 5px;">
                                                            <label for="dateRangePicker ">Select Date Range</label>
                                                        </div>
                                                        <div class="col-8 px-0">
                                                            <input type="date" id="dateRangePicker"
                                                                class="form-control">
                                                            <!--   <input type="daterange" class="form-control" id="dateRangePicker"
                                                            [options]="dateRangePickerOptions"
                                                            (selected)="onDateRangeSelected($event)" /> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="text-center mt-1">


                                            <a class="btn btn-light font-primary mt-1">Clear</a>
                                            <a class="btn btn-primary mt-1 ms-2">Search</a>
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="row mt-0">
                                        <div class="col-10 text-end mb-2 pt-0">
                                            <div ngbDropdown placement="bottom" class=" m-r-10">
                                                <button class="btn btn-secondary text-dark bg-light" id="dropdownBasic1"
                                                    ngbDropdownToggle>
                                                    <span class="text-center ms-2">Sort by</span> <i
                                                        class="fa fa-down"></i>
                                                </button>
                                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                    <button class="dropdown-item">All</button>
                                                    <button class="dropdown-item">Live</button> <button
                                                        class="dropdown-item">Draft</button>
                                                </span>
                                            </div>
                                        </div>



                                        <div class="col-2 text-end">
                                            <button class="btn btn-primary mb-2 m-r-5"
                                                routerLink="/cms/create-new-announcement">
                                                Create Announcement
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <table class="table">
                                            <thead class="bg-light text-dark">
                                                <tr>
                                                    <th class="text-center">Announcement Title</th>
                                                    <th class="text-center">Slug</th>
                                                    <th class="text-center">User Group</th>
                                                    <th class="text-center">Viewed</th>
                                                    <th class="text-center">Duration</th>
                                                    <th class="text-center">Updated By</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let announcement of paginatedList; let i=index text-center">
                                                    <td class="text-center">
                                                        <!-- <img src="{{ folder.img }}"
                                                            style="height: 40px; width: 40px; padding:3px"
                                                            class="border bg-light text-dark"> -->
                                                        {{
                                                        announcement.announcementTitle }}
                                                    </td>
                                                    <td class="text-center"> {{announcement.slug}}</td>
                                                    <td class="text-center">{{ announcement.userFolder }}</td>
                                                    <td class="text-center"> {{ announcement.viewed}}</td>
                                                    <td class="text-center"> {{ announcement.duration }}</td>

                                                    <td class="text-center">{{ announcement.updatedBy }}</td>
                                                    <td class="text-center">
                                                        <div class="badge-green">{{ announcement.status }}</div>
                                                    </td>



                                                    <td class="text-center">
                                                        <div class="d-flex justify-content-center action">
                                                            <a href="#" class="ms-1 trash-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                    height="20" viewBox="0 0 36 36"
                                                                    style="padding-top:1px ;">
                                                                    <path fill="currentColor"
                                                                        d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                                                                        class="clr-i-outline clr-i-outline-path-1" />
                                                                    <path fill="currentColor"
                                                                        d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                                                                        class="clr-i-outline clr-i-outline-path-2" />
                                                                    <path fill="none" d="M0 0h36v36H0z" />
                                                                </svg>
                                                            </a>
                                                            <a href="#" class="ms-1 pencil-icon"><i
                                                                    class="icon-pencil-alt"></i>
                                                            </a>

                                                            <a href="#" class="ms-1 trash-icon"><i
                                                                    class="icon-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- Pagination Start Here  -->


                                        <div class=" row mt-3 mb-2">


                                            <div class="col-2">

                                                <select class="form-select" [(ngModel)]="itemsPerPage"
                                                    (ngModelChange)="changeItemsPerPage()">
                                                    <option value="2">2 items</option>
                                                    <option value="4">4 items</option>
                                                    <option value="6">6 items</option>
                                                </select>
                                            </div>

                                            <div class="col-10 text-end">
                                                <nav *ngIf="totalPages > 1">
                                                    <ul class="pagination justify-content-end">
                                                        <li class="page-item" [class.disabled]="currentPage === 1">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="previousPage()">Previous</a>
                                                        </li>
                                                        <li class="page-item" [class.active]="currentPage === page"
                                                            *ngFor="let page of pages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="goToPage(page)">{{ page }}</a>
                                                        </li>
                                                        <li class="page-item"
                                                            [class.disabled]="currentPage === totalPages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="nextPage()">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <!-- Pagination Ends Here  -->
                                    </div>
                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2 col-sm-12"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>