import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AttributeFamilyComponent } from './attribute-family/attribute-family.component';
import { AttributeFamilyService } from './attribute-family.service';
import { RouterModule, Routes } from '@angular/router';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { AttributeFamilyAssignComponent } from './attribute-family-assign/attribute-family-assign.component';

const routes: Routes = [
  {
    path: '',
    component: AttributeFamilyComponent,
  },
  {
    path: 'edit/:id',
    component: AttributeFamilyComponent,
  },
  {
    path: 'assign/:id',
    component: AttributeFamilyAssignComponent,
  },
]


@NgModule({
  declarations: [
    AttributeFamilyComponent, AttributeFamilyAssignComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatFormFieldModule, 
    MatInputModule,
    MatDatepickerModule, 
    MatSortModule,
    MatNativeDateModule,
    DragDropModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers:[AttributeFamilyService]
})
export class AttributeFamilyModule { }
