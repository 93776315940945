import { JwtTokenService } from './jwt-token.service';
import { LoginModel } from '../models/login';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User, Role } from '../models/index';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { logout, updateUserState } from 'src/app/store/user/user.actions';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  private jwtTokenSubject: BehaviorSubject<string> | null = null;
  public token: Observable<string> | undefined;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient,
    private _toastrService: ToastrService,
    private tokenService: JwtTokenService,
    private store: Store,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    this.jwtTokenSubject = new BehaviorSubject(localStorage.getItem('token')!);
    this.currentUser = this.currentUserSubject.asObservable();
    this.token = this.jwtTokenSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return true;
    // return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return true;

    // return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(post: LoginModel) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, post)
      .pipe(
        map((res: any) => {
          console.log(res);
          if (res.statusCode == 401) {
            return { status: false, errors: res.error, message: res.message };
            // this._toastrService.error(res.data.message);
            // return false;
          }
          console.log(res.data);
          if (res.statusCode == 201 && res.data?.token) {
            // login successful if there's a jwt token in the response

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(res.data?.user));
            localStorage.setItem('token', res.data.token);
            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                res.data?.user?.username +
                ' user to igget. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + res.data?.user?.username + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(res.data?.user);
            // this.store.dispatch(updateUserState(res.data?.user));
            console.log(res.data?.user);
            return res.data?.user;
          }
          return { status: false, errors: res.error, message: res.message };
        }),
        catchError(this.handleError)
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    // notify
    // this.store.dispatch(logout());
    this.currentUserSubject.next(new User);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
