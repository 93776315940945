
import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemSettingsService } from './system-settings.service';
import { settingsModel } from './settings.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent {

  displayedColumns = ["slNo", "key","value", "group", "action"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;
  isRateLimitReached = false;

  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  sortBy = [{ value: "ASC", name: "Ascending Order" }, { value: "DESC", name: "Descending Order" }];

  public searchText;
  total$: Observable<number>;
  public selected = [];
  settings: any = [];
  departmentList: any =[];

  settingCreateForm: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  validate: any;
  errors: any = [];
  settingId: number;
  validPattern = "/^[a-zA-Z]*$"; 

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SystemSettingsService,
    private toast: ToastrService,) {
      const routeParams = this.route.snapshot.paramMap;
    this.settingId = Number(routeParams.get("id"));
  }
  ngOnInit() {

    this.settingCreateForm = this.fb.group({
      key: ['', Validators.required],
      // ,  Validators.pattern(/^[A-Za-z-]*$/)
      value: ['', Validators.required],
      group: ['', Validators.required],
    });

    this.settingsService.findOne(this.settingId).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        const settingUpdate: settingsModel = res.data;
        console.log(settingUpdate);
        this.settingCreateForm.get('key').setValue(settingUpdate.key);
        this.settingCreateForm.get('value').setValue(settingUpdate.value);
        this.settingCreateForm.get('group').setValue(settingUpdate.group);
      }
    });

    this.searchForm = this.fb.group({
      search: [null],
      sort: [null],
    });

  }

  async ngAfterViewInit() {
    await this.getSettingList();
  }

  async getSettingList() {
    await this.settingsService.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.settings= res.data.data;
        this.paginationMeta = res.data.meta;
        this.isLoadingResults = false;
        console.log(this.paginationMeta);
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getSettingList();
  }

  onRowClicked(row) {
    console.log('Row clicked: ', row);
  }

  async onDelete(id: number) {

    const postData = {
      setting_id: id,
    };
    const response = await this.settingsService.delete(postData).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.settings = this.settings.filter(setting => setting.setting_id !== id);
        console.log(this.settings);
        this.getSettingList();
      
      } else {
        this.toast.error(res.errors);
        console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }

  async onSubmit() {
    this.submitted = true;
    this.validate = !this.validate;
    console.log(this.settingCreateForm.value);
    if(this.settingId) {
      const postData = {
        setting_id: this.settingId,
        key: this.settingCreateForm.value.key,
        value:this.settingCreateForm.value.value,
        group: this.settingCreateForm.value.group,
      }
      console.log(postData);
  
      if (this.settingCreateForm.status == 'VALID') {
        const response = await this.settingsService.update(postData).subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.settingCreateForm.reset();
            this.toast.success('Setting has been updated successfully');
            this.getSettingList();
            return this.router.navigate(['/settings']);
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        });
        console.log(response);
      }
    }else {
      const postData: settingsModel= this.settingCreateForm.value;

    if (this.settingCreateForm.status == 'VALID') {

      const response = await this.settingsService.create(postData)
        .subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.settingCreateForm.reset();
            this.toast.success('Setting has been added successfully');
            this.getSettingList();
           
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        }
        );
      console.log(response);
    }
    }

  }

  get f() {
    return this.settingCreateForm.controls;
  }

  onSearch() {
    console.log(this.searchForm.value);
    if (this.searchForm.value.search != null) {
      this.pagination.search = this.searchForm.value.search;
    }

    this.getSettingList();

  }

  onClear(){
    this.searchForm.reset();
    this.pagination.search = '';
    this.getSettingList();

  }
}

