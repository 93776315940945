export const statusData = [
    { sNo: 1, key: 'purchase-approval', value: 1, group: 'Customer' },
    { sNo: 2, key: 'product-pricing-open', value: 2, group: 'sService' },
    { sNo: 3, key: 'product-approval', value: 1, group: 'Seller Marketing' },
    { sNo: 4, key: 'purchase-approval', value: 1, group: 'Orders' },
    { sNo: 5, key: 'product-pricing-open', value: 2, group: 'Content' },
    { sNo: 6, key: 'product-approval', value: 1, group: 'Content' },

    { sNo: 7, key: 'purchase-approval', value: 1, group: 'Seller Marketing' },
    { sNo: 8, key: 'product-pricing-open', value: 2, group: 'Content' },
    { sNo: 9, key: 'product-approval', value: 1, group: 'Product' },
    { sNo: 10, key: 'product-approval', value: 1, group: 'Seller Marketing' },


];
