<div class="container-fluid pt-3">
    <h4>Category List</h4>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header pt-1">
                    <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label">Search</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="Category Name / Category code"
                                            formControlName="category_search">
                                    </div>
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="form-group">
                                    <label class="col-form-label">Status</label>
                                    <div class="input-group">
                                        <ng-select [items]="status" bindValue="id" [clearable]="false" bindLabel="name"
                                            class="custom col-12" placeholder="Select status"
                                            formControlName="status"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label class="col-form-label">Sort By</label>
                                    <div class="input-group">
                                        <ng-select [items]="sortBy" bindValue="value" [clearable]="false"
                                            bindLabel="name" class="custom col-12" placeholder="Select order"
                                            formControlName="sort"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 text-end mt-4 pt-2">
                                <a class="btn btn-lg btn-primary" routerLink="/catalog/category/create">
                                    Add Category</a>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center mt-3">
                                    <a class="btn btn-secondary mx-2" (click)="onClear()">Clear</a>
                                    <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div class="card-body">
                    <mat-table class="" [dataSource]="category" matSort matSortActive="id" matSortDirection="asc"
                        matSortDisableClear>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                            <mat-cell *matCellDef="let category">{{ category?.name }}<br>
                                {{category.parents}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="level">
                            <mat-header-cell *matHeaderCellDef>Level</mat-header-cell>
                            <mat-cell class="email-cell" *matCellDef="let category">{{
                                category.level
                                }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="value">
                            <mat-header-cell *matHeaderCellDef>Commission</mat-header-cell>
                            <mat-cell class="role-cell" *matCellDef="let category">{{
                                category?.commission
                                }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="parent_id">
                            <mat-header-cell *matHeaderCellDef>Parent ID</mat-header-cell>
                            <mat-cell class="status-cell" *matCellDef="let category">{{
                                category.parent_id
                                }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                            <mat-cell class="status-cell" *matCellDef="let category">
                                <div class="action">
                                    <li class="edit me-3">
                                        <a [routerLink]="['/catalog/category/edit', category.id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li class="delete" (click)="onSelect(category.id)">
                                        <a>
                                            <i class="icon-trash"></i>
                                        </a>
                                    </li>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                </div>
                <div class="card-footer">
                    <mat-paginator [length]="paginationMeta.totalItems" [pageIndex]=0
                        [pageSize]="paginationMeta.itemsPerPage" [pageSizeOptions]="[5, 10, 20, 50]"
                        (page)="getServerData($event)" [showFirstLastButtons]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>