

import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { CreateUnitComponent } from 'src/app/shared/create-unit/create-unit.component';
// import { CreateUnitComponent } from '../../../../shared/create-unit/create-unit.component'

@Component({
  selector: 'app-create-new-catagory',
  templateUrl: './create-new-catagory.component.html',
  styleUrls: ['./create-new-catagory.component.scss']
})
export class CreateNewCatagoryComponent implements OnInit {




  [x: string]: any;

  submited: boolean = false;
  myForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      sorting: ['', Validators.required],
      status: ['', Validators.required],
      // image: ['', Validators.required],
      // folderType: ['', Validators.required],
      folder: ['', Validators.required],
      userGroup: ['', Validators.required],


    });
  }
  filters = [

    { id: 1, name: "folder 1", },
    { id: 2, name: "folder 2", },
    { id: 3, name: "folder 3", },
    { id: 4, name: "folder 4", },
    { id: 5, name: "folder 5", },

  ];
  onSubmit() {
    this.submitted = true;
    if (this.myForm.valid) {
      console.log('Form submitted successfully');
    } else {

      console.log('Form contains validation errors');
    }
  }

  get f() {
    return this.myForm.controls;
  }


  inputValue: string;


  // openModal() {
  //   const modalRef = this.modalService.open(CreateUnitComponent);
  //   modalRef.componentInstance.inputValue = this.inputValue;
  // }
}


