import { PaginationMetaType, PaginationMetaModel, PaginationType } from 'src/app/core/modals/Pagination';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {

  users: any = [];
  roles: any = [];
  errors: any = [];
  searchForm: FormGroup;
  displayedColumns = ["username", "email", "role", "status", "action"];
  isLoadingResults = true;
  isRateLimitReached = false;
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  status = [{id: 0, name : "Deleted"}, { id: 1, name: "Active" }, {id: 2, name: "Inactive" }];
  sortBy = [{value: "id:ASC", name: "Ascending Order"}, {value: "id:DESC", name: "Descending Order"}]

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private user: UserService,
    private toast: ToastrService,
  ) {

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    this.searchForm = this.fb.group({
      search: [null],
      status: [null],
      sort: [null]
    });

    // await this.user.findAll().subscribe((res: any) => {
    //   console.log(res);
    //   this.users = res.data.users.data;
    //   this.roles = res.data.roles;
    //   console.log(this.users);
    //   console.log(this.users[0].profile);
    // })
  }

  getRole(role:any = []){
    let roles: any = [];
    
    this.roles.forEach((item) => {
      role.forEach((data) => {
         if(item.role_id == data.role_id){
          roles.push(item.role_name);
         }
      })
    })
    return roles;
  }

  ngAfterViewInit() {
    this.getUserList();
  }

  async getUserList() {
    await this.user.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.users = res.data.users.data;
        this.roles = res.data.roles;
        this.paginationMeta = res.data.users.meta;
        this.isLoadingResults = false;
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getUserList();
  }

  getStatusName(id: number){
    let status : string = ''
    this.status.forEach((item) => {
      if (item.id == id) {
        status = item.name;
      }
    })
    return status;
  }

  onSearch() {
    
    // const filterValue = {
    //   search : this.searchForm.value.search || '',
    //   // status : this.searchForm.value.status,
    //   // sort : this.searchForm.value.sort || "id:DESC",
    // }
    // console.log(filterValue);
    console.log(this.searchForm.value);
    if(this.searchForm.value.search != null){
    this.pagination.search = this.searchForm.value.search;
    }
    // this.pagination.filter = filterValue.status;
    this.pagination.sortBy = this.searchForm.value.sort || ["admin.id:DESC"];

    this.getUserList();
    
  }

  onClear(){
    this.searchForm.reset();
    this.pagination.search = '';
    this.getUserList();
  }

  async onSelect(id: number) {
    const postData = {
      id: id,
      status: 0,
    };
    const response = await this.user.delete(postData).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.users = this.users.filter(user => user.id !== id);
        console.log(this.users);
        return this.router.navigate(['/user-management/user']) && res.data;
      } else {
        this.toast.error(res.errors);
        console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }
}

