import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'category',
    loadChildren: () => import('./category/category.module').then(m => m.CategoryModule)
  },
  {
    path: 'attribute',
    loadChildren: () => import('./attribute/attribute.module').then(m => m.AttributeModule),
  },
  {
    path: 'attribute-family',
    loadChildren: () => import('./attribute-family/attribute-family.module').then(m => m.AttributeFamilyModule),
  },
  {
    path: 'attribute-set',
    loadChildren: () => import('./attribute-set/attribute-set.module').then(m => m.AttributeSetModule)
  },
  {
    path: 'attribute-create',
    loadChildren: () => import('./attribute/attribute.module').then(m => m.AttributeModule),
  },
  {
    path: 'brand',
    loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  }

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class CatalogRoutingModule { }
