

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { pageList } from './page-list';


@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss']
})
export class PageListComponent implements OnInit {


  selectedValue: string = 'Page Name';
  filters = [{ id: 1, name: "Page Name", ph: "Page Name" },
  ]

  setValue() {
    this.selectedValue = this.myForm.get('selectedValue')?.value;
    console.log(this.selectedValue);
  }


  pageList = pageList

  myForm: FormGroup;




  ngOnInit() {
    this.myForm = this.fb.group({
      sorting: ['', Validators.required],

      selectedValue: new FormControl(this.selectedValue),
    });
  }


  public selectedList: string;


  constructor(private fb: FormBuilder) {


    this.paginate();

    // this.totalPagesFlashAnnouncement = Math.ceil(this.pageList.length / this.itemsPerPage);
    // this.generateFlashAnnouncementPages();
    // this.updateFlashAnnouncementPaginatedCategories();
  }
  // itemsPerPage: number = 4;
  currentPage: number = 1;
  totalPages: number = 0;
  paginatedList: any[] = [];
  pages: number[] = [];
  itemsPerPage: number = 4;






  // currentPageFlashAnnouncement: number = 1;


  // paginatedpageList: any[];
  // totalPagesFlashAnnouncement: number = 0;
  // FlashAnnouncementPage: number[] = [];
  // flashAnnouncementpages: number[] = [];




  paginate() {
    // Calculate paginatedList, totalPages, and pages based on itemsPerPage and currentPage
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedList = this.pageList.slice(startIndex, endIndex);

    this.totalPages = Math.ceil(this.pageList.length / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginate();
    }
  }

  changeItemsPerPage() {
    this.currentPage = 1;
    this.paginate();
  }
}
  // Make sure to populate this with your articles

//   previousPageFlashAnnouncement() {
//     if (this.currentPageFlashAnnouncement > 1) {
//       this.currentPageFlashAnnouncement--;
//       this.updateFlashAnnouncementPaginatedCategories();
//     }
//   }

//   nextPageFlashAnnouncement() {
//     if (this.currentPageFlashAnnouncement < this.totalPagesFlashAnnouncement) {
//       this.currentPageFlashAnnouncement++;
//       this.updateFlashAnnouncementPaginatedCategories();
//     }
//   }

//   goToPageFlashAnnouncement(flashAnnouncementPage: number) {
//     if (flashAnnouncementPage >= 1 && flashAnnouncementPage <= this.totalPagesFlashAnnouncement) {
//       this.currentPageFlashAnnouncement = flashAnnouncementPage;
//       this.updateFlashAnnouncementPaginatedCategories();
//     }
//   }

//   generateFlashAnnouncementPages() {
//     this.flashAnnouncementpages = Array(this.totalPagesFlashAnnouncement).fill(0).map((x, i) => i + 1);
//   }

//   updateFlashAnnouncementPaginatedCategories() {
//     const startIndex = (this.currentPageFlashAnnouncement - 1) * this.itemsPerPage;
//     const endIndex = startIndex + this.itemsPerPage;
//     this.paginatedpageList = this.pageList.slice(startIndex, endIndex);
//     this.generateFlashAnnouncementPages();
//   }

// }