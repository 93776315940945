<div class="image-container">
    <div class="image-upload" (click)="imageInput.click()">
        <input #imageInput type="file" id="image-input" accept="image/*" class="hide"
            (change)="processFile(imageInput, $event)" />

        <div class="default text-center" *ngIf="defaultImage == true">
            <img src="./../../../../assets/svg/image-upload-icon.svg" class="upload" alt="image-upload">
            <p class="font-primary">Add Image</p>
        </div>
        <div class="thumb" *ngIf="defaultImage == false">
            <img [src]='selectedFile?.src' alt="" class="img-fluid image">
        </div>
    </div>
</div>
<div class="tools" *ngIf="defaultImage == false">
    <div class="icon">
        <i class="fa fa-trash" (click)="remove($event)" aria-hidden="true"></i>
    </div>
    <div>
        <img class="vertical" src="./../../../../assets/svg/vertical-line-icon.svg" aria-hidden="true">
    </div>
    <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"
            (click)="crop($event, cropModal)" aria-hidden="true">
            <path fill="currentColor"
                d="M5 23.7q-.825 0-1.413-.587T3 21.7v-14q0-.825.588-1.413T5 5.7h8.925l-2 2H5v14h14v-6.95l2-2v8.95q0 .825-.588 1.413T19 23.7H5Zm7-9Zm4.175-8.425l1.425 1.4l-6.6 6.6V15.7h1.4l6.625-6.625l1.425 1.4l-7.2 7.225H9v-4.25l7.175-7.175Zm4.275 4.2l-4.275-4.2l2.5-2.5q.6-.6 1.438-.6t1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8l-2.475 2.475Z" />
        </svg>
    </div>
</div>

<ng-template #cropModal let-c="close" let-d="dismiss">
    <div class="cropper container">
        <div class="modal-header">
            <h5>Image Editor</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        </div>
        <div class="modal-body row">
            <div class="cropper-section col-md-7 border">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4/3" [resizeToWidth]="256"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
            <div class="col-md-5 corpedImage">
                <img [src]="croppedImage" class="border">
                <p class="text-center"> <b class="bold">Preview</b></p>

                <button class="btn btn-light button" (click)="zoomIn()" placement="top" ngbTooltip="Zoom In"><i
                        class="fa fa-search-plus f-light"></i></button>
                <button class="btn btn-light ms-1 button" (click)="zoomOut()" placement="top" ngbTooltip="Zoom Out"><i
                        class="fa fa-search-minus f-light"></i></button>
                <button class="btn btn-light ms-1 button" (click)="rotateLeft()" placement="top"
                    ngbTooltip="Rotate"><img src="./../../../../assets/svg/rotate-icon.svg" alt="rotate"
                        class="f-light"></button>
                <button class="btn btn-light ms-1 button" (click)="flipVertical()" placement="top"
                    ngbTooltip="Flip Vertical"><img src="./../../../../assets/svg/flip-horizontal.svg"
                        alt="flipVertical"></button>
                <button class="btn btn-light ms-1 button" (click)="flipHorizontal()" placement="top"
                    ngbTooltip="Flip Horizontal"><img src="./../../../../assets/svg/flip-vertical.svg"
                        alt="flipHorizontal"></button>
                <button class="btn btn-light ms-1 button" (click)="resetImage()" placement="top" ngbTooltip="Reset"><i
                        class="fa fa-refresh f-light"></i></button>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="d('Cross click')">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="c('Save click')">Save</button>
        </div>
    </div>
</ng-template>