import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusComponent } from '../status/status.component';
import { ThemeModule } from '../../../../../src/app/theme/theme.module';
import { Routes, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
const routes: Routes = [
  {
    path: '',
    component: StatusComponent,
  },

];

@NgModule({
  declarations: [StatusComponent],
  imports: [
    CommonModule, ThemeModule, NgSelectModule, RouterModule.forChild(routes),
  ]
})
export class StatusModule { }

