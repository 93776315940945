<div class="container-fluid pt-3">
    <h4>Attribute Edit</h4>
    <div class="row">
        <div class="col-12" *ngIf="errors.length > 0">
            <div class="alert alert-danger">
                <ul>
                    <li *ngFor="let error of errors">{{error}}</li>
                </ul>
            </div>
        </div>
        <div class="col-12" *ngIf="msg.length > 0">
            <div class="alert alert-success">
                <ul>
                    <li *ngFor="let msg of msg">{{msg}}</li>
                </ul>
            </div>
        </div>
        <div class="col-sm-12" *ngIf="attributeData$">
            <form [formGroup]="attributeForm">
                <div formArrayName="attributes">
                    <div class="card">
                        <div class="card-header">
                            <div class="row mb-2">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                    <div class="form-switch">
                                        <div class="text-end" id="mand">
                                            <label class="form-label mx-5"
                                                for="flexSwitchCheckChecked">Mandatory</label>
                                            <input class=" form-check-input text-end" type="checkbox" role="switch"
                                                id="mandatory" checked formControlName="mandatory">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" id="attribute">
                                <div class="col-6">
                                    <label>Attribute Type</label>
                                    <select name="type_id" class="form-control" formControlName="type_id">
                                        <option *ngFor="let type of attributeTypes" value="{{type.id}}">{{type.type}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 mb-1">
                                    <label>Attribute Code</label>
                                    <input class="form-control" formControlName="code" type="text" required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 mb-1">
                                    <label class="form-label">Allow Dependent</label>
                                    <ng-select [items]="dependents" class="custom" bindLabel="type" bindValue="value"
                                        placeholder="Select" [clearable]="false" bindLabel="type"
                                        formControlName="allow_dependent"></ng-select>
                                </div>
                                <div class="col-6 mb-1" *ngIf="allow_dependent != 'Yes'">
                                    <label class="form-label">Dependent Attribute</label>
                                    <ng-select [items]="dependent_attributes" class="custom" bindValue="type"
                                        placeholder="Select" [clearable]="false" bindLabel="type"
                                        formControlName="dependent_attribute"></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row my-2">
                                <container-element [ngSwitch]="selectedType">
                                    <!-- text field -->
                                    <some-element *ngSwitchCase="1">
                                         <!-- labels -->
                                        <div class="text-feild">
                                            <div class="row">
                                                <div class="col-6 mb-1">
                                                    <label class="form-label">Input Type</label>
                                                    <ng-select [items]="inputTypes" class="custom" bindValue="id"
                                                        placeholder="Select" [clearable]="false" bindLabel="type"
                                                        formControlName="input_type"></ng-select>
                                                </div>
                                                <div class="col-6 mb-1">
                                                    <label class="form-label">Default Value</label>
                                                    <input class="form-control" formControlName="default_value"
                                                        type="text" onlyAlphabets>
                                                </div>
                                            </div>
                                        </div>
                                    </some-element>
                                    <!-- text area -->
                                    <some-element *ngSwitchCase="2">
                                        <div class="text-area">
                                            <div class="row">
                                                <div class="col-6 mb-1">
                                                    <label class="form-label">Default Value</label>
                                                    <input class="form-control" type="text"
                                                        formcontrolname="default_value">
                                                </div>
                                            </div>
                                        </div>
                                    </some-element>
                                     <!-- drop down -->
                                    <some-element *ngSwitchCase="3">
                                        <div class="row">
                                            <div class="col-md-6"></div>
                                            <div class="col-md-6">
                                                <div class="form-switch">
                                                    <div class=" text-end">
                                                        <label class="form-label mx-5" for="flexSwitchCheckChecked">Add
                                                            More Option</label>
                                                        <input class=" form-check-input text-end" type="checkbox"
                                                            role="switch" id="add-more" checked>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2" formArrayName="options">
                                                <table class="table table-responsive">
                                                    <thead class="text-start">
                                                        <tr>
                                                            <th scope="col">Is Default</th>
                                                            <th *ngFor="let item of languages; let index = index;"
                                                                scope="col">
                                                                {{item.label}}
                                                            </th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of optionsForm.controls; let optionIndex = index"
                                                            [formGroupName]="optionIndex" class="translation-form">
                                                            <td scope="col">
                                                                <input class="form-check-input" name="optionDefault"
                                                                    value="{{index}}" type="radio"
                                                                    formControlName="default" [checked]="index==0">
                                                            </td>
                                                            <td scope *ngFor="let lang of languages">
                                                                <input type="text" class="form-control"
                                                                    [formControlName]='lang.code'>
                                                            </td>
                                                            <td scope="col">
                                                                <a (click)="removeTranslation(translationIndex)"
                                                                    class="btn btn-danger m-2">
                                                                    Remove
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-12 text-center my-2">
                                                        <a class="btn btn-outline-primary " (click)="addOption()">Add
                                                            Option</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </some-element>
                                    <!-- multi select -->
                                    <some-element *ngSwitchCase="5">
                                        <div class="multi-select">
                                            <div class="row">
                                                <div class="col-6 mb-1">
                                                    <label class="form-label">Multi Select Count</label>
                                                    <select name="allow-dependent" class="form-control">
                                                        <option value="0">1</option>
                                                        <option value="0">2</option>
                                                        <option value="0">3</option>
                                                        <option value="0">4</option>
                                                        <option value="0">5</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row option-card">
                                            <div class="col-md-6"></div>
                                            <div class="col-md-6">
                                                <div class="form-switch">
                                                    <div class=" text-end">
                                                        <label class="form-label mx-5" for="flexSwitchCheckChecked">
                                                            Add More Option
                                                        </label>
                                                        <input class=" form-check-input text-end" type="checkbox"
                                                            role="switch" id="add-more-option" checked>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card mt-2">
                                                <div class="col-md-12 mt-2" formArrayName="options">
                                                    <table class="table table-responsive">
                                                        <thead class="text-start">
                                                            <tr>
                                                                <th scope="col">Is Default</th>
                                                                <th *ngFor="let item of languages; let index = index;"
                                                                    scope="col">
                                                                    {{item.label}}
                                                                </th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of optionsForm.controls; let optionIndex = index"
                                                                [formGroupName]="optionIndex" class="translation-form">
                                                                <td scope="col">
                                                                    <input class="form-check-input" name="optionDefault"
                                                                        value="{{index}}" type="radio"
                                                                        formControlName="default" [checked]="index==0">
                                                                </td>
                                                                <td scope *ngFor="let lang of languages">
                                                                    <input type="text" class="form-control"
                                                                        [formControlName]='lang.code'>
                                                                </td>
                                                                <td scope="col">
                                                                    <a (click)="removeTranslation(translationIndex)"
                                                                        class="btn btn-danger m-2">
                                                                        Remove
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="row">
                                                        <div class="col-12 text-center my-2">
                                                            <a class="btn btn-outline-primary "
                                                                (click)="addOption()">Add
                                                                Option</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </some-element>
                                    <some-element *ngSwitchDefault></some-element>
                                    <div class="row border-bottom border-top translation-form-group mt-2 pt-2">
                                        <div class="col-md-2 border-end">
                                            <div class="nav flex-column nav-pills me-3 mt-3" id="language-tab"
                                                role="tablist" aria-orientation="vertical">
                                                <h6 class="text-center">Locals</h6>
                                                <button *ngFor="let item of languages; let index = index;"
                                                    [ngClass]="{'active': index === activePillIndex}"
                                                    (click)="selectLanguage(index)" class="nav-link border my-2"
                                                    id="v-pills-{{item.code}}-tab" data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-{{item.id}}" type="button" role="tab"
                                                    aria-controls="v-pills-{{item.code}}" aria-selected="true">
                                                    {{item.label}}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-10" formArrayName="translations">
                                            <div class="tab-content" id="language-tabContent">
                                                <div *ngFor="let item of languages; let index = index"
                                                    [ngClass]="{'show active': index === activePillIndex}"
                                                    class="translation-form my-2 tab-pane fade" id="v-pills-{{item.id}}"
                                                    role="tabpanel" aria-labelledby="v-pills-{{item.code}}-tab">
                                                    <div [formGroupName]="index">
                                                        <h6>
                                                            Enter Attribute Details - {{item.label}}
                                                        </h6>
                                                        <div class="mb-3 form-group">
                                                            <input type="hidden" formControlName="language_id"
                                                                class="form-control" value="{{item.code}}">
                                                        </div>
                                                        <div class="row">
                                                            <div class=" col-6 mb-1 form-group">
                                                                <label for="description" class="form-label">
                                                                    Attribute Label
                                                                </label>
                                                                <input type="text" formControlName="name"
                                                                    class="form-control"
                                                                    placeholder="Attribute Display Label">
                                                                <div *ngIf="submitted && translationsFrom.controls[index].get('name').errors"
                                                                    class="errors">
                                                                    <p *ngIf="translationsFrom.controls[index].get('name').errors['required']"
                                                                        class="text-danger">
                                                                        Attribute label required
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div class=" col-md-6 mb-3 form-group">
                                                                <label for="meta_tooltip" class="form-label">
                                                                    Tool Tip Text
                                                                </label>
                                                                <input type="text" formControlName="tooltip_text"
                                                                    class="form-control" placeholder="Tool Tip Info">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="mb-3 col-md-6 form-group">
                                                                <label for="meta_title" class="form-label">
                                                                    Place holder
                                                                </label>
                                                                <input type="text" formControlName="place_holder_text"
                                                                    class="form-control"
                                                                    placeholder="Place Holder Text">
                                                            </div>
                                                            <div class="mb-3 col-md-6 form-group">
                                                                <label for="meta_error" class="form-label">
                                                                    Input Type Error Message
                                                                </label>
                                                                <input type="text" formControlName="error_message"
                                                                    class="form-control"
                                                                    placeholder="Enter Error Message">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="store-front-properties" class="mt-2 pt-2">
                                        <h6>
                                            Storefront Properties
                                        </h6>
                                        <div class="row form-switch">
                                            <div class="text-start my-2">
                                                <label class="form-label mx-5 col-6" for="is_filterable">
                                                    Use in Fliter
                                                </label>
                                                <input class="col-6 form-check-input text-end" type="checkbox"
                                                    formControlName="is_filterable" role="switch" id="is_filterable"
                                                    checked>
                                            </div>
                                        </div>
                                        <div class="row form-switch">
                                            <div class="text-start my-2">
                                                <label class="col-6 form-label mx-5" for="flexSwitchCheckChecked">
                                                    Use in Search
                                                </label>
                                                <input class="col-6 form-check-input text-end" type="checkbox"
                                                    formControlName="is_searchable" role="switch" id="is_searchable"
                                                    checked>
                                            </div>
                                        </div>

                                        <div class="row form-switch">
                                            <div class="text-start my-2">
                                                <label class="col-6 form-label mx-5" for="is_comparable">
                                                    Use in Camparable
                                                </label>
                                                <input class="col-6 form-check-input" type="checkbox" role="switch"
                                                    formControlName="is_comparable" id="is_comparable" checked>
                                            </div>
                                        </div>

                                        <div class="row form-switch">
                                            <div class="text-start my-2">
                                                <label class="col-6 form-label mx-5" for="system_attribute">
                                                    System Attribute
                                                </label>
                                                <input class="col-6 form-check-input" type="checkbox" role="switch"
                                                    formControlName="is_user_defined" id="is_user_defined" checked>
                                            </div>
                                        </div>
                                        <div class="row form-switch">
                                            <div class="text-start my-2">
                                                <label class="col-6 form-label mx-5" for="flexSwitchCheckChecked">
                                                    Status
                                                </label>
                                                <input class="col-6 form-check-input" type="checkbox" role="switch"
                                                    formControlName="status" id="status-btn" checked>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-12 text-end mt-3">
                                                <a (click)="cancel()" class="btn btn-secondary mx-2" type="reset">
                                                    Cancel
                                                </a>
                                                <a (click)="save()" class="btn btn-primary mx-2">
                                                    Update
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </container-element>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>