import { Attribute } from './../services/attribute-modal';
import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbdSortableHeader } from 'src/app/theme/directives/NgbdSortableHeader';
import { AttributeServices } from '../services/attribute-services';
import { PaginationMetaType, PaginationMetaModel, PaginationType } from 'src/app/core/modals/Pagination';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss']
})
export class AttributeListComponent {

  attributes: any = [];
  displayedColumns = ["attribute_code", "attribute_label", "required", "system", "searchable", "filter", "comparable", "action"];
  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  public searchText;
  total$: Observable<number>;
  public selected = [];
  searchForm: FormGroup;
  // submitted: boolean;
  public validate = false;
  public tooltipValidation = false;
  attributes$: Attribute[] = [];

  sortBy = [{ id: "1", name: "Select" }, { id: "2", name: "Yes" }, { id: 3, name: "No" }]

  constructor(
    private attibute: AttributeServices,
    private fb: FormBuilder) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  async ngOnInit() {
    this.searchForm = this.fb.group({
      attrubute_code: [null, Validators.required],
      attrubute_label: [null, Validators.required],

    });

  }

  ngAfterViewInit() {
    this.getUserList();
  }

  async getUserList() {
    await this.attibute.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.attributes$ = res.data.data;
        this.paginationMeta = res.data.meta;
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getUserList();
  }

  onSearch() {
    this.validate = !this.validate;
    console.log(this.searchForm)
  }

  // async onSelect(id: number) {
  //   const postData = {
  //     id: id,
  //     status: 0,
  //   };
  //   const response = await this.user.delete(postData).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.status == true) {
  //       this.toast.success(res.message);
  //       this.users = this.users.filter(user => user.id !== id);
  //       console.log(this.users);
  //       return this.router.navigate(['/user-management/user']) && res.data;
  //     } else {
  //       this.toast.error(res.errors);
  //       console.log(res.errors);
  //       this.errors = res;
  //       return res;
  //     }
  //   }
  //   );
  //   console.log(response);
  // }
}