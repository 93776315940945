<div class="model">
    <div class="modal-header ">
        <h4 id="modal-basic-title" class="modal-title">Image Cropper</h4>
        <button type="button" aria-label="Close" (click)="onClose()" class="btn-close"></button>
    </div>

    <div class="modal-body">
        <div class="image-container" *ngIf="image">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
                [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
                [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
            </image-cropper>
            <div class="btn-showcase">
                <div class="text-center my-2">
                    <img [src]="croppedImage">
                </div>
                <div class="image-cropper-btn">
                    <input class="btn btn-outline-primary" placeholder="Choose Your Image" type="file"
                        (change)="fileChangeEvent($event)" />
                </div>
                <div>
                    <button class="btn btn-primary" (click)="rotateLeft()">Rotate left</button>
                    <button class="btn btn-primary" (click)="rotateRight()">Rotate right</button>
                    <button class="btn btn-primary" (click)="flipHorizontal()">Flip horizontal</button>
                    <button class="btn btn-primary" (click)="flipVertical()">Flip vertical</button>
                </div>
                <div>
                    <button class="btn btn-primary" (click)="toggleContainWithinAspectRatio()">
                        {{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}
                    </button>
                    <button class="btn btn-primary" (click)="resetImage()">Reset image</button>
                </div>
                <div>
                    <label class="col-form-label" for="rotation">Rotation</label>
                    <input class="btn btn-outline-primary ms-2" [(ngModel)]="rotation" placeholder="Rotation"
                        (keyup)="updateRotation()" type="text" onlyNumbers />
                </div>
                <div>
                    <button class="btn btn-primary" (click)="zoomOut()">Zoom -</button>
                    <button class="btn btn-primary" (click)="zoomIn()">Zoom +</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="confirm()">APPLY</button>
    </div>
</div>