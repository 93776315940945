<div class="container-fluid justfiy-content-center">
  <div class="row  justify-content-center ">
    <div class="col-xl-7">
    </div>
    <div class="">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" href="#">
              <img class="img-fluid for-light " src="assets/images/logo/logo.png" alt="logoicon">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">

            </a>

          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="signupForm" (ngSubmit)="onsubmit()">
              <h4>Admin Login OTP</h4>

              <p>Enter your Email or Mobile no to login</p>
              <div formGroupName="emailGroup">
                <div class="form-group">
                  <label class="col-form-label">Email Address or Mobile No</label>
                  <div class="input-group">
                    <input class="form-control" required="" placeholder="Enter Your Email or Mobile No"
                      (keyup)="validateEmail()" formControlName="mail">
                    <button class="btn btn-primary" type="button" (click)="sendEmailOtp()"
                      [disabled]="!emailButtonEnabled">Send OTP</button>
                  </div><br>


                  <div class="errors" *ngIf=" submitted && f['emailGroup']['controls'].mail.errors">
                    <p class="text-danger" *ngIf="f['emailGroup']['controls'].mail.errors['required']">Email is required
                    </p>
                    <p class="text-danger" *ngIf="f['emailGroup']['controls'].mail.errors['pattern']">Invalid Email</p>

                  </div>
                </div>

                <div class="form-group" *ngIf="emailOtpField">
                  <div class="input-group">
                    <input class="form-control text-center"  maxlength="6"   placeholder="Enter 6 Digit OTP"
                      formControlName="emailOtp">   
                    <button class="btn btn-primary " (click)="verfiyEmailOtp()" >Verify</button>
                    
                    <!-- <input class="form-control text-center " required="" placeholder="Enter 6 Digit OTP" maxlength="6"
                      formControlName="emailotp">
                    
                    <button class="btn btn-primary  " type="button" (click)="verifyEmailOtp()">Verify</button> -->

                  </div>

                  <div class="errors" *ngIf="f['emailGroup']['controls'].emailOtp.errors">
                    <p class="text-danger" *ngIf="f['emailGroup']['controls'].emailOtp.errors['required']">Please Enter
                      OTP</p>
                    <p class="text-danger" *ngIf="f['emailGroup']['controls'].emailOtp.errors['maxlength']">Please Enter
                      OTP</p>
                    <p class="text-danger" *ngIf="f['emailGroup']['controls'].emailOtp.errors['pattern']">Invalid OTP
                    </p>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary d-block w-100" type="submit">Sign in</button>
              <!-- <div class="form-group mb-0">
                <button class="btn btn-primary d-block w-100" type="submit">Sign in</button>
              </div> -->

              <p class="mt-4 mb-0">Problem in Login?
                <a routerLink='/authentication/register/simple' class="ms-2">Contact Us</a>
              </p>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>