import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = true; //window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = false; //true
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [

    {
      title: "Catalog",
      icon: "orders",
      type: "sub",
      active: false,
      children: [
        { path: "/catalog/category", title: "Category", type: "link" },
        { path: "/catalog/attribute", title: "Attributes", type: "link" },
        { path: "/catalog/attribute-family", title: "AttributeFamily", type: "link" },
        { path: "/catalog/attribute-set", title: "Attributes Set", type: "link" },
        { path: "/catalog/catalog-attribute-mapping", title: "Catalog Attribute Mapping", type: "link" },
        { path: "/catalog/product", title: "Product", type: "link" },
        { path: "/catalog/brand", title: "Brand", type: "link" },
        { path: "/catalog/bulk-upload", title: "Bulk Upload", type: "link" },
        { path: "/catalog/product-review", title: "Product Reviews", type: "link" }
      ],
    },
    {
      title: "Quality Control",
      icon: "orders",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Customer Service",
      icon: "orders",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Orders",
      icon: "orders",
      type: "sub",
      active: false,
      children: [
        { path: "/order/", title: "Order List", type: "link" },
      ],
    },
    {
      title: "Payments",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Seller Marketing",
      icon: "marketing",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Marketing",
      icon: "orders",
      type: "sub",
      active: false,
      children: [

        { path: "/marketing/create-sms", title: "SMS", type: "link" },
        { path: "/marketing/create-email", title: "Email", type: "link" },
        { path: "/marketing/create-newsletter", title: "Newsletter", type: "link" },

      ]
    },
    {
      title: "Help Desk",
      icon: "orders",
      type: "sub",
      active: false,
      children: [

        { path: "/help-desk/create-prepared-response", title: "Create Prepared Response", type: "link" },
        { path: "/help-desk/create-saved-replies", title: "Create Saved Replies", type: "link" },
        { path: "/help-desk/create-work-flow", title: "Create Work Flow", type: "link" },
        { path: "/help-desk/create-ticket-type", title: "Create Ticket Type", type: "link" },
        { path: "/help-desk/ticket-view", title: "Ticket View", type: "link" },

        { path: "/help-desk/ticket-dashboard", title: "Ticket Dashboard", type: "link" },
        { path: "/help-desk/ticket-list", title: "Ticket List", type: "link" },


      ]
    },

    {
      title: "User managements",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        { path: "/user-management/user", title: "User", type: "link" },
        { path: "/user-management/rbac", title: "RBAC", type: "link" },
        { path: "/user-management/rbac/roles", title: "Roles", type: "link" },
        { path: "/user-management/rbac/permissions", title: "Permissions", type: "link" }
      ]
    },
    {
      title: "Masters",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        { path: "/masters/state", title: "State", type: "link" },
        { path: "/masters/city", title: "City", type: "link" },
        { path: "/user-management/employee-department", title: "Employee Department", type: "link" },
        { path: "/user-management/employee-designation", title: "Employee Designation", type: "link" }
      ]
    },
    {
      title: "Settings",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        { path: "/settings/", title: "System Settings", type: "link" },
      ]
    },
    {
      title: "CMS",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        { path: "/cms/page-list", title: "pages", type: "link" },
        { path: "/cms/knowledge-center", title: "Knowledge Center", type: "link" },
        { path: "/cms/announcement", title: "Announcement", type: "link" },
        { path: "/cms/flash-announcememt", title: "Flash Announcement", type: "link" },


      ]
    },

    // 
    // {
    //   title: "CMS",
    //   icon: "payment",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/cms", title: "CMS", type: "link" },
    //   ]
    // },
    {
      title: "Menu Master",
      icon: "payment",
      type: "sub",
      active: false,
      children: [
        { path: "/menu-master", title: "Status", type: "link" }
      ]
    },
    {
      title: "Help Center",
      icon: "marketing",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Reports",
      icon: "marketing",
      type: "sub",
      active: false,
    },
    {
      title: "Analytics",
      icon: "marketing",
      type: "sub",
      active: false
    },
    {
      title: "File Management",
      icon: "marketing",
      type: "sub",
      active: false
    },
    {
      title: "Logs",
      icon: "marketing",
      type: "sub",
      active: false,
      children: [
        {}
      ]
    },
    {
      title: "Maintaince Mode",
      icon: "marketing",
      type: "sub",
      active: false
    }
    // {
    //   title: "Users",
    //   icon: "user",
    //   type: "sub",
    //   badgeType: "light-secondary",
    //   badgeValue: "New",
    //   active: false,
    //   children: [
    //     { path: "/user/list", title: "Users", type: "link" },
    //     { path: "/user/rbac/router", title: "Routes", type: "link" },
    //     { path: "/user/rbac/roles", title: "Roles", type: "link" },
    //     { path: "/user/rbac/permission", title: "Permissions", type: "link" },
    //     { path: "/user/assign", title: "RBAC", type: "link" },
    //   ],
    // },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
