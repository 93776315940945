import { Component } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { statusData } from './status-data'

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {


  inputValue: string;
  status = statusData;
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalPages: number = 0;
  pages: number[] = [];
  paginatedList: any[] = [];

  filters = [

    { id: 1, name: "Catalog ", },
    { id: 2, name: "Customer", },
    { id: 3, name: "Service", },
    { id: 4, name: "Orders", },
    { id: 5, name: "Payment", },
    { id: 6, name: "Seller Marketing", },
    { id: 7, name: " Marketplace Management", },
    { id: 8, name: "User Management", },
    { id: 9, name: "Settings", },
    { id: 10, name: "Content", },
    { id: 11, name: "Marketplace Management", },
    { id: 12, name: "Product", },
    { id: 13, name: "Reports", },
    { id: 14, name: "Analytics", },
    { id: 15, name: "File Management", },
    { id: 16, name: "Logs", },
    { id: 17, name: "Shipping", },
    { id: 18, name: "Invoice", },
  ];
  selectedValue: string = "Product Name";
  // setValue() {
  //   this.selectedValue = this.form.get("selectedValue")?.value;
  //   console.log(this.selectedValue);
  // }

  submitted: boolean = false;
  submited: boolean = false;

  constructor(private fb: FormBuilder,) {
    this.totalPages = Math.ceil(this.status.length / this.itemsPerPage);
    this.generatePages();
    this.updatePaginatedCategories();
  }

  form: FormGroup;

  setValue() {
    this.submited = false; // Reset the submited flag
  }
  ngOnInit() {

    this.form = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
      group: ['', Validators.required],

    });
  }
  // submitted = false;
  selectedLogo: File;

  handleLogoUpload(event: any) {
    this.selectedLogo = event.target.files[0];
  }

  // uploadLogo() {
  //   if (!this.selectedLogo) {
  //     console.log('No logo selected.');
  //     return;
  //   }
  // }
  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      console.log('Form submitted successfully');
    } else {

      console.log('Form contains validation errors');
    }
  }

  get f() {
    return this.form.controls;
  }


  // Pagination Logic Start Here
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedCategories();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedCategories();
    }
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedCategories();
    }
  }

  generatePages() {
    this.pages = Array(this.totalPages).fill(0).map((x, i) => i + 1);
  }

  updatePaginatedCategories() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedList = this.status.slice(startIndex, endIndex);
  }
  // Pagination Logic Ends Here
}

