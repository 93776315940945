import { AttributeModule } from './attribute/attribute.module';
import { CatalogRoutingModule } from './catalog-routing.module';
import { CategoryModule } from './category/category.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandModule } from '../catalog/brand/brand.module';
import { ThemeModule } from './../../theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AttributeMappingComponent } from './attribute-mapping/attribute-mapping.component';
import { AttributeFamilyModule } from './attribute-family/attribute-family.module';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { ProductReviewComponent } from './product-review/product-review.component';

@NgModule({
  declarations: [  
    AttributeMappingComponent, 
    BulkUploadComponent,
    ProductReviewComponent
  ],
  imports: [
    CategoryModule,
    AttributeModule,
    AttributeFamilyModule,
    CommonModule,
    CatalogRoutingModule,
    BrandModule,
    ThemeModule,
    ReactiveFormsModule,
    //RouterModule.forChild(routes)
  ]
})
export class CatalogModule { }
