<div class="container-fluid">
    <div class="row">
        <h3 class="mt-3"> State Master</h3>
        <div class="col-lg-4">

            <div class="card">
                <div class="card-body  ">

                    <!-- Add settings Start here  -->
                    <form [formGroup]="stateCreateForm">
                        <h4>{{ stateId ? 'Edit State' : 'Add State'}}</h4>
                        <div class="form-group mb-2">
                            <label>State Name</label>
                            <input class="form-control" id="name" type="text" placeholder="Enter State"
                                formControlName="name" onlyAlphabets />
                            <div class="errors mb-0" *ngIf="submitted && stateCreateForm.get('name').errors">
                                <p class="text-danger mb-0" *ngIf="stateCreateForm.get('name').errors.required">
                                    Name is required
                                </p>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                           
                            <label>Country</label>
                            <div class="input-group">
                                <ng-select [items]="countryList" bindValue="country_id" [clearable]="false" bindLabel="name"
                                    class="custom col-12" formControlName="country_id" placeholder="Select Country"></ng-select>
                            </div>
                            <!-- <input class="form-control" id="country_id" type="text" placeholder="Enter Value"
                                formControlName="country_id" onlyNumbers /> -->
                            <div class="errors mb-0" *ngIf="submitted && stateCreateForm.get('country_id').errors">
                                <p class="text-danger mb-0" *ngIf="stateCreateForm.get('country_id').errors.required">
                                    Country Id is required
                                </p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 text-end">
                                <button class="btn btn-md btn-primary" type="submit" (click)="onSubmit()">Save</button>

                            </div>
                        </div>
                    </form>
                    <!-- Add settings Ends here  -->
                </div>
            </div>
        </div>
        <div class="col-lg-8">

            <div class="card">

                <div class="card-header pt-1">
                    <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label">Search</label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="search" placeholder="igeet">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label class="col-form-label">Sort By</label>
                                    <div class="input-group">
                                        <ng-select [items]="sortBy" bindValue="value" [clearable]="false"
                                            bindLabel="name" class="custom col-12" formControlName="sortBy"
                                            placeholder="Select sort order" formControlName="sort"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center mt-3">
                                    <a class="btn btn-secondary mx-2" (click)="onClear()">Clear</a>
                                    <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div *ngIf="state.length > 0">
                    <div class="card-body">
                        <h4>State List</h4>
                        <mat-table class="" [dataSource]="state" matSort matSortActive="id" matSortDirection="asc"
                            matSortDisableClear>

                            <ng-container matColumnDef="slNo">
                                <mat-header-cell *matHeaderCellDef>Sl.No</mat-header-cell>
                                <mat-cell class="slNo-cell" *matCellDef="let state; let i = index">{{i+1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>State Name</mat-header-cell>
                                <mat-cell class="name-cell" *matCellDef="let state">{{state.name}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="country_id">
                                <mat-header-cell *matHeaderCellDef>Country Id</mat-header-cell>
                                <mat-cell class="country_id-cell"
                                    *matCellDef="let state"> {{getCountryName(state.country_id)}}</mat-cell>
                                    <!-- {{state.country_id}} -->
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                <mat-cell class="action" *matCellDef="let state">
                                    <li class="edit me-3">
                                        <a [routerLink]="['/masters/state/edit', state.id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li class="delete" (click)="onSelect(state.id)">
                                        <a>
                                            <i class="icon-trash"></i>
                                        </a>
                                    </li>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                            <mat-row *matRowDef="let row; columns: displayedColumns"
                                (click)="onRowClicked(row)"></mat-row>

                        </mat-table>
                    </div>
                    <div class="card-footer">
                        <mat-paginator [length]="paginationMeta.totalItems" [pageIndex]=0
                            [pageSize]="paginationMeta.itemsPerPage" [pageSizeOptions]="[5, 10, 20, 50]"
                            (page)="pageEvent = getServerData($event)" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>