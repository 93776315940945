import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-create-new-announcement',
  templateUrl: './create-new-announcement.component.html',
  styleUrls: ['./create-new-announcement.component.scss']
})
export class CreateNewAnnouncementComponent implements OnInit {

  [x: string]: any;

  submited: boolean = false;
  myForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      title: ['', Validators.required],
      link: ['', Validators.required],

      image: ['', Validators.required],
      folderType: ['', Validators.required],
      folder: ['', Validators.required],
      userGroup: ['', Validators.required],

      status: ['', Validators.required],


    });
  }
  // filters = [

  //   { id: 1, name: "Folder 1", },
  //   { id: 2, name: "Folder 2", },
  //   { id: 3, name: "Folder 3", },
  //   { id: 4, name: "Folder 4", },
  //   { id: 5, name: "Folder 5", },

  // ];
  onSubmit() {
    this.submitted = true;
    if (this.myForm.valid) {
      console.log('Form submitted successfully');
    } else {

      console.log('Form contains validation errors');
    }
  }

  get f() {
    return this.myForm.controls;
  }


  inputValue: string;


  // openModal() {
  //   const modalRef = this.modalService.open(CreateUnitComponent);
  //   modalRef.componentInstance.inputValue = this.inputValue;
  // }


  editor = ClassicEditor;
  data: any = ``;
  //   data: any = `< p > Hello, world!</p>`;

  config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      'fontFamily',
      'fontSize',
      '|',
      'bold',
      'italic',
      'underline',
      'fontColor',
      'fontBackgroundColor',
      'highlight',
      '|',
      'link',
      'CKFinder',
      'imageUpload',
      'mediaEmbed',
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'blockQuote',
      'specialCharacters'
    ],
    language: 'id',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:full',
        'imageStyle:side'
      ]
    },
  }

}



