<div class="container-fluid pt-3">
    <h4>Attribute List</h4>
    <div class="col-12">
        <div class="card" *ngIf="attributes$">
            <div class="card-header">
                <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-6">
                            <label class="form-label" for="validationCustom01">Attribute Code </label>
                            <input class="form-control" id="validationCustom01" formControlName="attrubute_code"
                                type="text" required="" onlyAlphabets />
                            <div class="valid-feedback">Looks good!</div>
                        </div>
                        <div class="col-6">
                            <label class="form-label" for="validationCustom02">Attribute label </label>
                            <input class="form-control" id="validationCustom02" formControlName="attrubute_label"
                                type="text" required="" onlyAlphabets />
                            <div class="valid-feedback">Looks good!</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label">Mandatory</label>
                            <div class="input-group">
                                <ng-select [items]="sortBy" [clearable]="false" bindLabel="name" class="custom col-12"
                                    placeholder="Select status"></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <label class="form-label">System</label>
                            <div class="input-group">
                                <ng-select [items]="sortBy" [clearable]="false" bindLabel="name" class="custom col-12"
                                    placeholder="Select status"></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <label class="form-label">Searchable</label>
                            <div class="input-group">
                                <ng-select [items]="sortBy" [clearable]="false" bindLabel="name" class="custom col-12"
                                    placeholder="Select status"></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <label class="form-label">Filter</label>
                            <div class="input-group">
                                <ng-select [items]="sortBy" [clearable]="false" bindLabel="name" class="custom col-12"
                                    placeholder="Select status"></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <label class="form-label">Comparable</label>
                            <div class="input-group">
                                <ng-select [items]="sortBy" [clearable]="false" bindLabel="name" class="custom col-12"
                                    placeholder="Select status"></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center mt-3">
                            <a class="btn btn-secondary mx-2">Clear</a>
                            <a class="btn btn-primary mx-2" (click)="onSearch()">Search</a>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-header">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-9 text-end">
                        <a class="btn btn-lg  btn-primary" routerLink="/catalog/attribute/create">Add Attribute </a>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <mat-table class="" [dataSource]="attributes$" matSort matSortActive="id" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="attribute_code">
                        <mat-header-cell *matHeaderCellDef>Attribute Code</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.code}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attribute_label">
                        <mat-header-cell *matHeaderCellDef>Attribute Label</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.translations[1].name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="required">
                        <mat-header-cell *matHeaderCellDef>Required</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.is_required}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="system">
                        <mat-header-cell *matHeaderCellDef>System</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.is_user_defined}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="searchable">
                        <mat-header-cell *matHeaderCellDef>Searchable</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.is_searchable}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="filter">
                        <mat-header-cell *matHeaderCellDef>Filter</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.is_filterable}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="comparable">
                        <mat-header-cell *matHeaderCellDef>Comparable</mat-header-cell>
                        <mat-cell *matCellDef="let attribute">{{attribute.is_comparable}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                        <mat-cell class="action-cell" *matCellDef="let attribute">
                            <div class="action">
                                <li class="edit me-3">
                                    <a [routerLink]="['/catalog/attribute/edit', attribute.attribute_id]">
                                        <i class="icon-pencil-alt"></i>
                                    </a>
                                </li>
                                <li class="delete" (click)="onSelect(attribute.id)" [hidden]="attribute.is_user_defined == 0">
                                    <a>
                                        <i class="icon-trash"></i>
                                    </a>
                                </li>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
            </div>
            <div class="card-footer">
                <mat-paginator [length]="paginationMeta.totalItems" [pageIndex]=0
                    [pageSize]="paginationMeta.itemsPerPage" [pageSizeOptions]="[5, 10, 20, 50]"
                    (page)="pageEvent = getServerData($event)" [showFirstLastButtons]="true"></mat-paginator>
            </div>
        </div>
    </div>
</div>