<div class="container-fluid ">
    <div class="row ">
        <div class="card mt-3 p-0 mx-0">
            <div class="card-body m-0 p-0">
                <div class="px-4 pt-4 ">
                    <h5>Knowledge Center</h5>

                    <hr>
                    <div class="px-2 ">
                        <ul #nav="ngbNav" ngbNav class="nav-tabs nav">
                            <li [ngbNavItem]="1" class="mx-1">
                                <a ngbNavLink class="nav-link">
                                    Folders
                                </a>
                                <ng-template ngbNavContent>

                                    <form [formGroup]="myForm">
                                        <div class="form-group media row p-0 ">

                                            <div class="col-12">
                                                <div class="form-group media row ">
                                                    <div class="input-group media-body">
                                                        <label class="col-form-label col-3">
                                                            <ng-select [items]="filters" (click)="setValue()"
                                                                class="custom" bindValue="name" [clearable]="false"
                                                                bindLabel="name"
                                                                formControlName="selectedValue"></ng-select>
                                                        </label>
                                                        <label class="col-form-label col-9 ">
                                                            <input [hidden]="selectedValue!= item.name"
                                                                *ngFor="let item of filters" type="search"
                                                                class="form-control pb-1 round"
                                                                placeholder="{{item.ph}}" style="max-width: 99%;" />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="row p-0 mt-2">
                                                <div class="col-6 p-0">
                                                    <div class="input-group row">
                                                        <div class=" col-2 text-start"
                                                            style="padding-top: 5px;padding-left: 37px;">
                                                            <label style="margin-top: 2px;">Sorting</label>
                                                        </div>
                                                        <div class=" col-10 p-0">
                                                            <select class="form-select form-select-md text-dark"
                                                                aria-label=".form-select-md example">
                                                                <!--          formControlName="sorting" -->
                                                                <option disabled selected value="" class="text-muted">
                                                                    Select Sorting
                                                                </option>
                                                                <option value="1">one</option>
                                                                <option value="2">two</option>
                                                                <option value="3">three</option>
                                                                <option value="3">four</option>
                                                            </select>

                                                            <!-- <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                                Sorting is required
                                                            </p>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 p-0 ">
                                                    <div class="input-group row">
                                                        <div class="col-4 text-end px-0;pe-1" style="padding-top: 5px;">
                                                            <label for="dateRangePicker ">Select Date Range</label>
                                                        </div>
                                                        <div class="col-8 px-0">
                                                            <input type="date" id="dateRangePicker"
                                                                class="form-control">
                                                            <!--   <input type="daterange" class="form-control" id="dateRangePicker"
                                                            [options]="dateRangePickerOptions"
                                                            (selected)="onDateRangeSelected($event)" /> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="text-center mt-1">


                                            <a class="btn btn-light font-primary mt-1">Clear</a>
                                            <a class="btn btn-primary mt-1 ms-2">Search</a>
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="row mt-0">
                                        <div class="col-10 text-end mb-2 pt-0">
                                            <div ngbDropdown placement="bottom" class="m-r-10">
                                                <button class="btn btn-secondary text-dark bg-light" id="dropdownBasic1"
                                                    ngbDropdownToggle>
                                                    <span class="text-center ms-2">Sort by</span> <i
                                                        class="fa fa-down"></i>
                                                </button>
                                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1" class="sortList"
                                                    style="z-index: 100;min-width: 100px;">
                                                    <button class="dropdown-item">All</button>
                                                    <button class="dropdown-item">Live</button>
                                                    <button class="dropdown-item">Draft</button>
                                                </span>
                                            </div>
                                        </div>










                                        <div class="col-2 text-end">
                                            <button class="btn btn-primary mb-2 m-r-5" routerLink="/cms/create-folder">
                                                Create New Folder
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <table class="table">
                                            <thead class="bg-light text-dark">
                                                <tr>
                                                    <th class="text-center">Folder Details</th>
                                                    <th class="text-center">Categories</th>
                                                    <th class="text-center">Article</th>
                                                    <th class="text-center">User Group</th>
                                                    <th class="text-center">Created On</th>

                                                    <th class="text-center">Updated On</th>
                                                    <th class="text-center">Updated by</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let folder of paginatedList; let i=index text-center">
                                                    <td class="text-center">
                                                        <div class="row">
                                                            <div class="col-5 text-end"> <img src="{{ folder.img }}"
                                                                    style="height: 40px; width: 40px; padding:3px"
                                                                    class="border bg-light text-dark">

                                                            </div>
                                                            <div class="col-7 text-start p-0"> <span
                                                                    style="margin-top: -10px;">{{
                                                                    folder.folderDetails }}</span></div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center"> {{ folder.categories}}</td>
                                                    <td class="text-center">{{ folder.article }}</td>
                                                    <!-- <td class="text-center"> -->
                                                    <!-- <td class="text-center"> -->
                                                    <!-- <div [ngStyle]="{ 'color': getColor(i) }">
                                                            {{ folder.userGroup }}
                                                        </div> -->
                                                    <!-- <div *ngFor="let group of userGroup; let i = index"
                                                            [ngStyle]="{ 'color': colors[i] }">
                                                            {{ group }}
                                                        </div> -->



                                                    <td class="text-center">
                                                        <div *ngFor="let userGroup of folder.userGroup; let j = index"
                                                            class="badge" [ngClass]="getBadgeClass(j)">
                                                            {{ userGroup }}
                                                        </div>
                                                    </td>


                                                    <!-- </td> -->


                                                    <!-- <div *ngFor="let user of userGroup">

                                                            <div class=" "
                                                                [ngStyle]="{'background-color':user==='admin'? 'blue':user==='seller'? 'green': ''}">
                                                                {{ user.userGroup }}
                                                            </div>
                                                        </div>
 -->

                                                    <!-- </td> -->
                                                    <td class="text-center"> {{ folder.createdOn}}</td>

                                                    <td class="text-center ">
                                                        {{ folder.updatedOn}}
                                                    </td>
                                                    <td class="text-center ">
                                                        {{ folder.updatedBy}}
                                                    </td>
                                                    <td class="text-center">
                                                        <div class="badge-green">{{ folder.status }}</div>
                                                    </td>



                                                    <td class="text-center">


                                                        <div class="d-flex justify-content-center action">
                                                            <a href="#" class="ms-1 trash-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                    height="20" viewBox="0 0 36 36"
                                                                    style="padding-top:1px ;">
                                                                    <path fill="currentColor"
                                                                        d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                                                                        class="clr-i-outline clr-i-outline-path-1" />
                                                                    <path fill="currentColor"
                                                                        d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                                                                        class="clr-i-outline clr-i-outline-path-2" />
                                                                    <path fill="none" d="M0 0h36v36H0z" />
                                                                </svg></a>
                                                            <a href="#" class="ms-1 pencil-icon"><i
                                                                    class="icon-pencil-alt"></i></a>

                                                            <a href="#" class="ms-1 trash-icon"><i
                                                                    class="icon-trash"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- Pagination Start Here  -->

                                        <div class=" row mt-3 mb-2">


                                            <div class="col-2">

                                                <select class="form-select" [(ngModel)]="itemsPerPage"
                                                    (ngModelChange)="changeItemsPerPage()">
                                                    <option value="2">2 items</option>
                                                    <option value="4">4 items</option>
                                                    <option value="6">6 items</option>
                                                </select>
                                            </div>

                                            <div class="col-10 text-end mb-3">
                                                <nav *ngIf="totalPages > 1">
                                                    <ul class="pagination justify-content-end">
                                                        <li class="page-item" [class.disabled]="currentPage === 1">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="previousPage()">Previous</a>
                                                        </li>
                                                        <li class="page-item" [class.active]="currentPage === page"
                                                            *ngFor="let page of pages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="goToPage(page)">{{ page }}</a>
                                                        </li>
                                                        <li class="page-item"
                                                            [class.disabled]="currentPage === totalPages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="nextPage()">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>

                            </li>

                            <li [ngbNavItem]="2" class="mx-1">
                                <a ngbNavLink class="nav-link">
                                    Category
                                </a>
                                <ng-template ngbNavContent>

                                    <form [formGroup]="myForm">

                                        <!-- <h5 class="mt-3">Category</h5> -->
                                        <div class="form-group media row p-0 ">

                                            <div class="col-12">
                                                <div class="form-group media row ">
                                                    <div class="col-12">
                                                        <div class="form-group media row ">
                                                            <div class="input-group media-body">
                                                                <label class="col-form-label col-3">
                                                                    <ng-select [items]="filters" (click)="setValue()"
                                                                        class="custom" bindValue="name"
                                                                        [clearable]="false" bindLabel="name"
                                                                        formControlName="selectedValue"></ng-select>
                                                                </label>
                                                                <label class="col-form-label col-9 ">
                                                                    <input [hidden]="selectedValue!= item.name"
                                                                        *ngFor="let item of filters" type="search"
                                                                        class="form-control pb-1 round"
                                                                        placeholder="{{item.ph}}"
                                                                        style="max-width: 99%;" />

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row p-0 mt-2">
                                                <div class="col-6 p-0">
                                                    <div class="input-group row">
                                                        <div class=" col-2 text-start"
                                                            style="padding-top: 5px;padding-left: 37px;">
                                                            <label style="margin-top: 2px;">Sorting</label>
                                                        </div>
                                                        <div class=" col-10 p-0">
                                                            <select class="form-select form-select-md text-dark"
                                                                aria-label=".form-select-md example">
                                                                <!--          formControlName="sorting" -->
                                                                <option disabled selected value="" class="text-muted">
                                                                    Select Sorting
                                                                </option>
                                                                <option value="1">one</option>
                                                                <option value="2">two</option>
                                                                <option value="3">three</option>
                                                                <option value="3">four</option>
                                                            </select>

                                                            <!-- <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                                Sorting is required
                                                            </p>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 p-0 ">
                                                    <div class="input-group row">
                                                        <div class="col-4 text-end px-0;pe-1" style="padding-top: 5px;">
                                                            <label for="dateRangePicker ">Select Date Range</label>
                                                        </div>
                                                        <div class="col-8 px-0">
                                                            <input type="date" id="dateRangePicker"
                                                                class="form-control">
                                                            <!--   <input type="daterange" class="form-control" id="dateRangePicker"
                                                            [options]="dateRangePickerOptions"
                                                            (selected)="onDateRangeSelected($event)" /> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="text-center mt-1">

                                            <a class="btn btn-light font-primary mt-1">Clear</a>
                                            <a class="btn btn-primary mt-1 ms-2">Search</a>
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="row mt-0">
                                        <div class="col-10 text-end mb-2 pt-0">
                                            <div ngbDropdown placement="bottom" class="m-r-10">
                                                <button class="btn btn-secondary text-dark bg-light" id="dropdownBasic1"
                                                    ngbDropdownToggle>
                                                    <span class="text-center ms-2">Sort by</span> <i
                                                        class="fa fa-down"></i>
                                                </button>
                                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1" class="sortList"
                                                    style="z-index: 100;min-width: 100px;">
                                                    <button class="dropdown-item">All</button>
                                                    <button class="dropdown-item">Live</button>
                                                    <button class="dropdown-item">Draft</button>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-2 text-end">
                                            <button class="btn btn-primary mb-2 m-r-5"
                                                routerLink="/cms/create-category">
                                                Create New Category
                                            </button>
                                        </div>
                                    </div>




                                    <div>
                                        <table class="table">
                                            <thead class="bg-light text-dark">
                                                <tr>
                                                    <th class="text-center">Category Details</th>
                                                    <!-- <th class="text-center">Categories</th> -->
                                                    <th class="text-center">Folder</th>

                                                    <th class="text-center">User Group</th>
                                                    <th class="text-center">Created On</th>

                                                    <th class="text-center">Updated On</th>
                                                    <th class="text-center">Updated by</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>





                                                <tr
                                                    *ngFor="let category of paginatedCategoryList; let i=index text-center">
                                                    <td class="text-center">

                                                        <!-- <div class="col-5 text-end"> <img src="{{ category.img }}"
                                                                    style="height: 40px; width: 40px; padding:3px"
                                                                    class="border bg-light text-dark">

                                                            </div> -->
                                                        <span style="margin-top: -10px;">{{
                                                            category.categoryDetails}}</span>

                                                    </td>
                                                    <td class="text-center"> {{ category.folder}}</td>


                                                    <td class="text-center">
                                                        <div *ngFor="let userGroup of category.userGroup; let j = index"
                                                            class="badge" [ngClass]="getBadgeClass(j)">
                                                            {{ userGroup }}
                                                        </div>
                                                    </td>


                                                    <td class="text-center"> {{ category.createdOn}}</td>
                                                    <td class="text-center"> {{ category.updatedOn}}</td>
                                                    <td class="text-center"> {{ category.updatedBy}}</td>
                                                    <td class="text-center">
                                                        <div class="badge-green"> {{ category.status }}</div>
                                                    </td>
                                                    <td class="text-center">


                                                        <div class="d-flex justify-content-center action">
                                                            <a href="#" class="ms-1 trash-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                    height="20" viewBox="0 0 36 36"
                                                                    style="padding-top:1px ;">
                                                                    <path fill="currentColor"
                                                                        d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                                                                        class="clr-i-outline clr-i-outline-path-1" />
                                                                    <path fill="currentColor"
                                                                        d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                                                                        class="clr-i-outline clr-i-outline-path-2" />
                                                                    <path fill="none" d="M0 0h36v36H0z" />
                                                                </svg></a>
                                                            <a href="#" class="ms-1 pencil-icon"><i
                                                                    class="icon-pencil-alt"></i></a>

                                                            <a href="#" class="ms-1 trash-icon"><i
                                                                    class="icon-trash"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- Pagination Start Here  -->




                                        <div class=" row mt-3 mb-2">


                                            <div class="col-2">

                                                <select class="form-select" [(ngModel)]="itemsPerPage"
                                                    (ngModelChange)="changeItemsPerPage()">
                                                    <option value="2">2 items</option>
                                                    <option value="4">4 items</option>
                                                    <option value="6">6 items</option>
                                                </select>
                                            </div>

                                            <div class="col-10 text-end mb-3">
                                                <nav *ngIf="totalPages > 1">
                                                    <ul class="pagination justify-content-end">
                                                        <li class="page-item" [class.disabled]="currentPage === 1">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="previousPage()">Previous</a>
                                                        </li>
                                                        <li class="page-item" [class.active]="currentPage === page"
                                                            *ngFor="let page of pages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="goToPage(page)">{{ page }}</a>
                                                        </li>
                                                        <li class="page-item"
                                                            [class.disabled]="currentPage === totalPages">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                (click)="nextPage()">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </div>
                                        <!-- Pagination Ends Here  -->
                                    </div>



                                </ng-template>

                            </li>


                            <li [ngbNavItem]="3" class="mx-1">
                                <a ngbNavLink class="nav-link">
                                    Article
                                </a>
                                <ng-template ngbNavContent>

                                    <form [formGroup]="myForm">

                                        <div class="form-group media row p-0 ">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <div class="form-group media row ">
                                                        <div class="input-group media-body">
                                                            <label class="col-form-label col-3">
                                                                <ng-select [items]="filters" (click)="setValue()"
                                                                    class="custom" bindValue="name" [clearable]="false"
                                                                    bindLabel="name"
                                                                    formControlName="selectedValue"></ng-select>
                                                            </label>
                                                            <label class="col-form-label col-9 ">
                                                                <input [hidden]="selectedValue!= item.name"
                                                                    *ngFor="let item of filters" type="search"
                                                                    class="form-control pb-1 round"
                                                                    placeholder="{{item.ph}}" style="max-width: 99%;" />

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="row p-0 mt-2">
                                                <div class="col-6 p-0">
                                                    <div class="input-group row">
                                                        <div class=" col-2 text-start"
                                                            style="padding-top: 5px;padding-left: 37px;">
                                                            <label style="margin-top: 2px;">Sorting</label>
                                                        </div>
                                                        <div class=" col-10 p-0">
                                                            <select class="form-select form-select-md text-dark"
                                                                aria-label=".form-select-md example">
                                                                <!--          formControlName="sorting" -->
                                                                <option disabled selected value="" class="text-muted">
                                                                    Select Sorting
                                                                </option>
                                                                <option value="1">one</option>
                                                                <option value="2">two</option>
                                                                <option value="3">three</option>
                                                                <option value="3">four</option>
                                                            </select>

                                                            <!-- <div class="errors mb-0" *ngIf="submitted && myForm.get('sorting').errors">
                                                            <p class="text-danger mb-0" *ngIf="myForm.get('sorting').errors.required">
                                                                Sorting is required
                                                            </p>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 p-0 ">
                                                    <div class="input-group row">
                                                        <div class="col-4 text-end px-0;pe-1" style="padding-top: 5px;">
                                                            <label for="dateRangePicker ">Select Date Range</label>
                                                        </div>
                                                        <div class="col-8 px-0">
                                                            <input type="date" id="dateRangePicker"
                                                                class="form-control">
                                                            <!--   <input type="daterange" class="form-control" id="dateRangePicker"
                                                            [options]="dateRangePickerOptions"
                                                            (selected)="onDateRangeSelected($event)" /> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="text-center mt-1">

                                            <a class="btn btn-light font-primary mt-1">Clear</a>
                                            <a class="btn btn-primary mt-1 ms-2">Search</a>
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="row mt-0">
                                        <div class="col-10 text-end mb-2 pt-0">
                                            <div ngbDropdown placement="bottom" class="m-r-10">
                                                <button class="btn btn-secondary text-dark bg-light" id="dropdownBasic1"
                                                    ngbDropdownToggle>
                                                    <span class="text-center ms-2">Sort by</span> <i
                                                        class="fa fa-down"></i>
                                                </button>
                                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1" class="sortList"
                                                    style="z-index: 100;min-width: 100px;">
                                                    <button class="dropdown-item">All</button>
                                                    <button class="dropdown-item">Live</button>
                                                    <button class="dropdown-item">Draft</button>
                                                </span>
                                            </div>
                                        </div>



                                        <div class="col-2 text-end">
                                            <button class="btn btn-primary mb-2 m-r-5" routerLink="/cms/create-article">
                                                Create New Article
                                            </button>
                                        </div>
                                    </div>


                                    <table class="table">
                                        <thead class="bg-light text-dark">
                                            <tr>
                                                <th class="text-center">Article Details</th>
                                                <th class="text-center">Categories</th>
                                                <th class="text-center">Tags</th>
                                                <th class="text-center">Viewed</th>
                                                <th class="text-center">Helpful</th>
                                                <th class="text-center">Not helpful</th>
                                                <th class="text-center">Created On</th>
                                                <th class="text-center">Updated On</th>
                                                <th class="text-center">Updated By</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let article of paginatedArticleList; let i=index text-center">



                                                <td class="text-center">
                                                    <div class="row">
                                                        <!-- <div class="col-5 text-end"> <img src="{{ category.img }}"
                                                                style="height: 40px; width: 40px; padding:3px"
                                                                class="border bg-light text-dark">

                                                        </div> -->
                                                        <div class="col-12 text-center p-0">
                                                            <span style="margin-top: -10px;">{{
                                                                article.articleDetails }}</span>
                                                        </div>
                                                    </div>
                                                </td>


                                                <td class="text-center">
                                                    <div class="badge-prim">{{article.category}}</div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="badge-dang">{{ article.tags }}</div>
                                                </td>
                                                <td class="text-center"> {{ article.viewed}}</td>
                                                <td class="text-center"> {{ article.helpful }}</td>
                                                <td class="text-center ">
                                                    {{ article.notHelpful}}
                                                </td>
                                                <td class="text-center ">
                                                    {{ article.createdOn}}
                                                </td>
                                                <td class="text-center">{{ article.updatedOn }}</td>
                                                <td class="text-center">{{ article.updatedBy }}</td>
                                                <td class="text-center">
                                                    <div class="badge-green">{{ article.status }}</div>
                                                </td>



                                                <td class="text-center">
                                                    <div class="d-flex justify-content-center action">
                                                        <a href="#" class="ms-1 trash-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                height="20" viewBox="0 0 36 36"
                                                                style="padding-top:1px ;">
                                                                <path fill="currentColor"
                                                                    d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                                                                    class="clr-i-outline clr-i-outline-path-1" />
                                                                <path fill="currentColor"
                                                                    d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                                                                    class="clr-i-outline clr-i-outline-path-2" />
                                                                <path fill="none" d="M0 0h36v36H0z" />
                                                            </svg></a>
                                                        <a href="#" class="ms-1 pencil-icon"><i
                                                                class="icon-pencil-alt"></i></a>

                                                        <a href="#" class="ms-1 trash-icon"><i
                                                                class="icon-trash"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- Pagination Start Here  -->

                                    <div class=" row mt-3 mb-2">

                                        <div class="col-2">

                                            <select class="form-select" [(ngModel)]="itemsPerPageArticle"
                                                (ngModelChange)="changeItemsPerPageArticle()">
                                                <option value="2">2 items</option>
                                                <option value="4">4 items</option>
                                                <option value="6">6 items</option>
                                            </select>
                                        </div>

                                        <div class="col-10 text-end ">
                                            <nav *ngIf="totalPagesArticle > 1" class="mt-3 mb-2">

                                                <ul class="pagination justify-content-end">
                                                    <li class="page-item" [class.disabled]="currentPageArticle === 1">
                                                        <a class="page-link" href="javascript:void(0)"
                                                            (click)="previousPageArticle()">Previous</a>
                                                    </li>

                                                    <li class="page-item" [class.active]="currentPageArticle === page"
                                                        *ngFor="let page of articlepages">
                                                        <a class="page-link" href="javascript:void(0)"
                                                            (click)="goToPageArticle(page)">{{ page }}</a>
                                                    </li>

                                                    <li class="page-item"
                                                        [class.disabled]="currentPageArticle === totalPagesArticle">
                                                        <a class="page-link" href="javascript:void(0)"
                                                            (click)="nextPageArticle()">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </div>
                                    </div>
                                    <!-- Pagination Ends Here  -->

                                </ng-template>
                            </li>


                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2 col-sm-12"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>