export const CategoryDB = {
    first_level: [
        {
            "path": [
                "Home Appliances"
            ],
            "spell": "Home Appliances",
            "idpath": [
                
            ],
            "name": "Home Appliances",
            "id": 6,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office"
            ],
            "spell": "Computer & Office",
            "idpath": [
                7
            ],
            "name": "Computer & Office",
            "id": 7,
            "leaf": false
        },
        {
            "path": [
                "Home Improvement"
            ],
            "spell": "Home Improvement",
            "idpath": [
                13
            ],
            "name": "Home Improvement",
            "id": 13,
            "leaf": false
        },
        {
            "path": [
                "Home & Garden"
            ],
            "spell": "Home & Garden",
            "idpath": [
                15
            ],
            "name": "Home & Garden",
            "id": 15,
            "leaf": false
        },
        {
            "path": [
                "Sports & Entertainment"
            ],
            "spell": "Sports & Entertainment",
            "idpath": [
                18
            ],
            "name": "Sports & Entertainment",
            "id": 18,
            "leaf": false 
        },
        {
            "path": [
                "Office & School Supplies"
            ],
            "spell": "Office & School Supplies",
            "idpath": [
                21
            ],
            "name": "Office & School Supplies",
            "id": 21,
            "leaf": false
        },
        {
            "path": [
                "Toys & Hobbies"
            ],
            "spell": "Toys & Hobbies",
            "idpath": [
                26
            ],
            "name": "Toys & Hobbies",
            "id": 26,
            "leaf": false
        },
        {
            "path": [
                "Security & Protection"
            ],
            "spell": "Security & Protection",
            "idpath": [
                30
            ],
            "name": "Security & Protection",
            "id": 30,
            "leaf": false
        },
        {
            "path": [
                "Automobiles, Parts & Accessories"
            ],
            "spell": "Automobiles, Parts & Accessories",
            "idpath": [
                34
            ],
            "name": "Automobiles, Parts & Accessories",
            "id": 34,
            "leaf": false
        },
        {
            "path": [
                "Jewelry & Accessories"
            ],
            "spell": "Jewelry & Accessories",
            "idpath": [
                36
            ],
            "name": "Jewelry & Accessories",
            "id": 36,
            "leaf": false
        },
        {
            "path": [
                "Lights & Lighting"
            ],
            "spell": "Lights & Lighting",
            "idpath": [
                39
            ],
            "name": "Lights & Lighting",
            "id": 39,
            "leaf": false
        },
        {
            "path": [
                "Consumer Electronics"
            ],
            "spell": "Consumer Electronics",
            "idpath": [
                44
            ],
            "name": "Consumer Electronics",
            "id": 44,
            "leaf": false
        },
        {
            "path": [
                "Beauty & Health"
            ],
            "spell": "Beauty & Health",
            "idpath": [
                66
            ],
            "name": "Beauty & Health",
            "id": 66,
            "leaf": false
        },
        {
            "path": [
                "Weddings & Events"
            ],
            "spell": "Weddings & Events",
            "idpath": [
                320
            ],
            "name": "Weddings & Events",
            "id": 320,
            "leaf": false
        },
        {
            "path": [
                "Shoes"
            ],
            "spell": "Shoes",
            "idpath": [
                322
            ],
            "name": "Shoes",
            "id": 322,
            "leaf": false
        },
        {
            "path": [
                "Electronic Components & Supplies"
            ],
            "spell": "Electronic Components & Supplies",
            "idpath": [
                502
            ],
            "name": "Electronic Components & Supplies",
            "id": 502,
            "leaf": false
        },
        {
            "path": [
                "Phones & Telecommunications"
            ],
            "spell": "Phones & Telecommunications",
            "idpath": [
                509
            ],
            "name": "Phones & Telecommunications",
            "id": 509,
            "leaf": false
        },
        {
            "path": [
                "Tools"
            ],
            "spell": "Tools",
            "idpath": [
                1420
            ],
            "name": "Tools",
            "id": 1420,
            "leaf": false
        },
        {
            "path": [
                "Mother & Kids"
            ],
            "spell": "Mother & Kids",
            "idpath": [
                1501
            ],
            "name": "Mother & Kids",
            "id": 1501,
            "leaf": false
        },
        {
            "path": [
                "Furniture"
            ],
            "spell": "Furniture",
            "idpath": [
                1503
            ],
            "name": "Furniture",
            "id": 1503,
            "leaf": false
        },
        {
            "path": [
                "Watches"
            ],
            "spell": "Watches",
            "idpath": [
                1511
            ],
            "name": "Watches",
            "id": 1511,
            "leaf": false
        },
        {
            "path": [
                "Luggage & Bags"
            ],
            "spell": "Luggage & Bags",
            "idpath": [
                1524
            ],
            "name": "Luggage & Bags",
            "id": 1524,
            "leaf": false
        },
        {
            "path": [
                "Women's Clothing"
            ],
            "spell": "Women's Clothing",
            "idpath": [
                200000345
            ],
            "name": "Women's Clothing",
            "id": 200000345,
            "leaf": false
        },
        {
            "path": [
                "Men's Clothing"
            ],
            "spell": "Men's Clothing",
            "idpath": [
                200000343
            ],
            "name": "Men's Clothing",
            "id": 200000343,
            "leaf": false
        },
        {
            "path": [
                "Apparel Accessories"
            ],
            "spell": "Apparel Accessories",
            "idpath": [
                200000297
            ],
            "name": "Apparel Accessories",
            "id": 200000297,
            "leaf": false
        },
        {
            "path": [
                "Hair Extensions & Wigs"
            ],
            "spell": "Hair Extensions & Wigs",
            "idpath": [
                200165144
            ],
            "name": "Hair Extensions & Wigs",
            "id": 200165144,
            "leaf": false
        },
        {
            "path": [
                "Underwear"
            ],
            "spell": "Underwear",
            "idpath": [
                200574005
            ],
            "name": "Underwear",
            "id": 200574005,
            "leaf": false
        },
        {
            "path": [
                "Novelty & Special Use"
            ],
            "spell": "Novelty & Special Use",
            "idpath": [
                200000532
            ],
            "name": "Novelty & Special Use",
            "id": 200000532,
            "leaf": false
        },
        {
            "path": [
                "Sports Shoes,Clothing&Accessories"
            ],
            "spell": "Sports Shoes,Clothing&Accessories",
            "idpath": [
                201768104
            ],
            "name": "Sports Shoes,Clothing&Accessories",
            "id": 201768104,
            "leaf": false
        },
        {
            "path": [
                "Motorcycle Equipments & Parts"
            ],
            "spell": "Motorcycle Equipments & Parts",
            "idpath": [
                201355758
            ],
            "name": "Motorcycle Equipments & Parts",
            "id": 201355758,
            "leaf": false
        },
    ],
    second_level: [
        {
            "path": [
                "Home Appliances",
                "Personal Care Appliances"
            ],
            "spell": "Personal Care Appliances",
            "idpath": [
                6,
                200165142
            ],
            "name": "Personal Care Appliances",
            "id": 200165142,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Commercial Appliances"
            ],
            "spell": "Commercial Appliances",
            "idpath": [
                6,
                200235142
            ],
            "name": "Commercial Appliances",
            "id": 200235142,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Cleaning Appliances"
            ],
            "spell": "Cleaning Appliances",
            "idpath": [
                6,
                100000038
            ],
            "name": "Cleaning Appliances",
            "id": 100000038,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Home Appliance Parts"
            ],
            "spell": "Home Appliance Parts",
            "idpath": [
                6,
                100000039
            ],
            "name": "Home Appliance Parts",
            "id": 100000039,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Kitchen Appliances"
            ],
            "spell": "Kitchen Appliances",
            "idpath": [
                6,
                100000041
            ],
            "name": "Kitchen Appliances",
            "id": 100000041,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Major Appliances"
            ],
            "spell": "Major Appliances",
            "idpath": [
                6,
                200293142
            ],
            "name": "Major Appliances",
            "id": 200293142,
            "leaf": false
        },
        {
            "path": [
                "Home Appliances",
                "Household Appliances"
            ],
            "spell": "Household Appliances",
            "idpath": [
                6,
                200294142
            ],
            "name": "Household Appliances",
            "id": 200294142,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Desktops & AIO"
            ],
            "spell": "Desktops & AIO",
            "idpath": [
                7,
                701
            ],
            "name": "Desktops & AIO",
            "id": 701,
            "leaf": true
        },
        {
            "path": [
                "Computer & Office",
                "Laptops"
            ],
            "spell": "Laptops",
            "idpath": [
                7,
                702
            ],
            "name": "Laptops",
            "id": 702,
            "leaf": true
        },
        {
            "path": [
                "Computer & Office",
                "Laptop Parts & Accessories"
            ],
            "spell": "Laptop Parts & Accessories",
            "idpath": [
                7,
                200001083
            ],
            "name": "Laptop Parts & Accessories",
            "id": 200001083,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Computer Peripherals"
            ],
            "spell": "Computer Peripherals",
            "idpath": [
                7,
                200001081
            ],
            "name": "Computer Peripherals",
            "id": 200001081,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Tablets"
            ],
            "spell": "Tablets",
            "idpath": [
                7,
                200001086
            ],
            "name": "Tablets",
            "id": 200001086,
            "leaf": true
        },
        {
            "path": [
                "Computer & Office",
                "Tablet Accessories & Parts"
            ],
            "spell": "Tablet Accessories & Parts",
            "idpath": [
                7,
                200001085
            ],
            "name": "Tablet Accessories & Parts",
            "id": 200001085,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Computer Components"
            ],
            "spell": "Computer Components",
            "idpath": [
                7,
                200001076
            ],
            "name": "Computer Components",
            "id": 200001076,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Storage Device"
            ],
            "spell": "Storage Device",
            "idpath": [
                7,
                200001074
            ],
            "name": "Storage Device",
            "id": 200001074,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Networking"
            ],
            "spell": "Networking",
            "idpath": [
                7,
                200001077
            ],
            "name": "Networking",
            "id": 200001077,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Barebone & Mini PC"
            ],
            "spell": "Barebone & Mini PC",
            "idpath": [
                7,
                70803003
            ],
            "name": "Barebone & Mini PC",
            "id": 70803003,
            "leaf": true
        },
        {
            "path": [
                "Computer & Office",
                "3D Printing & Additive Manufacturing"
            ],
            "spell": "3D Printing & Additive Manufacturing",
            "idpath": [
                7,
                200003879
            ],
            "name": "3D Printing & Additive Manufacturing",
            "id": 200003879,
            "leaf": false
        },
        {
            "path": [
                "Computer & Office",
                "Office Electronics"
            ],
            "spell": "Office Electronics",
            "idpath": [
                7,
                200003782
            ],
            "name": "Office Electronics",
            "id": 200003782,
            "leaf": false
        },
    ],
    third_level: [{
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Personal Care and Cleaning Foam Machine"
        ],
        "spell": "Personal Care and Cleaning Foam Machine",
        "idpath": [
            6,
            200165142,
            201346402
        ],
        "name": "Personal Care and Cleaning Foam Machine",
        "id": 201346402,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electic Sonic Tooth Stains Remover"
        ],
        "spell": "Electic Sonic Tooth Stains Remover",
        "idpath": [
            6,
            200165142,
            201378001
        ],
        "name": "Electic Sonic Tooth Stains Remover",
        "id": 201378001,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Shawer Care"
        ],
        "spell": "Electric Shawer Care",
        "idpath": [
            6,
            200165142,
            201345802
        ],
        "name": "Electric Shawer Care",
        "id": 201345802,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Hair Cap"
        ],
        "spell": "Electric Hair Cap",
        "idpath": [
            6,
            200165142,
            200262142
        ],
        "name": "Electric Hair Cap",
        "id": 200262142,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Bath Brushes"
        ],
        "spell": "Electric Bath Brushes",
        "idpath": [
            6,
            200165142,
            200688002
        ],
        "name": "Electric Bath Brushes",
        "id": 200688002,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Toothbrushes Head"
        ],
        "spell": "Toothbrushes Head",
        "idpath": [
            6,
            200165142,
            330506
        ],
        "name": "Toothbrushes Head",
        "id": 330506,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Automatic Induction Hand Washer"
        ],
        "spell": "Automatic Induction Hand Washer",
        "idpath": [
            6,
            200165142,
            201377603
        ],
        "name": "Automatic Induction Hand Washer",
        "id": 201377603,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Toothbrush"
        ],
        "spell": "Electric Toothbrush",
        "idpath": [
            6,
            200165142,
            200001328
        ],
        "name": "Electric Toothbrush",
        "id": 200001328,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Shavers"
        ],
        "spell": "Electric Shavers",
        "idpath": [
            6,
            200165142,
            3314
        ],
        "name": "Electric Shavers",
        "id": 3314,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Hair Dryers"
        ],
        "spell": "Hair Dryers",
        "idpath": [
            6,
            200165142,
            200001209
        ],
        "name": "Hair Dryers",
        "id": 200001209,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Hair Trimmers"
        ],
        "spell": "Hair Trimmers",
        "idpath": [
            6,
            200165142,
            200001213
        ],
        "name": "Hair Trimmers",
        "id": 200001213,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Hair Diffusers"
        ],
        "spell": "Hair Diffusers",
        "idpath": [
            6,
            200165142,
            200001206
        ],
        "name": "Hair Diffusers",
        "id": 200001206,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Hair Brushes"
        ],
        "spell": "Electric Hair Brushes",
        "idpath": [
            6,
            200165142,
            200263142
        ],
        "name": "Electric Hair Brushes",
        "id": 200263142,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electric Nose & Ear Trimmers"
        ],
        "spell": "Electric Nose & Ear Trimmers",
        "idpath": [
            6,
            200165142,
            200366149
        ],
        "name": "Electric Nose & Ear Trimmers",
        "id": 200366149,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Wax Heater"
        ],
        "spell": "Wax Heater",
        "idpath": [
            6,
            200165142,
            200003970
        ],
        "name": "Wax Heater",
        "id": 200003970,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Oral Irrigator"
        ],
        "spell": "Oral Irrigator",
        "idpath": [
            6,
            200165142,
            200003960
        ],
        "name": "Oral Irrigator",
        "id": 200003960,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Toothbrush Sanitiser"
        ],
        "spell": "Toothbrush Sanitiser",
        "idpath": [
            6,
            200165142,
            201335508
        ],
        "name": "Toothbrush Sanitiser",
        "id": 201335508,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Personal Care Appliance Accessories"
        ],
        "spell": "Personal Care Appliance Accessories",
        "idpath": [
            6,
            200165142,
            200342143
        ],
        "name": "Personal Care Appliance Accessories",
        "id": 200342143,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Sonic Washer"
        ],
        "spell": "Sonic Washer",
        "idpath": [
            6,
            200165142,
            201375502
        ],
        "name": "Sonic Washer",
        "id": 201375502,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Electronic Foot File"
        ],
        "spell": "Electronic Foot File",
        "idpath": [
            6,
            200165142,
            200261142
        ],
        "name": "Electronic Foot File",
        "id": 200261142,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Hair Clippers"
        ],
        "spell": "Hair Clippers",
        "idpath": [
            6,
            200165142,
            200359142
        ],
        "name": "Hair Clippers",
        "id": 200359142,
        "leaf": true
    },
    {
        "path": [
            "Home Appliances",
            "Personal Care Appliances",
            "Epilator"
        ],
        "spell": "Epilator",
        "idpath": [
            6,
            200165142,
            66010102
        ],
        "name": "Epilator",
        "id": 66010102,
        "leaf": true
    },

    {
        "path": [
            "Computer & Office",
            "Servers & Industrial Computer"
        ],
        "spell": "Servers & Industrial Computer",
        "idpath": [
            7,
            201610101
        ],
        "name": "Servers & Industrial Computer",
        "id": 201610101,
        "leaf": false
    },

    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Screen Protectors"
        ],
        "spell": "Screen Protectors",
        "idpath": [
            7,
            200001083,
            200002108
        ],
        "name": "Screen Protectors",
        "id": 200002108,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Cooling Pads"
        ],
        "spell": "Laptop Cooling Pads",
        "idpath": [
            7,
            200001083,
            721
        ],
        "name": "Laptop Cooling Pads",
        "id": 721,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Stand"
        ],
        "spell": "Laptop Stand",
        "idpath": [
            7,
            200001083,
            200003384
        ],
        "name": "Laptop Stand",
        "id": 200003384,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Adapter"
        ],
        "spell": "Laptop Adapter",
        "idpath": [
            7,
            200001083,
            14191109
        ],
        "name": "Laptop Adapter",
        "id": 14191109,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Bags & Cases"
        ],
        "spell": "Laptop Bags & Cases",
        "idpath": [
            7,
            200001083,
            380110
        ],
        "name": "Laptop Bags & Cases",
        "id": 380110,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Lock"
        ],
        "spell": "Laptop Lock",
        "idpath": [
            7,
            200001083,
            200001090
        ],
        "name": "Laptop Lock",
        "id": 200001090,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Lapdesks"
        ],
        "spell": "Lapdesks",
        "idpath": [
            7,
            200001083,
            200001089
        ],
        "name": "Lapdesks",
        "id": 200001089,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "External Power Supplies"
        ],
        "spell": "External Power Supplies",
        "idpath": [
            7,
            200001083,
            201229905
        ],
        "name": "External Power Supplies",
        "id": 201229905,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Docking Stations"
        ],
        "spell": "Laptop Docking Stations",
        "idpath": [
            7,
            200001083,
            200001088
        ],
        "name": "Laptop Docking Stations",
        "id": 200001088,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Skins"
        ],
        "spell": "Laptop Skins",
        "idpath": [
            7,
            200001083,
            708031
        ],
        "name": "Laptop Skins",
        "id": 708031,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Keyboard Covers"
        ],
        "spell": "Keyboard Covers",
        "idpath": [
            7,
            200001083,
            708021
        ],
        "name": "Keyboard Covers",
        "id": 708021,
        "leaf": true
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "Laptop Parts"
        ],
        "spell": "Laptop Parts",
        "idpath": [
            7,
            200001083,
            201223314
        ],
        "name": "Laptop Parts",
        "id": 201223314,
        "leaf": false
    },
    {
        "path": [
            "Computer & Office",
            "Laptop Parts & Accessories",
            "PC Cleaners"
        ],
        "spell": "PC Cleaners",
        "idpath": [
            7,
            200001083,
            201220612
        ],
        "name": "PC Cleaners",
        "id": 201220612,
        "leaf": true
    }
    ]
}