export const articleArray = [
    {
        articleDetails: "Sample Article 1",
        category: "Category 1",
        tags: 46,
        viewed: 100,
        helpful: 75,
        notHelpful: 5,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Published"
    },
    {
        articleDetails: "Sample Article 2",
        category: "Category 2",
        tags: 866,
        viewed: 150,
        helpful: 120,
        notHelpful: 10,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Draft"
    }, {
        articleDetails: "Sample Article 1",
        category: "Category 1",
        tags: 46,
        viewed: 100,
        helpful: 75,
        notHelpful: 5,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Published"
    },
    {
        articleDetails: "Sample Article 2",
        category: "Category 2",
        tags: 866,
        viewed: 150,
        helpful: 120,
        notHelpful: 10,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Draft"
    }, {
        articleDetails: "Sample Article 1",
        category: "Category 1",
        tags: 46,
        viewed: 100,
        helpful: 75,
        notHelpful: 5,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Published"
    },
    {
        articleDetails: "Sample Article 2",
        category: "Category 2",
        tags: 866,
        viewed: 150,
        helpful: 120,
        notHelpful: 10,
        createdOn: '2-06-2023 6:00',
        updatedOn: '2-06-2023 6:00',
        updatedBy: "Bhadri",
        status: "Draft"
    },
    // Add more objects as needed
];
