export const announcementList = [{
    announcementTitle: 'Sample Announcement 1',
    slug: 'sample-announcement-1',
    userFolder: 'admin',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    slug: 'sample-announcement-2',
    userFolder: 'admin',
    viewed: '53',
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    slug: 'sample-announcement-1',
    userFolder: 'seller',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    slug: 'sample-announcement-2',
    userFolder: 'seller',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}, {
    announcementTitle: 'Sample Announcement 1',
    slug: 'sample-announcement-1',
    userFolder: 'admin',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Active',
},
{
    announcementTitle: 'Sample Announcement 2',
    slug: 'sample-announcement-2',
    userFolder: 'seller',
    viewed: 52,
    duration: '2-06-2023 6:00',
    updatedBy: 'Bhadri',
    status: 'Inactive',
}]