import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaginationMetaModel, PaginationMetaType, PaginationType } from 'src/app/core/modals/Pagination';
import { AttributeFamilyService } from '../attribute-family.service';
import { ToastrService } from 'ngx-toastr';
import { AttributeFamily } from '../../attribute/services/attribute-modal';

@Component({
  selector: 'app-attribute-family',
  templateUrl: './attribute-family.component.html',
  styleUrls: ['./attribute-family.component.scss']
})
export class AttributeFamilyComponent {

  displayedColumns = ["slNo", "name", "code", "status", "action"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;
  isRateLimitReached = false;

  paginationMeta: PaginationMetaType = PaginationMetaModel;
  pagination: PaginationType = {
    route: '/'
  };
  sortBy = [{ value: "ASC", name: "Ascending Order" }, { value: "DESC", name: "Descending Order" }];

  status = [{ id: 0, value: "InActive" }, { id: 1, value: "Active" }]

  public searchText;
  total$: Observable<number>;
  public selected = [];
  attributeFamily: any = [];

  familyCreateForm: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  validate: any;
  errors: any = [];
  familyId: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private familyService: AttributeFamilyService,
    private toast: ToastrService,) {
    const routeParams = this.route.snapshot.paramMap;
    this.familyId = Number(routeParams.get("id"));
  }
  ngOnInit() {

    this.familyCreateForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      is_user_defined: ['', Validators.required],
      status: ['', Validators.required],
    });

    this.familyService.findOne(this.familyId).subscribe((res: any) => {
      if (res.status == true) {
        const familyUpdate: AttributeFamily = res.data;
        console.log(familyUpdate);
        this.familyCreateForm.get('name').setValue(familyUpdate.name);
        this.familyCreateForm.get('code').setValue(familyUpdate.code);
        this.familyCreateForm.get('is_user_defined').setValue(familyUpdate.is_user_defined);
        this.familyCreateForm.get('status').setValue(familyUpdate.status);
      }
    });

    this.searchForm = this.fb.group({
      search: [null],
      sort: [null],
    });

  }

  async ngAfterViewInit() {
    await this.getAttributeFamilyList();
  }

  async getAttributeFamilyList() {
    await this.familyService.findAll(this.pagination).subscribe((res: any) => {
      if (res.status == true) {
        this.attributeFamily = res.data.data;
        this.paginationMeta = res.data.meta;
        this.isLoadingResults = false;
        console.log(this.paginationMeta);
      }
    });
  }

  getServerData(event) {
    console.log(this.pagination);
    console.log(event)
    this.pagination.limit = event.pageSize || 10;
    this.pagination.page = (parseInt(event.pageIndex) + 1) || 1;
    this.getAttributeFamilyList();
  }

  getStatusName(id: number) {
    let status: string = ''
    this.status.forEach((item) => {
      if (item.id == id) {
        status = item.value;
      }
    })
    return status;
  }

  onRowClicked(row) {
    console.log('Row clicked: ', row);
  }

  async onDelete(id: number) {

    const postData = {
      attribute_family_id: id,
      status: 0,
    };
    const response = await this.familyService.delete(postData).subscribe((res: any) => {
      console.log(res);
      if (res.status == true) {
        this.toast.success(res.message);
        this.attributeFamily = this.attributeFamily.filter(family => family.attribute_family_id !== id);
        console.log(this.attributeFamily);
        this.getAttributeFamilyList();

      } else {
        this.toast.error(res.errors);
        console.log(res.errors);
        this.errors = res;
        return res;
      }
    }
    );
    console.log(response);
  }

  async onSubmit() {
    this.submitted = true;
    this.validate = !this.validate;
    console.log(this.familyCreateForm.value);
    if (this.familyId) {
      const postData = {
        attribute_family_id: this.familyId,
        name: this.familyCreateForm.value.name,
        code: this.familyCreateForm.value.code,
        is_user_defined: this.familyCreateForm.value.is_user_defined,
        status: this.familyCreateForm.value.status,
      }
      console.log(postData);

      if (this.familyCreateForm.status == 'VALID') {
        const response = await this.familyService.update(postData).subscribe((res: any) => {
          console.log(res);
          if (res.status == true) {
            this.familyCreateForm.reset();
            this.toast.success('Attribute Family has been updated successfully');
            this.getAttributeFamilyList();
            return this.router.navigate(['/catalog/attribute-family']);
          } else {
            this.toast.error(res.errors);
            this.errors = res;
            return res;
          }
        });
        console.log(response);
      }
    } else {
      // const postData: AttributeFamily = this.familyCreateForm.value;
      const postData = {
        name: this.familyCreateForm.value.name,
        code: this.familyCreateForm.value.code,
        is_user_defined: this.familyCreateForm.value.is_user_defined,
        status: (this.familyCreateForm.value.status ==true)?1 : 0,
        
      }

      if (this.familyCreateForm.status == 'VALID') {

        const response = await this.familyService.create(postData)
          .subscribe((res: any) => {
            console.log(res);
            if (res.status == true) {
              this.familyCreateForm.reset();
              this.toast.success('Attribute Family has been added successfully');
              this.getAttributeFamilyList();

            } else {
              this.toast.error(res.errors);
              this.errors = res;
              return res;
            }
          }
          );
        console.log(response);
      }
    }

  }

  get f() {
    return this.familyCreateForm.controls;
  }

  onSearch() {
    console.log(this.searchForm.value);
    if (this.searchForm.value.search != null) {
      this.pagination.search = this.searchForm.value.search;
    }

    this.getAttributeFamilyList();

  }

  onClear() {
    this.searchForm.reset();
    this.pagination.search = '';
    this.getAttributeFamilyList();

  }
}
