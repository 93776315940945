import { Actions, createEffect, ofType } from "@ngrx/effects"
import { UserService } from './../../modules/users/services/user.service';
import { Injectable } from "@angular/core";
import { catchError, exhaustMap, map, mergeMap } from "rxjs";
// import { SET_USER_STATE, updateUserState } from "./user.actions";

export abstract class Wrapper {
    constructor(protected readonly actions$: Actions) { }
}

@Injectable()
export class UserEffects extends Wrapper {
    constructor(
        actions$: Actions,
        private userService: UserService
    ) {
        super(actions$);
    }

    // setUserState$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SET_USER_STATE),
    //         exhaustMap(action =>
    //             this.userService.getUserState().pipe(
    //                 map((data: any) =>
    //                     updateUserState(data.data?.user)
    //                 ),
    //                 //catchError(error => of(AuthApiActions.loginFailure({ error })))
    //             )
    //         )
    //     )
    // );
}